import { CSSProperties, useMemo } from 'react'
import { CircularProgress, Paper } from '@material-ui/core'

type LoadingProps = {
  width?: number | string
  height?: number | string
  radius?: number | string
}

const convertValue = (value?: number | string, _default = '100%') => {
  return value !== undefined ? (typeof value === 'number' ? `${value}px` : value) : _default
}

const LoadingPanel = (props: LoadingProps) => {
  const style: CSSProperties = useMemo(() => {
    let properties: CSSProperties = {}

    properties.height = convertValue(props.height)

    properties.width = convertValue(props.width)

    properties.borderRadius = convertValue(props.radius, '12px')

    return properties
  }, [props])

  return (
    <Paper style={{ ...style }}>
      <LoadingCircle />
    </Paper>
  )
}

const LoadingBox = (props: LoadingProps) => {
  const style: CSSProperties = useMemo(() => {
    let properties: CSSProperties = {}

    properties.height = convertValue(props.height)

    properties.width = convertValue(props.width)

    properties.borderRadius = convertValue(props.radius, '12px')

    return properties
  }, [props])

  return (
    <div style={{ ...style }}>
      <LoadingCircle />
    </div>
  )
}

const LoadingCircle = (props: LoadingProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress color="secondary" />
    </div>
  )
}

export const Loading = {
  Panel: LoadingPanel,
  Box: LoadingBox,
  Circle: LoadingCircle
}
