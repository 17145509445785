import styled from 'styled-components'
import { Box } from '@material-ui/core'
import colors from '../../support/theme/colors'

export const Count = styled(Box)`
  && {
    font-weight: 600;
    line-height: 30px;
    font-size: 24px;
    color: ${colors.main};
  }
`
