import styled from 'styled-components'
import { IconButton } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const IconButtonStyles = styled(IconButton)`
  && {
    margin-right: 16px !important;
    -webkit-box-shadow: 0px 1px 3px 1px rgba(65, 69, 88, 0.2);
    box-shadow: 0px 1px 3px 1px rgba(65, 69, 88, 0.2);
    width: 40px;
    height: 40px;
  }
`

export const useAssetStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#F6F8FA',
      marginBottom: theme.spacing(4)
    }
  })
)

export const useBlogStyles = makeStyles((theme: Theme) =>
  createStyles({
    paragraphs: {
      'font-size': '18px',
      'line-height': '1.4',
      'letter-spacing': '-0.25px'
    }
  })
)
