export const getBrands = (brand: string) => {
  switch (brand) {
    // case 'CUSTOM':
    //   return {
    //     colors: {
    //       main: '#EAEAEA', // Footer background
    //       secondary: '#34117a', // Buttons etc
    //       navigation: {
    //         main: '#EAEAEA', // Menu Background
    //         dark: '#003EA9', // Menu Items
    //       }
    //     },
    //     logos: {
    //       app: '/brands/visa_app.png',
    //       default: '/brands/visa_app.png'
    //     }
    //   };
    default:
      return {
        colors: {
          main: '#001433',
          secondary: '#E5126E',
          navigation: {
            main: '#001433',
            dark: '#FFFFFF'
          }
        },
        logos: {
          app: '/brands/isky_app.svg',
          default: '/brands/isky_default.svg'
        }
      }
  }
}
