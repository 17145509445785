import { uniqBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useCriteria } from '../contexts/Search/Criteria'
import { FeatureJourney } from '../types/Functions'
import { useQuestions } from './questions'
import { ChannelScore } from '../declarations/ChannelScore'
import { JourneyCriterion } from '../declarations/Criterion'
import { Question, Weight } from '../declarations/Question'

/**
 * Functions hook for use within components.
 */

type TransformedChannelJourney = {
  journeyId: number
  cx_score: number
  score: number
  answers: {
    value: number
    questionId: number
  }[]
}

type ChannelJourneysType = {
  key: string
  name: string
  id: number
  questions: Question[]
  weight?: Weight
}[]

export type TransformedChannel = ChannelScore & {
  sort: number
  channelJourneys: ChannelJourneysType
  answers: TransformedChannelJourney[]
}

export const useFunctions = () => {
  const searchCriteria = useCriteria()
  const { getJourneyWeight } = useQuestions()

  /**
   * Get an array of unique questions by id.
   *
   * @returns {Question[]}
   */
  const uniqueQuestions = useMemo(
    () => uniqBy(searchCriteria.state.questions.questions, 'id'),
    [searchCriteria.state.questions]
  )

  /**
   * Get the journey.
   */
  const getJourney = useCallback(
    (journey: JourneyCriterion): FeatureJourney => {
      const questions = uniqueQuestions.filter((q) => q.journeyId === journey.id)
      const weight = getJourneyWeight(journey.id)
      return {
        key: `journey#${journey.id}`,
        id: journey.id,
        name: journey.name,
        questions,
        weight
      }
    },
    [getJourneyWeight, uniqueQuestions]
  )

  /**
   * Get a combined list of both secure and non-secure journeys.
   *
   * @returns {FeatureJourney[]}
   */
  const journeys: FeatureJourney[] = useMemo(() => {
    const journeys = searchCriteria.state.journeys.map((j) => getJourney(j))

    return journeys
  }, [searchCriteria.state.journeys, getJourney])

  /**
   * Get an array of unique names from the journeys by their key.
   *
   * @returns {string[]}
   */
  const names = useMemo(() => journeys.map((j) => j.key), [journeys])

  /**
   * Get the channel journeys from the feature journeys.
   */
  const channelJourneys: ChannelJourneysType = useMemo(
    () =>
      journeys.map((j) => ({
        key: j.key,
        name: j.name,
        id: j.id,
        questions: j.questions.map((q) => {
          return q
        }),
        weight: getJourneyWeight(j.id)
      })),
    [getJourneyWeight, journeys]
  )

  /**
   * Transforms the journeys based on their secure state.
   *
   * @param {ChannelScore} channelScore
   */
  const transformChannelJourneys = (channelScore: ChannelScore): TransformedChannelJourney[] =>
    channelScore.journeys.map((wjs) => ({
      journeyId: wjs.journey,
      cx_score: wjs.cx_score,
      score: wjs.score,
      answers: wjs.answers.map((a) => ({
        value: a.value,
        questionId: a.question.id
      }))
    }))

  /**
   * Transforms the channel scores.
   *
   * @param {ChannelScore[]} channelScores
   */
  const transformChannels = (channelScores: ChannelScore[]) => channelScores.map(transformChannel)

  /**
   * Transforms a singular channel score.
   *
   * @param {ChannelScore} channelScore
   * @param {number} sort
   */
  const transformChannel = (channelScore: ChannelScore, sort: number): TransformedChannel => ({
    ...channelScore,
    sort,
    channelJourneys: channelJourneys,
    answers: transformChannelJourneys(channelScore)
  })

  return {
    transformChannel,
    transformChannels,
    transformChannelJourneys,
    journeys,
    names,
    channelJourneys
  }
}
