import { Auth } from '@aws-amplify/auth'
import {
  AmplifyCodeExpired,
  AmplifyCodeMismatch,
  AmplifySuccess,
  AmplifyUnknownError,
  AmplifyUserNotFound
} from '../../types/Amplify'
import { CognitoError } from '../../types/Cognito'
import { UNEXPECTED_ERROR_MESSAGE } from '../../helpers/messages'

type ConfirmSignUpResult =
  | AmplifySuccess
  | AmplifyCodeMismatch
  | AmplifyCodeExpired
  | AmplifyUserNotFound
  | AmplifyUnknownError

/**
 * Handle the confirmation of signup via the amplify sdk.
 *
 * @param {string} username
 * @param {string} code
 *
 * @returns {Promise<ConfirmSignUpResult>}
 */
export const confirmSignUp = async (username: string, code: string): Promise<ConfirmSignUpResult> => {
  try {
    await Auth.confirmSignUp(username, code)

    return { type: 'success' }
  } catch (ex) {
    const caught = ex as CognitoError
    if (caught.code === undefined) {
      return { type: 'unknown-error', exception: caught }
    }

    switch (caught.code) {
      case 'CodeMismatchException':
        return {
          type: 'code-mismatch',
          error: caught.message
        }
      case 'ExpiredCodeException':
        return {
          type: 'code-expired',
          error: caught.message
        }
      case 'UserNotFoundException':
        return {
          type: 'user-not-found',
          error: UNEXPECTED_ERROR_MESSAGE
        }
    }

    return { type: 'unknown-error', exception: caught }
  }
}
