const videoExtensions = [
  'webm',
  'mpg',
  'mp2',
  'mpeg',
  'mpe',
  'mpv',
  'ogg',
  'mp4',
  'm4p',
  'm4v',
  'avi',
  'wmv',
  'mov',
  'qt',
  'flv',
  'swf',
  'avchd'
]

/**
 * Checks if the url is a video based on its extension.
 *
 *  @param {string} asset
 */
const isVideo = (asset: string): boolean => {
  const extension = asset.split('.')[asset.split('.').length - 1]
  return videoExtensions.includes(extension)
}

export default isVideo
