import { Auth } from '@aws-amplify/auth'
import { CognitoError } from '../../types/Cognito'
import { AmplifyLimitExceeded, AmplifySuccess, AmplifyUnknownError, AmplifyNetworkError } from '../../types/Amplify'

type ForgotPasswordConfirmResult = AmplifySuccess | AmplifyLimitExceeded | AmplifyUnknownError | AmplifyNetworkError

/**
 * Handle the confirmation of a password change via the amplify sdk.
 *
 * @param {string} username
 * @param {string} code
 * @param {string} newPassword
 *
 * @returns {Promise<ForgotPasswordConfirmResult>}
 */
export const forgotPasswordConfirm = async (
  username: string,
  code: string,
  newPassword: string
): Promise<ForgotPasswordConfirmResult> => {
  try {
    await Auth.forgotPasswordSubmit(username, code, newPassword)
    const user = await Auth.signIn(username, newPassword)
    await Auth.updateUserAttributes(user, { 'custom:LastRevalidationDate': new Date().toISOString() })
    await Auth.signOut()
    return { type: 'success' }
  } catch (caught) {
    if ((caught as CognitoError).code === 'NetworkError') {
      return { type: 'network-error', error: 'Please check your internet connection' }
    }
    if ((caught as CognitoError).code === undefined) {
      return { type: 'unknown-error', exception: caught }
    }

    switch ((caught as CognitoError).code) {
      case 'LimitExceededException':
        return {
          type: 'limit-exceeded',
          error: 'You have exceeded the number of attempts, please try again shortly.'
        }
    }

    return { type: 'unknown-error', exception: caught }
  }
}
