import { useCallback } from 'react'
import { useCriteria } from '../contexts/Search/Criteria'
import { useSearchQuery } from '../contexts/Search/Query'
import { Weight } from '../declarations/Question'
import { Segments } from '../types/Segments'

/**
 * A hook for using questions in components.
 */
export const useQuestions = () => {
  const searchCriteria = useCriteria()
  const searchQuery = useSearchQuery()
  const selectedSector = searchQuery.state.selectedCycle.linkedSector

  /**
   * Gets the question based on the input.
   *
   * @param {number} id
   * @param {number} journey
   * @param {number} segment
   */
  const resolveQuestion = useCallback(
    (id: number, journey: number, segment: Segments) => {
      return searchCriteria.state.questions.questions.find((q) => {
        return q.segmentId === segment && q.journeyId === journey && q.sectorId === selectedSector && q.id === id
      })
    },
    [searchCriteria.state.questions.questions, selectedSector]
  )

  /**
   * Get the journey based on the input.
   *
   * @param {number} id
   */
  const resolveJourney = useCallback(
    (id: number) => {
      return searchCriteria.state.questions.journeys.find((q) => {
        return q.journeyId === id
      })
    },
    [searchCriteria.state.questions.journeys]
  )

  /**
   * Get the weight based on the input.
   *
   * @param {number} id
   * @param {number} journey
   * @param {number} segment
   */
  const getWeight = useCallback(
    (id: number, journey: number, segment: Segments): Weight | undefined => {
      const question = resolveQuestion(id, journey, segment)

      if (!question) {
        return undefined
      }

      return question.weight
    },
    [resolveQuestion]
  )

  /**
   * Get the specific journey weight based on input.
   *
   * @param {number} id
   * @param {number} secure
   */
  const getJourneyWeight = useCallback(
    (id: number) => {
      const journey = resolveJourney(id)

      if (!journey) {
        return undefined
      }
      return journey.weight
    },
    [resolveJourney]
  )

  return {
    getWeight,
    getJourneyWeight
  }
}
