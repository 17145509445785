import { Typography } from '@material-ui/core'

type TotalsTypographyProps = {
  page: number
  totalPages: number
}

export const TotalsTypography = (props: TotalsTypographyProps) => {
  return (
    <Typography variant={'h3'}>
      Page {props.page} of {props.totalPages}
    </Typography>
  )
}
