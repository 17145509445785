import { Box, withStyles } from '@material-ui/core'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import styled from 'styled-components'

export const GreenCheckbox = withStyles({
  root: {
    color: '#24AB3B',
    '&$checked': {
      color: '#24AB3B'
    }
  },
  checked: {}
})((props: CheckboxProps) => <Checkbox color="default" {...props} />)

export const StyledBox = styled(Box)`
  && {
    font-size: 14px;
    color: #647386;
    letter-spacing: -0.2px;
    margin-bottom: 32px;
    line-height: 1.5;
  }
`
