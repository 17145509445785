import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { Accordion, AccordionSummary } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

interface Row {
  dataCaptured: string
  definition: string
  score: string
}

const rows: Row[] = [
  {
    dataCaptured: 'No. Fields',
    definition: 'Identification of number of data fields populated through the journey',
    score: '1 Field = 1'
  },
  {
    dataCaptured: 'Swipes',
    definition: 'Identification of number of left to right or right to left navigational movements',
    score: '1 Swipe = 1'
  },
  {
    dataCaptured: 'Wipes',
    definition: 'Identification of number of scrolling up and down screens in journey',
    score: '1 Wipe = 1'
  },
  {
    dataCaptured: 'Clicks',
    definition: 'Identification of the number of navigational clicks or selections in the journey',
    score: '1 Click = 1'
  },
  { dataCaptured: 'Screens', definition: 'Identification of the number of screens encountered', score: '1 Screen = 1' },
  {
    dataCaptured: 'Security',
    definition: 'Identification of type and frequency of security in journey',
    score:
      "'Something you are' = 0.5\n'Something you know' = 1\n'Something you have but can’t be memorised’/ ‘Something you can get’ = 2"
  },
  {
    dataCaptured: 'Friction score ™',
    definition:
      'Sum total of the scores for the number of fields, swipes, wipes, clicks, screens and the security score',
    score: ''
  }
]

/**
 * Renders a data card component that displays a table with data captured, definition, and score.
 * This is a ledgend for frictionscore
 *
 * @returns {React.ReactNode} The rendered data card component.
 */
const DataCard: React.FC = () => {
  return (
    <div>
      <Accordion style={{ marginTop: '1em' }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h3">Friction Score definition</Typography>
        </AccordionSummary>
        <Paper style={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>DATA CAPTURED</TableCell>
                  <TableCell>DEFINITION</TableCell>
                  <TableCell align="right">SCORE</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.dataCaptured}>
                    <TableCell>{row.dataCaptured}</TableCell>
                    <TableCell>{row.definition}</TableCell>
                    <TableCell align="right">{row.score}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Accordion>
    </div>
  )
}

export default DataCard
