import { ApolloClient, gql } from '@apollo/client'

export type Tag = {
  id: string
  name: string
}

type GetTagsResponse = {
  getTags: Tag[]
}

const query = gql`
  query tags {
    getTags {
      id
      name
    }
  }
`

export const getTags = async (client: ApolloClient<object>): Promise<Tag[]> => {
  try {
    const results = await client.query<GetTagsResponse>({ query })
    return results.data.getTags
  } catch (caught) {
    console.error(caught)
    throw Error()
  }
}
