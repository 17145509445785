export const BusinessBanking2021Sector = 1
export const LifeInsurance2021Sector = 2
export const TelecomsSector = 3
export const LifeInsurance2023Sector = 4
export const PersonalBanking2021Sector = 5
export const PersonalBanking2023Sector = 6
export const BusinessBanking2023Sector = 7
export const MerchantServices2023Sector = 8
export const Health2024Sector = 9

export type Sectors =
  | typeof BusinessBanking2021Sector
  | typeof LifeInsurance2021Sector
  | typeof TelecomsSector
  | typeof LifeInsurance2023Sector
  | typeof PersonalBanking2021Sector
  | typeof PersonalBanking2023Sector
  | typeof BusinessBanking2023Sector
  | typeof MerchantServices2023Sector
  | typeof Health2024Sector
