import { Box, CircularProgress, ClickAwayListener, Paper } from '@material-ui/core'
import { FunctionComponent } from 'react'
import { FilterText } from '../../../components/Typography'
import { DropdownProvider, useDropdown } from '../../../contexts/DropdownContext'
import { ChevronDown } from '../../Common/icons'
import { CounterTextStyles, DropdownContainerStyles, PopoverStyles } from './Dropdown.styled'

export type CriterionFilterCounterProps = {
  total: number
  count: number
}

const Counter: FunctionComponent<CriterionFilterCounterProps> = (props) => {
  return (
    <Box
      bgcolor="#F6F8FA"
      width="22.5px"
      height="22.5px"
      borderRadius="50%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CounterTextStyles>{props.count === 0 || props.count === props.total ? 'All' : props.count}</CounterTextStyles>
    </Box>
  )
}

type DropdownProps = {
  label: string
  disabled: boolean
  count?: number
  total?: number
  hideCounter?: boolean
  counter?: JSX.Element
}

export const Dropdown: FunctionComponent<DropdownProps> = (props) => {
  const dropdownContext = useDropdown()
  const { count = 0, total = 0, hideCounter = false } = props

  const handleClickAway = () => {
    dropdownContext.dispatch({
      type: 'TOGGLE_DROPDOWN_OPEN_STATE',
      payload: { value: false }
    })
  }

  const handleClick = () => {
    dropdownContext.dispatch({
      type: 'TOGGLE_DROPDOWN_OPEN_STATE',
      payload: { value: true }
    })
  }

  return (
    <Box data-cy={`dropdown-${props.label}`} flexGrow={1} key={props.label} padding={1}>
      <Box onClick={handleClick}>
        <DropdownContainerStyles
          display="flex"
          justifyContent="space-between"
          justifyItems={'center'}
          disabled={props.disabled}
        >
          <Box display={'flex'}>
            <FilterText>{props.label}</FilterText>
          </Box>
          <Box display={'flex'} justifyContent={'space-between'} justifyItems={'center'}>
            {!props.disabled && !hideCounter ? (
              <>
                {props.counter === undefined && <Counter total={total} count={count} />}

                {props.counter !== undefined && props.counter}
              </>
            ) : (
              <>{!hideCounter && <CircularProgress color={'secondary'} size={'1rem'} />}</>
            )}
            <Box width={'8px'} height={'8px'}>
              <ChevronDown
                viewBox={'0,0,8,8'}
                style={{
                  width: '8px',
                  height: '8px',
                  marginLeft: '5px'
                }}
              />
            </Box>
          </Box>
        </DropdownContainerStyles>
      </Box>

      {dropdownContext.state.open && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box position="relative" zIndex={20}>
            <PopoverStyles position="absolute" left="0" minWidth={'200px'}>
              <Paper>
                <Box padding={2} overflow={'auto'} maxHeight={'600px'}>
                  {props.children}
                </Box>
              </Paper>
            </PopoverStyles>
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  )
}

export const DropdownContainer: FunctionComponent = (props) => {
  return <DropdownProvider>{props.children}</DropdownProvider>
}
