import { Box, Container, Modal as MaterialUiModal, Paper } from '@material-ui/core'
import { CSSProperties, FunctionComponent, ReactNode } from 'react'
import Header from './Header'
import { ToolbarAction } from '../../declarations/ToolbarAction'

type ModalProps = {
  open: boolean
  title?: string
  actions?: ToolbarAction[]
  closeable?: boolean
  exportable?: ReactNode
  onClose?: () => void
  paddingX?: number
  paddingY?: number
  fullHeight?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  backdrop?: boolean
  bgColor?: string
  rounded?: boolean
  zIndex?: number
}

export const Modal: FunctionComponent<ModalProps> = (props) => {
  const {
    backdrop = true,
    closeable = false,
    exportable = undefined,
    paddingX = 4,
    paddingY = 1,
    fullHeight = true,
    size = 'lg',
    bgColor = '#F6F8FA',
    rounded = false,
    zIndex = 6
  } = props

  const handleClose = () => {
    if (closeable) {
      props.onClose && props.onClose()
    }
  }

  const styles: CSSProperties = fullHeight
    ? {
        height: 'calc(100vh - 100px)'
      }
    : {}

  return (
    <Box>
      <MaterialUiModal
        hideBackdrop={!backdrop}
        open={props.open}
        onClose={handleClose}
        style={{ outline: 'none', height: '100%', zIndex: zIndex }}
      >
        <Container
          maxWidth={size}
          style={
            fullHeight
              ? {
                  marginTop: '100px',
                  outline: 'none'
                }
              : {
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  outline: 'none'
                }
          }
        >
          <Paper
            style={
              rounded
                ? { borderRadius: '12px', overflow: 'hidden' }
                : { borderRadius: 0, borderTopLeftRadius: '12px', borderTopRightRadius: '12px', overflow: 'hidden' }
            }
          >
            <Box bgcolor={bgColor} style={styles}>
              <Header
                title={props.title}
                toolbarActions={props.actions}
                onClose={handleClose}
                exportable={exportable}
              />

              <Box style={{ overflowY: 'auto', height: 'calc(100% - 64px)' }} paddingX={paddingX} paddingY={paddingY}>
                <Box style={{ paddingBottom: '1rem' }}>{props.children}</Box>
              </Box>
            </Box>
          </Paper>
        </Container>
      </MaterialUiModal>
    </Box>
  )
}
