import { Radio, Typography } from '@material-ui/core'
import { FunctionComponent } from 'react'
import { EqualHeightElement } from 'react-equal-height'
import { useComparison } from '../../contexts/ComparisonContext'
import { State, useExpandable } from '../../contexts/ExpandableContext'
import { TransformedChannel } from '../../hooks/functions'
import { ChannelBadge } from '../Common/ChannelBadge'
import { FrictionScore } from '../Common/FrictionScore'
import { SegmentBadge } from '../Common/SegmentBadge'
import { WeightBadge } from '../Common/WeightBadge'
import { CheckedIcon, UncheckedIcon } from '../Common/icons'
import { Expandable } from '../Expandable/Expandable'
import Score from '../Score'
import { SecureChannelBadge } from '../../components/Common/SecureChannelBadge'
import { Cell, CellElement, ExpandableArea, Question, Sticker, useStyles } from './FunctionView.styled'
import { ChannelScore } from '../../declarations/ChannelScore'
import { FeatureJourney } from '../../types/Functions'
import { Question as QuestionType } from '../../declarations/Question'
import { FrictionData, FrictionScore as FrictionScoreType } from '../../declarations/FrictionScore'
import { ChannelTabs } from '../../types/Channel'

const prominentColour = 'white'
const baseColour = '#fafafa'

const showQuestion = (expandableState: State, sortedQuestion: number | undefined, j: any, q: any) => {
  if (expandableState.expanded.includes(j.key)) {
    return true
  }
  if (expandableState.checked.includes(j.key)) {
    return q.id === sortedQuestion
  }
  return false
}

export const FunctionViewHeader = () => (
  <Sticker
    style={{
      alignItems: 'flex-end',
      backgroundColor: 'white',
      borderBottom: '1px solid #D9DBE0',
      zIndex: 3,
      boxShadow: '5px 0px 15px 0px rgba(0,0,0,0.06)'
    }}
  >
    <div style={{ display: 'flex', alignItems: 'center', padding: '15px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Expandable.GroupTrigger>
          <Typography style={{ marginLeft: '6px' }} variant={'h4'}>
            Expand all
          </Typography>
        </Expandable.GroupTrigger>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
        <Expandable.GroupTrigger collapse>
          <Typography style={{ marginLeft: '6px' }} variant={'h4'}>
            Collapse all
          </Typography>
        </Expandable.GroupTrigger>
      </div>
    </div>
  </Sticker>
)

export type FunctionViewQuestionStubProps = {
  question: QuestionType
  journey: FeatureJourney
  highlight: boolean
}

export const FunctionViewQuestionStub: FunctionComponent<FunctionViewQuestionStubProps> = ({
  question,
  journey,
  highlight
}) => (
  <Question
    style={{
      borderTopLeftRadius: '6px',
      borderBottomLeftRadius: '6px',
      marginLeft: '50px'
    }}
    className={'oddly'}
  >
    <EqualHeightElement name={`${journey.key}#question#${question.id}`}>
      <div style={{ padding: '16px', display: 'block' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            width: '100%'
          }}
        >
          <div style={{ flex: '1 1 auto', width: 'auto' }}>
            <Typography component={'span'} variant={'body1'}>
              {highlight ? (
                <>
                  <div style={{ position: 'relative', top: '-220px', left: 0 }}>what is here?</div>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        flex: '5',
                        paddingLeft: '5px',
                        fontWeight: 'normal',
                        color: '#24AB3B'
                      }}
                    >
                      {question.text}
                    </div>
                    <div
                      style={{
                        flex: '3',
                        paddingRight: '5px',
                        paddingLeft: '5px',
                        fontWeight: 'normal',
                        color: '#24AB3B'
                      }}
                    >
                      Sorted by Friction Score
                    </div>
                  </div>
                </>
              ) : (
                <div style={{ display: 'flex' }}>
                  <p>{question.text}</p>
                </div>
              )}
            </Typography>
            <div style={{ display: 'flex', paddingTop: '15px' }}>
              {question.weight && (
                <WeightBadge
                  weight={question.weight.weight}
                  question={question.id}
                  text={question.text}
                  segment={question.weight.segment}
                />
              )}
              <Typography component={'span'}>
                {<SecureChannelBadge secureChannel={question.secure_channelId} />}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </EqualHeightElement>
  </Question>
)

export type FunctionViewJourneyStubProps = {
  journey: FeatureJourney
  sortedQuestion: number | undefined
  comparable?: boolean
  sortedOnText?: string
  usesVoc: boolean
}

export const FunctionViewJourneyStub: FunctionComponent<FunctionViewJourneyStubProps> = ({
  journey,
  sortedQuestion,
  sortedOnText,
  comparable = false,
  usesVoc
}) => {
  const expandable = useExpandable()

  return (
    <ExpandableArea
      style={{
        backgroundColor:
          expandable.state.expanded.length !== 0
            ? expandable.state.expanded.includes(journey.key)
              ? prominentColour
              : baseColour
            : prominentColour,
        borderRight: '1px solid #D9DBE0'
      }}
    >
      <EqualHeightElement name={journey.key}>
        <div>
          <div style={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Expandable.Trigger name={journey.key}>
                <Typography style={{ marginLeft: '10px' }} variant={'h2'}>
                  {journey.name}
                </Typography>
              </Expandable.Trigger>
            </div>
            <div style={{ paddingTop: '15px', marginLeft: '30px' }}>
              <div style={{ display: 'flex' }}>
                {journey.weight && (
                  <WeightBadge withIcon weight={journey.weight.weight} segment={journey.weight.segment} />
                )}
              </div>
            </div>
          </div>
        </div>
      </EqualHeightElement>

      <Expandable.Group name={journey.key}>
        {journey.questions
          .filter((question) => showQuestion(expandable.state, sortedQuestion, journey, question))
          .map((question, qIdx) => (
            <FunctionViewQuestionStub
              key={qIdx}
              question={question}
              journey={journey}
              highlight={sortedOnText === question.text && comparable}
            />
          ))}

        {expandable.state.expanded.includes(journey.key) && (
          <>
            {usesVoc && (
              <Question
                style={{
                  borderTopLeftRadius: '6px',
                  borderBottomLeftRadius: '6px',
                  marginLeft: '50px'
                }}
                className={'oddly'}
              >
                <EqualHeightElement name={`${journey.key}#cx_score`}>
                  <div style={{ padding: '16px', display: 'block' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        width: '100%'
                      }}
                    >
                      <div style={{ flex: '1 1 auto', width: 'auto' }}>
                        <Typography variant={'h4'}>VoC Score</Typography>
                      </div>
                    </div>
                  </div>
                </EqualHeightElement>
              </Question>
            )}
            <Question
              style={{
                borderTopLeftRadius: '6px',
                borderBottomLeftRadius: '6px',
                marginLeft: '50px'
              }}
              className={'oddly'}
            >
              <EqualHeightElement name={`${journey.key}#score`}>
                <div style={{ padding: '16px', display: 'block' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      width: '100%'
                    }}
                  >
                    <div style={{ flex: '1 1 auto', width: 'auto' }}>
                      <Typography variant={'h4'}>Utility Score</Typography>
                    </div>
                  </div>
                </div>
              </EqualHeightElement>
            </Question>
          </>
        )}
      </Expandable.Group>
    </ExpandableArea>
  )
}

export type FunctionViewColumnHeaderProps = {
  provider: TransformedChannel
  comparable?: boolean
  channelScores: ChannelScore[]
  view: ChannelTabs
}

export const FunctionViewColumnHeader: FunctionComponent<FunctionViewColumnHeaderProps> = ({
  provider,
  comparable,
  channelScores,
  view
}) => {
  const comparison = useComparison()
  const classes = useStyles()

  const toggleAddToCompare = (e: any, key: string) => {
    e.stopPropagation()
    const channelScore = channelScores.find((p) => p.key === key)
    if (!channelScore) return
    comparison.dispatch({ type: 'TOGGLE_COMPARISON_ITEM_IN_STATE', payload: { item: channelScore, view } })
  }

  const selected = comparison.state[view].filter((c) => {
    return c.value.key === provider.key
  })

  const isChecked = selected.length > 0
  const disabled = !isChecked && comparison.state[view].length === 10

  return (
    <Sticker style={{ borderBottom: '1px solid #D9DBE0', backgroundColor: 'white' }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {comparable && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Radio
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                textAlign: 'center'
              }}
              checked={isChecked}
              disabled={disabled}
              onClick={(e) => toggleAddToCompare(e, provider.key)}
              classes={{ root: classes.radio }}
              disableRipple={true}
              icon={<UncheckedIcon />}
              inputProps={{ 'aria-label': provider.providerName }}
              checkedIcon={<CheckedIcon />}
            />
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img style={{ maxHeight: '24px', width: 'auto' }} src={provider.providerLogo} alt={''} />
        </div>
        <div
          style={{
            marginTop: '8px',
            marginBottom: '8px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <span>
            <Typography variant={'body2'}>{provider.marketName}</Typography>
          </span>
        </div>
        <div
          style={{
            marginTop: '8px',
            marginBottom: '8px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <div
            style={{
              marginBottom: '4px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <ChannelBadge
              info={false}
              channel={provider.channel}
              channelName={provider.channelName}
              providerName={provider.providerName}
              auditDate={provider.audit_date}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SegmentBadge segment={provider.segment} segmentName={provider.segmentName} />
          </div>
        </div>
      </div>
    </Sticker>
  )
}

export type FunctionViewQuestionCellProps = {
  provider: TransformedChannel
  journey: FeatureJourney
  frictionScore?: FrictionScoreType
  value?: number
  question: QuestionType
}

export const FunctionViewQuestionCell: FunctionComponent<FunctionViewQuestionCellProps> = ({
  provider,
  journey,
  frictionScore,
  value,
  question
}) => {
  return (
    <Cell>
      <EqualHeightElement name={`${journey.key}#question#${question.id}`}>
        <CellElement>
          <FrictionScore.Dropdown
            guid={`${provider.key}#${journey.key}#${question.id}`}
            channelScore={provider}
            journey={journey.id}
            question={question.id}
            score={frictionScore}
            value={value}
            provider={provider}
          />
        </CellElement>
      </EqualHeightElement>
    </Cell>
  )
}

export type FunctionViewJourneyCellProps = {
  provider: TransformedChannel
  journey: FeatureJourney
  frictionScores: FrictionData[]
  sortedQuestion?: number
  usesVoc: boolean
}

export const FunctionViewJourneyCell: FunctionComponent<FunctionViewJourneyCellProps> = ({
  provider,
  journey,
  frictionScores,
  sortedQuestion,
  usesVoc
}) => {
  const frictionData = frictionScores.find((fd) => fd.auditId === provider.auditId)
  const expandable = useExpandable()

  const currentJourney = provider.answers.find((pj) => {
    return pj.journeyId === journey.id
  })

  let functionCount = 0

  if (currentJourney) {
    functionCount = currentJourney.answers.filter((a) => {
      return a.value === 1
    }).length
  }

  let frictionScoresCount = 0

  if (frictionData) {
    const questions = journey.questions.map((q) => q.id)
    frictionScoresCount = frictionData.friction_scores.filter((fs) => questions.includes(fs.question)).length
  }

  return (
    <>
      <Cell
        style={{
          borderTop: '1px solid #D9DBE0',
          backgroundColor:
            expandable.state.expanded.length !== 0
              ? expandable.state.expanded.includes(journey.key)
                ? prominentColour
                : baseColour
              : prominentColour
        }}
      >
        <EqualHeightElement name={journey.key}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {currentJourney && (
              <Typography
                variant={'body2'}
                style={{
                  color: '#647386',
                  fontSize: '12px',
                  lineHeight: '15px',
                  letterSpacing: '-0.17px',
                  fontWeight: 'bold'
                }}
              >
                {functionCount === 1 ? `${functionCount} Function` : `${functionCount} Functions`}
              </Typography>
            )}

            {frictionScoresCount > 0 && (
              <Typography
                variant={'body2'}
                style={{
                  color: '#647386',
                  fontSize: '12px',
                  lineHeight: '15px',
                  letterSpacing: '-0.17px',
                  fontWeight: 'bold'
                }}
              >
                {frictionScoresCount === 1
                  ? `${frictionScoresCount} Friction Score`
                  : `${frictionScoresCount} Friction Scores`}
              </Typography>
            )}
          </div>
        </EqualHeightElement>
      </Cell>

      <Expandable.Group name={journey.key}>
        <div className={'oddly'}>
          {journey.questions
            .filter((question) => showQuestion(expandable.state, sortedQuestion, journey, question))
            .map((question, qIdx) => {
              const frictionScore = frictionData?.friction_scores.find((fs) => fs.question === question.id)
              const value = currentJourney?.answers.find((a) => a.questionId === question.id)
              return (
                <FunctionViewQuestionCell
                  key={qIdx}
                  provider={provider}
                  journey={journey}
                  frictionScore={frictionScore}
                  value={value?.value}
                  question={question}
                />
              )
            })}
          {expandable.state.expanded.includes(journey.key) && (
            <>
              {usesVoc && (
                <Cell>
                  <EqualHeightElement name={`${journey.key}#cx_score`}>
                    <CellElement>{currentJourney && <Score score={currentJourney.cx_score} />}</CellElement>
                  </EqualHeightElement>
                </Cell>
              )}
              <Cell>
                <EqualHeightElement name={`${journey.key}#score`}>
                  <CellElement>{currentJourney && <Score score={currentJourney.score} />}</CellElement>
                </EqualHeightElement>
              </Cell>
            </>
          )}
        </div>
      </Expandable.Group>
    </>
  )
}
