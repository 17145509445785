import React, { PropsWithChildren, useMemo } from 'react'
import { Box, Popover as MaterialPopover, Typography } from '@material-ui/core'
import { DeviceLaptopIcon, DevicePhoneIcon, DeviceTabletIcon, Information } from './icons'
import { withStyles } from '@material-ui/core/styles'

const Badge = withStyles({
  root: {
    fontSize: '10px',
    lineHeight: '12px',
    padding: '6px 8px',
    color: '#647386',
    display: 'inline-block',
    borderRadius: '20px',
    '&:not(:only-child):not(:last-child)': {
      marginRight: '8px'
    }
  }
})(Box)

const Popover = withStyles({
  root: {
    position: 'relative',
    marginTop: '12px',
    borderRadius: '9px',
    pointerEvents: 'none',
    filter: 'drop-shadow( 0px 3px 9px rgba(65,69,88,0.25))'
  },
  paper: {
    '&:before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      padding: '0',
      margin: '0',
      boxSizing: 'border-box',
      top: '-10px',
      left: '12px',
      width: '22px',
      height: '10px',
      clipPath: 'polygon(50% 0, 0% 100%, 100% 100%)',
      backgroundColor: 'white',
      zIndex: '9999'
    }
  }
})(MaterialPopover)

const styles = (channel: number): React.CSSProperties => {
  switch (channel) {
    case 1:
      return {
        color: '#2c6be8',
        border: '1px solid #f3f9ff',
        backgroundColor: '#f3f9ff'
      }
    case 2:
      return {
        color: '#428100',
        border: '1px solid #f6faf2',
        backgroundColor: '#f6faf2'
      }
    case 4:
      return {
        color: '#1d7b8c',
        border: '1px solid #e9f8fa',
        backgroundColor: '#e9f8fa'
      }
    case 5:
      return {
        color: '#b620e0',
        border: '1px solid #fbf4fd',
        backgroundColor: '#fbf4fd'
      }
  }

  return {}
}

const icons = (channel: number): JSX.Element | null => {
  switch (channel) {
    case 1:
      return <DeviceLaptopIcon styles={{ marginRight: '5px' }} />
    case 2:
      return <DevicePhoneIcon fill="#428100" styles={{ marginRight: '5px' }} />
    case 4:
      return <DeviceTabletIcon styles={{ marginRight: '5px' }} />
    case 5:
      return <DevicePhoneIcon styles={{ marginRight: '5px' }} />
  }

  return null
}

type ChannelBadgeProps = {
  channel: number
  channelName: string
  providerName: string
  auditDate: string
  version?: string
  info?: boolean
}

export const ChannelBadge = (props: PropsWithChildren<ChannelBadgeProps>) => {
  const { version = '', channel, channelName, providerName, auditDate, info = true } = props

  if (!channel) return null

  const icon = icons(channel)
  const style = styles(channel)

  return (
    <Badge style={{ ...style, position: 'relative' }} display={'flex'}>
      {info ? (
        <ChannelBadgePopover
          version={version}
          providerName={providerName ?? 'Unknown'}
          channelName={channelName}
          auditDate={auditDate}
          disabled={!info}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {icon && icon}
            {channelName}
            {info && (
              <span style={{ marginLeft: '4px' }}>
                <Information />
              </span>
            )}
          </div>
        </ChannelBadgePopover>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {icon && icon}
          {channelName}
        </div>
      )}
    </Badge>
  )
}

type ChannelBadgePopoverProps = {
  version: string
  providerName: string
  channelName: string
  auditDate: string
  disabled?: boolean
}

const ChannelBadgePopover = (props: PropsWithChildren<ChannelBadgePopoverProps>) => {
  const { disabled = false } = props

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    if (!disabled) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const date = useMemo(() => new Date(props.auditDate).toLocaleDateString(), [props.auditDate])
  const id = useMemo(
    () => `${props.providerName}_${props.channelName}_${props.auditDate}`,
    [props.providerName, props.channelName, props.auditDate]
  )

  return (
    <div style={{ position: 'relative' }}>
      <span
        style={{ position: 'relative' }}
        aria-owns={open ? id : undefined}
        aria-haspopup="true"
        // onClick={handlePopoverOpen} // - use this for debug
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {props.children}
      </span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        disablePortal={true}
        disableRestoreFocus
      >
        <div style={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant={'h4'}>Channel:</Typography>
            <Typography style={{ marginLeft: '5px' }} variant={'body1'}>
              {props.version}
            </Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant={'h4'}>Audited:</Typography>
            <Typography style={{ marginLeft: '5px' }} variant={'body1'}>
              {date}
            </Typography>
          </div>
        </div>
      </Popover>
    </div>
  )
}
