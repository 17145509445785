import { Dropdown } from './Filter/Dropdown'
import { Group } from './Filter/Option/Group'
import { useCriteria } from '../../contexts/Search/Criteria'
import { useSearchQuery } from '../../contexts/Search/Query'
import { SearchKind } from '../../declarations/SearchCriteria'

export const ChannelFilter = () => {
  const searchCriteria = useCriteria()
  const searchQuery = useSearchQuery()

  const handleSelectedChange = <K extends keyof SearchKind>(key: K, value: number, state: boolean) => {
    let channels = searchQuery.state.channels

    if (!state && channels.includes(value)) {
      channels.splice(
        channels.findIndex((item) => item === value),
        1
      )
    } else {
      channels.push(value)
    }

    searchQuery.dispatch({ type: 'SET_CHANNEL_QUERY_STATE', payload: { value: channels } })
  }

  const handleSelectAll = <K extends keyof SearchKind>(key: K, state: boolean) => {
    if (state) {
      let options = searchCriteria.state.channels
      let channels = options.map((crit) => crit.id)

      searchQuery.dispatch({ type: 'SET_CHANNEL_QUERY_STATE', payload: { value: channels } })
    } else {
      searchQuery.dispatch({ type: 'SET_CHANNEL_QUERY_STATE', payload: { value: [] } })
    }
  }

  return (
    <Dropdown
      label={'Channels'}
      count={searchQuery.state.channels.length}
      total={searchCriteria.state.channels.length}
      disabled={searchCriteria.state.loading}
    >
      <Group
        groupKey={'channels'}
        onSelected={handleSelectedChange}
        onSelectAll={handleSelectAll}
        criteria={searchCriteria.state.channels}
        selected={searchQuery.state.channels}
      />
    </Dropdown>
  )
}
