import { FunctionComponent } from 'react'
import { Button } from '@material-ui/core'
import { Cog } from '../Common/icons'

type DemographicsButtonProps = {
  onOpen: () => void
}

export const DemographicsButton: FunctionComponent<DemographicsButtonProps> = ({ onOpen }) => {
  return (
    <Button
      size={'small'}
      variant="contained"
      color="default"
      style={{
        backgroundColor: 'white',
        fontSize: '14px',
        padding: '10px 20px',
        borderRadius: '20px',
        color: '#647386',
        textTransform: 'none',
        whiteSpace: 'nowrap'
      }}
      endIcon={<Cog />}
      onClick={onOpen}
    >
      VoC Setup
    </Button>
  )
}

export {}
