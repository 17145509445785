const colors = [
  '#001433',
  '#E5126E',
  '#1A73E8',
  '#8B96A4',
  '#A6FE00',
  '#FA6500',
  '#44D7B6',
  '#6236FF',
  '#B620E0',
  '#E5126E'
]

export default colors
