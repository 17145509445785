import { Box, Typography } from '@material-ui/core'
import { CheckCircle, HighlightOff } from '@material-ui/icons'
import { useCriteria } from '../../../contexts/Search/Criteria'
import { Modal } from '../../Modal/Modal'
import { ChannelScore } from '../../../declarations/ChannelScore'
import { Asset } from '../../../declarations/Asset'

type FunctionsProps = {
  channelScore: ChannelScore
  asset: Asset
  onClose: () => void
}

export const Functions = (props: FunctionsProps) => {
  const searchCriteria = useCriteria()

  const renderFeatures = () => {
    const related = searchCriteria.state.questions.questions.filter((r) => {
      return props.asset.questions.includes(r.id) && props.asset.segment === r.segmentId
    })

    return (
      <Box paddingY={1}>
        {related.map((q, index) => {
          const weightedJourneyScore = props.channelScore.journeys.find((weightedJourneyScore, i) => {
            return weightedJourneyScore.journey === q.journeyId
          })

          if (!weightedJourneyScore) {
            return null
          }

          const answer = weightedJourneyScore.answers.find((a, x) => {
            return q.id === a.question.id
          })

          if (!answer) {
            return null
          }

          return (
            <Box
              display={'flex'}
              alignItems={'center'}
              paddingY={1}
              key={index}
              style={{ borderTop: '1px solid #D9DBE0' }}
            >
              <Box display={'flex'} alignItems={'center'} component={'span'} marginRight={1}>
                {answer.value ? (
                  <CheckCircle fontSize="small" style={{ color: '#24AB3B' }} />
                ) : (
                  <HighlightOff fontSize="small" style={{ color: '#8B96A4' }} />
                )}
              </Box>
              <Typography variant={'body2'}>{answer.question.text}</Typography>
            </Box>
          )
        })}
      </Box>
    )
  }

  return (
    <Box position={'relative'}>
      <Modal
        bgColor={'rgba(255,255,255,0.85)'}
        backdrop={false}
        size={'md'}
        fullHeight={false}
        title={'Functions'}
        onClose={props.onClose}
        closeable
        open={props.asset !== undefined}
        rounded
      >
        {renderFeatures()}
      </Modal>
    </Box>
  )
}
