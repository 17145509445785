export const TabChannelView = 'channel'
export const TabJourneyView = 'journey'
export const TabFunctionView = 'function'
export const TabRankView = 'rank'

export type ChannelTabs = typeof TabChannelView | typeof TabJourneyView | typeof TabFunctionView | typeof TabRankView

export type MenuItem = {
  key: ChannelTabs
  label: string
  icon: any
}
