import { Auth } from '@aws-amplify/auth'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { AmplifySuccess, AmplifyUnknownError } from '../../types/Amplify'

type UpdateUserAttributesResult = AmplifySuccess | AmplifyUnknownError

/**
 * Handles updating the user attributes via the amplify sdk.
 *
 * @param {CognitoUser} user
 * @param {Record<string, string>} attributes
 *
 * @returns {Promise<UpdateUserAttributesResult>}
 */
export const updateUserAttributes = async (
  user: CognitoUser,
  attributes: Record<string, string>
): Promise<UpdateUserAttributesResult> => {
  try {
    await Auth.updateUserAttributes(user, attributes)

    return { type: 'success' }
  } catch (caught) {
    return { type: 'unknown-error', exception: caught }
  }
}
