import { Auth } from '@aws-amplify/auth'
import { CognitoError } from '../../types/Cognito'
import { AmplifySuccess, AmplifyUnknownError, AmplifyUserNotFound } from '../../types/Amplify'
import { UNEXPECTED_ERROR_MESSAGE } from '../../helpers/messages'

type ResendVerificationCodeResult = AmplifySuccess | AmplifyUserNotFound | AmplifyUnknownError

/**
 * Handles sending the verification code for sign up via the amplify sdk.
 *
 * @param {string} username
 *
 * @returns {Promise<ResendVerificationCodeResult>}
 */
export const resendVerificationCode = async (username: string): Promise<ResendVerificationCodeResult> => {
  try {
    await Auth.resendSignUp(username)

    return { type: 'success' }
  } catch (caught) {
    if ((caught as CognitoError).code === undefined) {
      return { type: 'unknown-error', exception: caught }
    }

    switch ((caught as CognitoError).code) {
      case 'UserNotFoundException':
        return {
          type: 'user-not-found',
          error: UNEXPECTED_ERROR_MESSAGE
        }
    }

    return { type: 'unknown-error', exception: caught }
  }
}
