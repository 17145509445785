import { FunctionComponent, useEffect, useState } from 'react'
import { BlogList } from '../../components/Blog/BlogList'
import { Blog } from '../../support/contentful/content-types'
import { getInsights } from '../../services/api/get-insights'
import { useApolloClient } from '@apollo/client'

const DashboardBlogList: FunctionComponent = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [posts, setPosts] = useState<Blog[]>([])
  const apolloClient = useApolloClient()

  useEffect(() => {
    const loadBlogPosts = async () => {
      const { insights } = await getInsights(apolloClient, { order: '-fields.originalPostDate', limit: 4 })
      setPosts(insights)
      setLoading(false)
    }
    loadBlogPosts()
  }, [apolloClient])

  if (loading) return null

  return <BlogList items={posts} />
}

export default DashboardBlogList
