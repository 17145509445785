import { Box, Divider } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { FunctionComponent } from 'react'
import { SortOption } from '../../components/SearchSorting/SortingDropdown'
import { useSearchQuery } from '../../contexts/Search/Query'
import { useSearchResults } from '../../contexts/Search/Results'
import { TabChannelView, TabFunctionView, TabJourneyView, TabRankView } from '../../types/Channel'
import { Pagination } from './Pagination'
import { Count } from './Results.styled'
import { Channels } from './Results/Channels'
import { Functions } from './Results/Functions'
import { Journeys } from './Results/Journeys'
import { Rank } from './Results/Rank'
import { FilterToolbar } from './Toolbar/FilterToolbar/FilterToolbar'

type SearchResultsProps = {
  view: string
  onOpenDemographics: () => void
  onFrictionScoreSort: () => void
  onViewChange: (view: string) => void
  onTriggerSearch: () => void
}

export const SearchResults: FunctionComponent<SearchResultsProps> = ({
  view,
  onViewChange,
  onTriggerSearch,
  onOpenDemographics,
  onFrictionScoreSort
}) => {
  const searchResults = useSearchResults()
  const searchQuery = useSearchQuery()

  const onSortChange = (option: SortOption) => {
    if (option.field === 'FRICTION') {
      onFrictionScoreSort()
    } else {
      onTriggerSearch()
    }
  }

  const usesVoc = searchQuery.state.selectedIndustry.usesVoc

  return (
    <Box marginBottom={6}>
      <Box display="flex" justifyContent="space-between" justifyItems={'center'} alignItems={'center'} marginBottom={2}>
        <Box display={'flex'} justifyItems={'center'} alignItems={'baseline'} style={{ whiteSpace: 'nowrap' }}>
          {searchResults.state.loading ? (
            <Skeleton variant={'text'} height={48} width={400} />
          ) : (
            <>
              <span>{searchResults.state.count ? <Count>{searchResults.state.count} channels</Count> : false}</span>
            </>
          )}
        </Box>

        <FilterToolbar
          onOpenDemographics={onOpenDemographics}
          onViewChange={onViewChange}
          onSortChange={onSortChange}
          sortedOnText={searchQuery.state.sort.friction_question_text}
          sortType={searchQuery.state.sort.label}
          view={view}
          usesVoc={usesVoc}
        />
      </Box>

      <Divider />

      <Box marginTop={4} marginBottom={4}>
        {view === TabChannelView && <Channels channelScores={searchResults.state.channels} />}

        {view === TabFunctionView && (
          <Functions
            channelScores={searchResults.state.channels}
            sortedOnText={searchQuery.state.sort.friction_question_text}
            usesVoc={usesVoc}
          />
        )}

        {view === TabRankView && (
          <Rank
            channelScores={searchResults.state.channels}
            sortedOnText={searchQuery.state.sort.friction_question_text}
            usesVoc={usesVoc}
          />
        )}

        {view === TabJourneyView && (
          <Journeys
            channelScores={searchResults.state.channels}
            stacks={searchResults.state.stacks}
            usesVoc={usesVoc}
          />
        )}
      </Box>

      <Pagination onTriggerSearch={onTriggerSearch} pagination={searchQuery.state.pagination} />
    </Box>
  )
}
