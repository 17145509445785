import { MP4Icon, PDFIcon, PPTXIcon } from './icons'
import { InsertDriveFileOutlined } from '@material-ui/icons'
import { useMemo } from 'react'

type ResourceIconProps = {
  type: string
}

const icon = (type: string) => {
  switch (type) {
    case 'application/pdf':
      return PDFIcon
    case 'application/vnd.ms-powerpoint':
      return PPTXIcon
    case 'video/mp4':
      return MP4Icon
    default:
      return InsertDriveFileOutlined
  }
}

export const ResourceIcon = (props: ResourceIconProps) => {
  const IconComponent = useMemo(() => {
    return icon(props.type)
  }, [props.type])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '15px'
      }}
    >
      <IconComponent />
    </div>
  )
}
