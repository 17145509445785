import { Typography } from '@material-ui/core'
import styled from 'styled-components'

// Acumin Pro Bold
// font-family: acumin-pro,sans-serif
// font-weight: 700
// font-style: normal;

// Acumin Pro Bold Italic
// font-family: acumin-pro, sans-serif;
// font-weight: 700;
// font-style: italic;

// Acumin Pro Italic
// font-family: acumin-pro, sans-serif;
// font-weight: 400;
// font-style: italic;

// Acumin Pro Regular
// font-family: acumin-pro, sans-serif;
// font-weight: 400;
// font-style: normal;

// Acumin Pro Wide Regular
// font-family: acumin-pro-wide, sans-serif;
// font-weight: 400;
// font-style: normal;

export const BodyText = styled(Typography)`
  && {
    font-size: 14px !important;
    line-height: 21px !important;
    color: #68788b !important;
  }
`

export const BodyTextWhite = styled(Typography)`
  && {
    font-size: 14px !important;
    line-height: 21px !important;
    color: white !important;
  }
`

export const BodyTextWhiteBold = styled(Typography)`
  && {
    font-size: 14px !important;
    line-height: 21px !important;
    color: white !important;
    font-weight: bold;
  }
`

export const BodyTextSmall = styled(Typography)`
  && {
    font-size: 12px !important;
    line-height: 14px !important;
    color: #68788b !important;
  }
`

export const BodyTextSmall2 = styled(Typography)`
  && {
    font-size: 12px !important;
    line-height: 15px !important;
    letter-spacing: -0.15px !important;
    color: #001433 !important;
    font-weight: bold !important;
  }
`

export const DetailTitle = styled(Typography)`
  && {
    color: #021d3a !important;
    font-size: 24px !important;
    line-height: 27px !important;
    font-weight: 700 !important;
  }
`

export const DetailDate = styled(Typography)`
  && {
    color: #021d3a !important;
    font-size: 18px !important;
    line-height: 27px !important;
  }
`

export const DetailSecondaryTitle = styled(Typography)`
  && {
    color: #001433 !important;
    font-size: 18px !important;
    line-height: 27px !important;
  }
`

export const FilterText = styled(Typography)`
  && {
    color: #68788b;
    font-size: 0.9em !important;
    font-weight: 600 !important;
  }
`

export const SegmentTag = styled(Typography)`
  && {
    font-size: 12px !important;
    line-height: 18px !important;
    color: #68788b !important;
  }
`

export const TitleText = styled(Typography)`
  && {
    font-weight: 700 !important;
    color: #021d3a;
    font-size: 0.9em !important;
    text-transform: initial;
  }
`

export const StackSubheading = styled(Typography)`
  && {
    color: #021d3a !important;
    font-size: 14px !important;
    line-height: 14px !important;
  }
`
