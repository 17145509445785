import { ApolloClient, gql } from '@apollo/client'
import { Sectors } from '../../types/Sectors'
import { QuestionBase, Questions, Weight, QuestionJourneyBase } from '../../declarations/Question'
import { getSurveySegmentFromSector } from '../../types/Segments'

type QuestionRaw = QuestionBase & {
  weights: Weight[]
}

type QuestionJourneyRaw = QuestionJourneyBase & {
  weights: Weight[]
}

/**
 * The response type for the api request.
 */
type GetQuestionsResponse = {
  getQuestions: {
    questions: QuestionRaw[]
    journeys: QuestionJourneyRaw[]
  }
}

/**
 * The variables used for the request passed in from the caller.
 */
type GetQuestionsVariables = {
  sector: Sectors
  audience: any
}

/**
 * The query to use when running the request.
 */
const query = gql`
  query questions($sector: Int!, $audience: AudienceSearch) {
    getQuestions(sector: $sector, audience: $audience) {
      questions {
        weights {
          segment
          weight
        }
        id
        journey
        journeyId
        predecessor
        questionnaire_version
        sector
        sectorId
        secure_channel
        secure_channelId
        segment
        segmentId
        text
        has_friction_scores
        valid_from
        valid_to
      }
      journeys {
        journey
        journeyId
        weights {
          segment
          weight
        }
      }
    }
  }
`

/**
 * Gets the question from the api.
 *
 * @param {ApolloClient<object>} client
 * @param {GetQuestionsVariables} variables
 *
 * @throws Error
 */
export const getQuestions = async (
  client: ApolloClient<object>,
  variables: GetQuestionsVariables
): Promise<Questions> => {
  try {
    const response = await client.query<GetQuestionsResponse, GetQuestionsVariables>({ query, variables })

    // for historical reasons the server returns an array of weights, but we want to only return the correct one (or none)
    const rawQuestions = response.data.getQuestions
    const surveySegment = getSurveySegmentFromSector(variables.sector)
    return {
      questions: rawQuestions.questions.map((question) => ({
        ...question,
        weight: question.weights.find((weight) => weight.segment === surveySegment),
        weights: undefined
      })),
      journeys: rawQuestions.journeys.map((journey) => ({
        ...journey,
        weight: journey.weights.find((weight) => weight.segment === surveySegment),
        weights: undefined
      }))
    }
  } catch (caught) {
    throw Error()
  }
}
