import { ApolloClient, useApolloClient } from '@apollo/client'
import { Box, Button, Container, FormControlLabel, Grid, Modal, Paper, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useCriteria } from '../../contexts/Search/Criteria'
import { useSearchQuery } from '../../contexts/Search/Query'
import { getAudienceCriteria } from '../../services/api/get-audience-criteria'
import { getQuestions } from '../../services/api/get-questions'
import colors from '../../support/theme/colors'
import { Sectors } from '../../types/Sectors'
import { DemographicsBadge } from '../Common/DemographicsBadge'
import { Loading } from '../Common/Loading'
import { GreenCheckbox, StyledBox } from './DemographicsModal.styled'
import { getIncomeSurveySegmentFromSector, getSurveySegmentFromSector } from '../../types/Segments'
import { AudienceCriteria, AudienceCriterion } from '../../declarations/Audience'

const loadAudienceCriteria = (client: ApolloClient<object>, sector: Sectors) => {
  return getAudienceCriteria(client, { sector })
}

type DemographicsModalProps = {
  onClose: () => void
  onTriggerSearch: () => void
}

export const DemographicsModal: FunctionComponent<DemographicsModalProps> = ({ onClose, onTriggerSearch }) => {
  const client = useApolloClient()
  const searchQuery = useSearchQuery()
  const searchCriteria = useCriteria()

  const [audienceCheckboxes, setAudienceCheckboxes] = useState<Record<string, number[]>>({})
  const [criteria, setCriteria] = useState<AudienceCriteria>()
  const [loading, setLoading] = useState<boolean>(true)

  const [isApplying, setIsApplying] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)

    const checkboxes = Object.assign(
      {},
      {
        market: Array.from(searchQuery.state.audience.market),
        age: Array.from(searchQuery.state.audience.age),
        income: Array.from(searchQuery.state.audience.income),
        gender: Array.from(searchQuery.state.audience.gender)
      }
    )

    loadAudienceCriteria(client, searchQuery.state.selectedCycle.linkedSector)
      .then((response) => {
        setCriteria(response)
        setAudienceCheckboxes(checkboxes)
      })
      .then(() => setLoading(false))
  }, [
    client,
    searchQuery.state.audience.age,
    searchQuery.state.audience.gender,
    searchQuery.state.audience.income,
    searchQuery.state.audience.market,
    searchQuery.state.selectedCycle.linkedSector
  ])

  const segment = useMemo(
    () => getSurveySegmentFromSector(searchQuery.state.selectedCycle.linkedSector)!,
    [searchQuery.state.selectedCycle.linkedSector]
  )

  const handleClose = () => {
    onClose()
  }

  const handleApply = async () => {
    setIsApplying(true)

    getQuestions(client, {
      sector: searchQuery.state.selectedCycle.linkedSector,
      audience: audienceCheckboxes
    })
      .then((response) => {
        searchCriteria.dispatch({
          type: 'SET_QUESTIONS_CRITERIA_STATE',
          payload: { value: response }
        })

        searchQuery.dispatch({
          type: 'SET_AUDIENCE_QUERY_STATE',
          payload: { value: audienceCheckboxes }
        })

        onTriggerSearch()
      })
      .then(() => {
        onClose()
      })
  }

  const handleCheckboxClicked = (type: string, id: number) => {
    let checkboxes = Object.assign({}, {}, audienceCheckboxes)

    if (checkboxes[type] !== undefined) {
      const index = checkboxes[type].findIndex((value) => value === id)

      if (index >= 0) {
        checkboxes[type].splice(index, 1)
      } else {
        checkboxes[type].push(id)
      }
    }

    setAudienceCheckboxes(checkboxes)
  }

  const isSelected = (type: string, id: number) => {
    if (audienceCheckboxes[type] !== undefined) {
      return audienceCheckboxes[type].includes(id)
    }

    return false
  }

  return (
    <Modal open={true} onClose={handleClose} style={{ outline: 'none', display: 'flex', alignItems: 'center' }}>
      <Container
        maxWidth="lg"
        style={{
          outline: 'none',
          maxHeight: '90vh'
        }}
      >
        <Paper style={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Box bgcolor={'#F6F8FA'}>
            <Box
              bgcolor={colors.main}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding={2}
              style={{
                color: 'white',
                borderTopLeftRadius: '12px',
                borderTopRightRadius: '12px'
              }}
            >
              <Typography variant={'h4'} style={{ color: 'white' }}>
                VoC Setup
              </Typography>
              <Close onClick={handleClose} style={{ color: 'white', cursor: 'pointer' }} />
            </Box>
            <Box
              padding={2}
              display={'flex'}
              flexDirection={'column'}
              style={{
                borderBottomLeftRadius: '12px',
                borderBottomRightRadius: '12px',
                maxHeight: 'calc(80vh - 56px)',
                maxWidth: '100%',
                overflowY: 'auto',
                margin: '0 auto'
              }}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                style={{
                  width: '792px',
                  margin: '0 auto'
                }}
              >
                <StyledBox>
                  {/*@TODO needs backend work*/}
                  {/*<Typography variant={"h3"}>Introductory title (if needed)</Typography>*/}
                  <p>
                    VoC Score™ applies a weight to each function to highlight those that customers deem more or less
                    important to their experience. The higher the function weighting, the higher the importance to the
                    customer segment
                  </p>
                  <p>
                    With the filters below you can define the customer segment of interest, and those choices will alter
                    the weightings applied to VoC Scores for the industry, throughout the site. The results reflect the
                    opinions of survey participants (i.e. ‘real’ customers), without regard for their service provider.
                  </p>
                </StyledBox>
                <Box
                  borderRadius={12}
                  paddingX={2}
                  paddingTop={2}
                  paddingBottom={4}
                  style={{ backgroundColor: 'white', boxShadow: '0 1px 3px 0 rgba(65,69,88,0.2)', marginBottom: 32 }}
                >
                  {loading ? (
                    <Loading.Box width={'100%'} height={400} />
                  ) : (
                    <Grid container spacing={3}>
                      {criteria &&
                        Object.entries<AudienceCriterion[]>(criteria)
                          .filter((criteria) => {
                            const [name] = criteria
                            return name !== '__typename'
                          })
                          .map((criteria, z) => {
                            const [name, criterions] = criteria

                            if (name === 'income') {
                              const surveySegment = getIncomeSurveySegmentFromSector(
                                searchQuery.state.selectedCycle.linkedSector
                              )
                              const values = criterions.filter((x) => x.segment === surveySegment)
                              return (
                                <React.Fragment key={z}>
                                  <Grid container item xs={3} justifyContent={'flex-end'}>
                                    <Typography
                                      style={{
                                        fontSize: '14px',
                                        color: '#001433',
                                        fontWeight: 600,
                                        textTransform: 'capitalize',
                                        paddingTop: '9px'
                                      }}
                                      variant={'subtitle2'}
                                    >
                                      {name}
                                    </Typography>
                                  </Grid>
                                  <Grid container item xs={9}>
                                    <Grid item xs={6}>
                                      {values.map((value) => (
                                        <FormControlLabel
                                          key={`${name}_${value.id}`}
                                          style={{ whiteSpace: 'nowrap' }}
                                          control={
                                            <GreenCheckbox
                                              checked={isSelected(name, value.id)}
                                              onChange={() => handleCheckboxClicked(name, value.id)}
                                              name={`${name}_${value.id}`}
                                            />
                                          }
                                          label={value.name}
                                        />
                                      ))}
                                    </Grid>
                                  </Grid>
                                </React.Fragment>
                              )
                            } else {
                              return (
                                <React.Fragment key={z}>
                                  <Grid container item xs={3} justifyContent={'flex-end'}>
                                    <Typography
                                      style={{
                                        fontSize: '14px',
                                        color: '#001433',
                                        fontWeight: 600,
                                        textTransform: 'capitalize',
                                        paddingTop: '9px'
                                      }}
                                      variant={'subtitle2'}
                                    >
                                      {name}
                                    </Typography>
                                  </Grid>
                                  <Grid container item xs={9}>
                                    {criterions.map((criterion, x) => {
                                      return (
                                        <Grid item xs={4} key={x}>
                                          <FormControlLabel
                                            style={{ whiteSpace: 'nowrap' }}
                                            control={
                                              <GreenCheckbox
                                                checked={isSelected(name, criterion.id)}
                                                onChange={() => handleCheckboxClicked(name, criterion.id)}
                                                name={`${name}_${criterion.id}`}
                                              />
                                            }
                                            label={criterion.name}
                                          />
                                        </Grid>
                                      )
                                    })}
                                  </Grid>
                                </React.Fragment>
                              )
                            }
                          })}
                      <Grid item xs={3} /> {/*Pusher // not sure how to do offset*/}
                      <Grid item xs={9}>
                        <Box display={'flex'} alignItems={'center'}>
                          <Button
                            color={'secondary'}
                            variant={'contained'}
                            onClick={handleApply}
                            style={{ boxShadow: 'none' }}
                            disabled={isApplying}
                          >
                            {isApplying ? 'Applying ... ' : 'Apply'}
                          </Button>
                          <Button
                            color={'secondary'}
                            variant={'text'}
                            onClick={handleClose}
                            style={{ marginLeft: '20px', boxShadow: 'none' }}
                            disabled={isApplying}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </Box>
                <StyledBox>
                  <Box display={'flex flex-col'}>
                    <div style={{ marginRight: '5px' }}>
                      <DemographicsBadge segment={segment} audience={audienceCheckboxes} />
                    </div>
                  </Box>
                  <p>
                    Results generated by a cross-sectional, closed ended survey incorporating the benchmarked Functions
                    in Radar. Values reflect the proportion of survey participants that identified this benchmark as a
                    priority in their digital experience.
                  </p>
                </StyledBox>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Container>
    </Modal>
  )
}
