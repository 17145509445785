import { Box } from '@material-ui/core'
import { FunctionComponent } from 'react'
import { PageNavigator } from './PageNavigator'
import { PageSelector } from './PageSelector'
import { TotalsTypography } from './TotalsTypography'

/**
 * Base interface for pagination. Usually used with the PaginationWithPagesProps or PaginationWithHitsProps
 * to determin on how we sort and how pages are calculated.
 */
export interface BasePaginationProps {
  onPageNavigate: (page: number) => void
  page: number
}
/**
 * Used when we know the total number of pages.
 */
export interface PaginationWithPagesProps extends BasePaginationProps {
  meta: {
    limit: number
    skip: number
    totalPages: number
  }
}
/**
 * Used when we want to calculate the number of pages based on the total hits and the limit.
 */
export interface PaginationWithHitsProps extends BasePaginationProps {
  meta: {
    limit: number
    skip: number
    totalHits: number
  }
}

export type PaginationProps = PaginationWithPagesProps | PaginationWithHitsProps

export const Pagination: FunctionComponent<PaginationProps> = (props) => {
  // This code checks whether the totalPages property exists on the props object.
  // If it does, it checks whether the totalPages property has a value of 0.
  // If it does, it assigns the value of the page property to the totalPages variable.
  // If the totalPages property does not exist, it assigns the value of the totalHits property divided by the limit property to the totalPages variable.
  const totalPages =
    'totalPages' in props.meta
      ? props.meta.totalPages === 0
        ? props.page
        : props.meta.totalPages
      : Math.ceil(props.meta.totalHits / props.meta.limit)
  return (
    <Box marginY={2}>
      <Box display="flex" justifyContent="space-between" justifyItems={'center'} alignItems="center">
        <TotalsTypography page={props.page} totalPages={totalPages} />
        <Box display="flex" justifyContent="flex-end" style={{ borderRadius: '20px', color: '#8B96A4' }}>
          {props.page > 1 && (
            <PageNavigator
              direction={'left'}
              onPageNavigate={() => {
                props.onPageNavigate(props.page - 1)
              }}
            />
          )}

          <PageSelector onPageNavigate={props.onPageNavigate} totalPages={totalPages} page={props.page} />

          {props.page !== totalPages && (
            <PageNavigator
              direction={'right'}
              onPageNavigate={() => {
                props.onPageNavigate(props.page + 1)
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}
