import { uniqBy } from 'lodash'
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes'
import { FeatureJourney } from '../../types/Functions'
import getFormattedDate from '../date/getFormattedDate'
import { getSurveySegmentFromSegment } from '../../types/Segments'
import { FrictionData } from '../../declarations/FrictionScore'
import { TransformedChannel } from '../../hooks/functions'

export const exportChannels = async (
  filename: string,
  transformedChannels: TransformedChannel[],
  journeys: FeatureJourney[],
  frictionScoreData: FrictionData[],
  selected: 'cx_score' | 'score'
) => {
  const headers = await exportChannelsHeaders(transformedChannels, selected)
  const data = await exportChannelsData(transformedChannels, journeys, frictionScoreData, selected)

  return { headers, data, filename }
}

const exportChannelsHeaders = async (transformedChannels: TransformedChannel[], selected: 'cx_score' | 'score') => {
  let headers = [{ label: 'Function', key: 'function' }]
  let segments: LabelKeyObject[] = []
  let channelNames: LabelKeyObject[] = []

  transformedChannels.forEach((transformedChannel) => {
    if (selected === 'cx_score') {
      segments.push({
        label: 'Weight',
        key: 'segment-' + getSurveySegmentFromSegment(transformedChannel.segment)
      })
    }

    channelNames.push({
      label: transformedChannel.providerName,
      key: transformedChannel.key
    })
  })

  return headers.concat(uniqBy(segments, 'key')).concat(channelNames)
}

const exportChannelsData = async (
  transformedChannels: TransformedChannel[],
  journeys: FeatureJourney[],
  frictionScoreData: FrictionData[],
  selected: 'cx_score' | 'score'
) => {
  let data: Record<string, string>[] = []

  let channelRow = { function: 'Channel' }
  let segmentRow = { function: 'Segment' }
  let channelVersionRow = { function: 'Channel Version' }
  let auditDateRow = { function: 'Audit Date' }

  transformedChannels.forEach((transformedChannel) => {
    channelRow = {
      ...channelRow,
      [transformedChannel.key]: transformedChannel.channelName
    }

    segmentRow = {
      ...segmentRow,
      [transformedChannel.key]: transformedChannel.segmentName
    }

    channelVersionRow = {
      ...channelVersionRow,
      [transformedChannel.key]: transformedChannel.channel_version
    }

    auditDateRow = {
      ...auditDateRow,
      [transformedChannel.key]: getFormattedDate(transformedChannel.audit_date)
    }
  })

  data.push(channelRow)
  data.push(segmentRow)
  data.push(channelVersionRow)
  data.push(auditDateRow)

  journeys.forEach((journey) => {
    let functionRow = { function: journey.name }

    transformedChannels.forEach((transformedChannel) => {
      const channelJourney = transformedChannel.journeys.find((j) => {
        return j.journey === journey.id
      })

      const segment = getSurveySegmentFromSegment(transformedChannel.segment)

      functionRow = {
        ...functionRow,
        [transformedChannel.key]: channelJourney ? channelJourney[selected] : 0,
        [`segment-${segment}`]: journey.weight?.weight
      }
    })

    data.push(functionRow)
  })

  return data
}
