export const PASSWORD_POLICY = `Password must be a minimum length of 8 characters and contain at least 1 upper case character, 1 lower case character, 1 numeric character and 1 special character.`

/**
 * Handles validating password input for client side checks.
 *
 * @param {string} input
 */
export const fn = (input: string) => {
  return (
    input.match(/[a-z]/i) &&
    input.match(/[0-9]/) &&
    input.match(/[^0-9a-z]/) &&
    input.match(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/) &&
    input.length > 8
  )
}
