import AppRoutes from '../../AppRoutes'
import Layout from './Layout'

export const Guest = () => {
  return (
    <>
      <Layout>
        <AppRoutes />
      </Layout>
    </>
  )
}
