import { Box, Button, Divider, Paper } from '@material-ui/core'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { useSearchQuery } from '../../../contexts/Search/Query'
import { useCharts } from '../../../hooks/charts'
import colors from '../../../support/theme/colors'
import {
  BusinessBanking2021Sector,
  BusinessBanking2023Sector,
  LifeInsurance2021Sector,
  LifeInsurance2023Sector,
  PersonalBanking2021Sector,
  PersonalBanking2023Sector
} from '../../../types/Sectors'
import { SegmentBadge, SegmentBadgeBusinessAndCorporateSegment } from '../../Common/SegmentBadge'
import RadarChart from '../../RadarChart'
import { LifeInsurance2021Segment, Personal2021Segment } from '../../../types/Segments'
import { ChannelScore } from '../../../declarations/ChannelScore'

const ViewButton = styled(Button)`
  && {
    &.selected {
      background-color: #ffffff !important;
      color: ${colors.main} !important;
    }

    &.dark {
      background-color: #d9dbe0;
      color: ${colors.main} !important;
    }

    &.dark.selected {
      background-color: ${colors.main} !important;
      color: white !important;
    }

    background-color: transparent !important;
    color: #ffffff !important;
    box-shadow: none !important;
    border-radius: 9px !important;
  }
`

const ButtonContainer = styled(Box)`
  && {
    background-color: white;
    justify-content: space-between;
    padding: 4px;
    border-radius: 12px;
    display: flex;
    &.dark {
      background-color: white;
    }
  }
`

type ChannelProps = {
  channelScore: ChannelScore
  usesVoc: boolean
}

export const Channel = (props: ChannelProps) => {
  const [scoreType, setScoreType] = useState<'score' | 'cx_score'>(props.usesVoc ? 'cx_score' : 'score')

  return (
    <Box overflow={'visible'}>
      <Box minHeight="600px">
        <Box display="flex" justifyContent="center" padding={2}>
          <Paper>
            <ButtonContainer className="dark">
              {props.usesVoc && (
                <>
                  <ViewButton
                    className={`dark ${scoreType === 'cx_score' ? 'selected' : ''}`}
                    variant="contained"
                    onClick={() => setScoreType('cx_score')}
                  >
                    VoC Score
                  </ViewButton>
                  <Divider orientation="vertical" flexItem style={{ marginLeft: '4px', marginRight: '4px' }} />
                </>
              )}
              <ViewButton
                className={`dark ${scoreType === 'score' ? 'selected' : ''}`}
                variant="contained"
                onClick={() => setScoreType('score')}
              >
                Utility Score
              </ViewButton>
            </ButtonContainer>
          </Paper>
        </Box>
        <Box marginX={4} marginY={2}>
          <Chart type={scoreType} channelScore={props.channelScore} />
        </Box>
      </Box>
    </Box>
  )
}

type ChartProps = {
  channelScore: ChannelScore
  type: 'score' | 'cx_score'
}

const Chart = (props: ChartProps) => {
  const { single } = useCharts()
  const searchQuery = useSearchQuery()
  const currentSector = searchQuery.state.selectedCycle.linkedSector

  const chartData = useMemo(() => single(props.channelScore, props.type), [props.channelScore, props.type, single])

  return (
    <Paper>
      <Box display="flex" flexDirection={'column'} justifyContent="center" minHeight="600px" width="100%" padding={2}>
        <Box height="600px" width="900px" position="relative" paddingTop={4} paddingBottom={4} marginX={'auto'}>
          {chartData && (
            <RadarChart
              showPercentages={true}
              advanced={props.type === 'cx_score'}
              height={600}
              chartData={chartData}
            />
          )}
        </Box>

        <Box>
          {props.type === 'cx_score' && (
            <>
              {(currentSector === BusinessBanking2021Sector || currentSector === BusinessBanking2023Sector) && (
                <SegmentBadge
                  segment={SegmentBadgeBusinessAndCorporateSegment}
                  segmentName={'Business & Corporate weightings'}
                />
              )}
              {(currentSector === PersonalBanking2021Sector || currentSector === PersonalBanking2023Sector) && (
                <SegmentBadge segment={Personal2021Segment} segmentName={'Personal weightings'} />
              )}
              {(currentSector === LifeInsurance2021Sector || currentSector === LifeInsurance2023Sector) && (
                <SegmentBadge segment={LifeInsurance2021Segment} segmentName={'Life Insurance weightings'} />
              )}
            </>
          )}
        </Box>
      </Box>
    </Paper>
  )
}
