import React from 'react'
import { Typography, TypographyProps, withStyles, WithStyles } from '@material-ui/core'
import { createStyles, Theme } from '@material-ui/core/styles'

/**
 * Styles for the LimitedTypography component.
 *
 * The multilineEllipsis style limits the text to two lines using CSS properties.
 * - display: -webkit-box: Makes the element behave like a block container box.
 * - overflow: hidden: Hides any content that overflows the block container.
 * - textOverflow: ellipsis: Displays an ellipsis (...) to represent the clipped text.
 * - WebkitLineClamp: 2: Limits the text to two lines.
 * - WebkitBoxOrient: vertical: Sets the box orientation to vertical, necessary for the line clamp to work.
 */
const styles = (theme: Theme) =>
  createStyles({
    multilineEllipsis: {
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxHeight: '2.6em', // Adjust based on your line-height
      lineHeight: '1.3em', // Adjust as needed
      wordWrap: 'break-word',
      whiteSpace: 'normal',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px', // Adjust font size for smaller screens
        '-webkit-line-clamp': 2, // Ensure it stays at 2 lines on small screens
        maxHeight: '2.4em', // Adjust based on new line-height
        lineHeight: '1.2em', // Slightly reduced line height for smaller screens
      },
    },
  })

/**
 * Props for the LimitedTypography component.
 * Extends the TypographyProps interface from Material-UI to accept all props that Typography does.
 */
interface Props extends Omit<TypographyProps, 'classes'>, WithStyles<typeof styles> { }

/**
 * LimitedTypography Component
 *
 * This component displays a Typography element limited to two lines of text.
 * If the text exceeds two lines, it will be truncated with an ellipsis (...).
 *
 * @param {Props} props - The props for the component.
 * @returns {JSX.Element} The rendered LimitedTypography component.
 */
const LimitedTypography: React.FC<Props> = ({ classes, children, ...typographyProps }) => (
  <Typography className={classes.multilineEllipsis} {...typographyProps}>
    {children}
  </Typography>
)

/**
 * Export the LimitedTypography component with styles applied.
 *
 * withStyles is a higher-order component from Material-UI that injects styles into the component.
 */
export default withStyles(styles)(LimitedTypography)
