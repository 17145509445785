import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { Box, Button, Container, Grid, Paper, Typography } from '@material-ui/core'
import { Asset, Entry, TagLink } from 'contentful'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BlogImage } from '../../components/Blog/BlogImage'
import { TagIcon } from '../../components/Common/icons'
import { Page } from '../../components/Layout/Page'
import isVideo from '../../helpers/isVideo'
import { Blog, Fields, InlineGallery } from '../../support/contentful/content-types'
import getFormattedDate from '../../support/date/getFormattedDate'
import { useAssetStyles, useBlogStyles } from './Show.styled'
import { FetchProvidersResponse, fetchProviders } from '../../services/api/get-providers'
import { useApolloClient } from '@apollo/client'
import CountryFlag from '../../components/Common/CountryFlag'
import { getInsights } from '../../services/api/get-insights'
import { getTags, Tag } from '../../services/api/get-tags'

type RouterParams = {
  id: string
}

/**
 * Renders the Show component, which displays a single blog post.
 *
 * @return {JSX.Element} The rendered Show component.
 */
export const Show: FunctionComponent = () => {
  const apolloClient = useApolloClient()
  const classes = useBlogStyles()
  const { id } = useParams<RouterParams>()
  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(true)
  const [post, setPost] = useState<Blog>()
  const [tags, setTags] = useState<Tag[]>()
  const [providers, setProviders] = useState<FetchProvidersResponse>()

  useEffect(() => {
    const loadData = async () => {
      if (post) {
        const response = await fetchProviders(apolloClient, { providerIds: [Number(post.fields.organisationId)] })
        setProviders(response)
      }
    }
    loadData()
  }, [apolloClient, post])

  useEffect(() => {
    const loadData = async () => {
      setLoading(true)
      const [{ insights }, tags] = await Promise.all([
        getInsights(apolloClient, { limit: 1, path: id }),
        getTags(apolloClient)
      ])
      setPost(insights[0])
      setTags(tags)
      setLoading(false)
    }
    loadData()
  }, [apolloClient, id])

  if (loading) return null

  const options: Options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Typography variant={'body1'}>{text}</Typography>
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        if (node.data.target.sys.contentType.sys.id === 's3Asset') {
          return <SingleAsset entry={node.data.target} />
        }

        if (node.data.target.sys.contentType.sys.id === 'inlineGallery') {
          return <AssetGallery entry={node.data.target} />
        }

        return null
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <Box marginY={1}>
            <Typography classes={{ root: classes.paragraphs }} variant={'body1'}>
              {children}
            </Typography>
          </Box>
        )
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return (
          <Box marginY={4}>
            <Typography variant={'h2'}>{children}</Typography>
          </Box>
        )
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <Box marginY={4}>
            <Typography variant={'h3'}>{children}</Typography>
          </Box>
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        if (node.data.target.sys.contentType.sys.id === 's3Asset') {
          return <SingleAsset entry={node.data.target} />
        }

        return null
      },
      'embedded-entry-inline': (node, children) => {
        if (node.data.target.sys.contentType.sys.id === 'inlineGallery') {
          return <AssetGallery entry={node.data.target} />
        }

        return null
      }
    }
  }

  return (
    <Page>
      <Container>
        <Box marginTop={4} marginBottom={4} display={'flex'} justifyItems={'center'} alignItems={'center'}>
          <Grid container spacing={4}>
            <Grid item xs={10}>
              <Box display={'flex'} flexDirection={'column'}>
                <Box height={'100%'} display={'flex'} justifyItems={'center'} alignItems={'center'}>
                  <Button
                    variant="contained"
                    color="default"
                    style={{
                      color: '#647386',
                      backgroundColor: 'white',
                      fontSize: '14px',
                      padding: '8px 20px',
                      borderRadius: '20px'
                    }}
                    startIcon={
                      <svg
                        width="17px"
                        style={{ transform: 'rotate(90deg) translateX(1px)' }}
                        height="14px"
                        viewBox="0 0 17 14"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Blog" stroke="none" strokeWidth="1" fill="none" fillRule={'evenodd'}>
                          <g
                            id="Blog-Post"
                            transform="translate(-215.000000, -107.000000)"
                            fill="#8B96A4"
                            fillRule={'nonzero'}
                          >
                            <g id="header" transform="translate(200.000000, 94.000000)">
                              <g id="Icons/Arrows/Reload" transform="translate(15.058961, 13.541039)">
                                <path d="M8,14.4589615 C8.22333892,14.4589615 8.43774428,14.3606924 8.60748185,14.1820212 L14.1998883,8.58068118 C14.3785595,8.40201005 14.4589615,8.2054718 14.4589615,7.99106644 C14.4589615,7.53545505 14.1194863,7.1959799 13.6728085,7.1959799 C13.4494696,7.1959799 13.2350642,7.27638191 13.0921273,7.41931882 L11.1624791,9.32216639 L8.75041876,11.9843663 L8.81295366,10.1708543 L8.81295366,-0.728084869 C8.81295366,-1.20156337 8.4734785,-1.54103853 8,-1.54103853 C7.51758794,-1.54103853 7.18704634,-1.20156337 7.18704634,-0.728084869 L7.18704634,10.1708543 L7.24958124,11.9932998 L4.82858738,9.32216639 L2.9078727,7.41931882 C2.76493579,7.27638191 2.55053043,7.1959799 2.32719151,7.1959799 C1.88051368,7.1959799 1.54103853,7.53545505 1.54103853,7.99106644 C1.54103853,8.2054718 1.62144054,8.40201005 1.80011167,8.58068118 L7.39251815,14.1820212 C7.56225572,14.3606924 7.77666108,14.4589615 8,14.4589615 Z" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    }
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>
                </Box>

                {/*
                  Render post title if the original post date is before the REACT_APP_INSIGHTS_DATE
                  environment variable.
                */}
                {post?.fields.originalPostDate &&
                  new Date(post?.fields.originalPostDate) < new Date(import.meta.env.REACT_APP_INSIGHTS_DATE!) && (
                    <Box marginTop={6}>
                      <Typography
                        variant={'h1'}
                        style={{
                          fontSize: '48px'
                        }}
                      >
                        {post?.fields.title}
                      </Typography>
                    </Box>
                  )}

                <Box marginY={2}>
                  <Box
                    style={{
                      borderWidth: '1px',
                      borderColor: 'transparent',
                      borderRadius: '25px'
                    }}
                  ></Box>
                </Box>

                <Box marginTop={2}>
                  <Grid container>
                    <Grid item xs={2}>
                      <BlogImage
                        style={{ marginLeft: '0px', marginBottom: '10px', backgroundPosition: 'left' }}
                        height="40px"
                        width="140px"
                        asset={providers ? `${providers.getProviders[0]?.image}` : ''}
                      />
                      {post && (
                        <CountryFlag
                          style={{ justifyContent: 'left', padding: '0px', marginBottom: '10px' }}
                          countryCode={post.fields.organisationRegion}
                        />
                      )}
                      <Typography variant="h3">
                        <strong>{post?.fields.source}</strong>
                      </Typography>
                      <Typography variant="body1">
                        {post?.fields.originalPostDate && <>{getFormattedDate(post.fields.originalPostDate)}</>}
                      </Typography>
                    </Grid>

                    <Grid item xs={10}>
                      {post?.fields.content && <>{documentToReactComponents(post.fields.content, options)}</>}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box display={'flex'} flexDirection={'column'}>
                <Box>
                  <Box marginTop={12}>
                    <Typography variant={'h3'}>See more articles</Typography>
                    {post?.metadata.tags && tags && <TagList tags={post?.metadata.tags} allTags={tags} />}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

/**
 * Renders an asset gallery component based on the provided `entry` object.
 *
 * @param {Object} props - The component props.
 * @param {InlineGallery} props.entry - The entry object containing the assets to display.
 * @return {JSX.Element} The rendered asset gallery component.
 */
const AssetGallery = ({ entry }: { entry: InlineGallery }) => {
  const classes = useAssetStyles()
  const assetUrls = useMemo(() => {
    const s3AssetUrls = entry.fields.s3Assets?.map((asset) => asset.fields.s3Url) || []
    const fileAssetUrls = entry.fields.assets?.map((asset) => asset.fields.file.url) || []
    return [...s3AssetUrls, ...fileAssetUrls]
  }, [entry])

  return (
    <Paper className={classes.root} elevation={0}>
      <Box display="flex">
        {assetUrls.map((url, index) => (
          <Box key={index} marginX="auto" display="flex">
            <Box padding={4}>
              {isVideo(url) ? (
                <video src={url} controls height="auto" width="220px" />
              ) : (
                <img src={url} alt="" height="auto" width="220px" />
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Paper>
  )
}

const SingleAsset = (props: { entry: Entry<Fields.S3Asset> | Asset }) => {
  const classes = useAssetStyles()

  const url = useMemo(() => {
    if ((props.entry as Asset).fields.file !== undefined) {
      return (props.entry as Asset).fields.file.url
    }

    if ((props.entry as Entry<Fields.S3Asset>).fields.s3Url !== undefined) {
      return (props.entry as Entry<Fields.S3Asset>).fields.s3Url
    }

    return null
  }, [props.entry])

  if (!url) return null

  const assetTypeIsVideo = isVideo(url)

  return (
    <Paper className={classes.root} elevation={0}>
      <Box paddingY={4} margin={'auto'} maxWidth={'400px'}>
        {assetTypeIsVideo ? (
          <video
            controls
            src={url}
            style={{
              width: '100%',
              height: '100%'
            }}
          />
        ) : (
          <img src={url} height={'100%'} width={'100%'} alt={''} />
        )}
      </Box>
    </Paper>
  )
}

const TagList = (props: { tags?: TagLink[]; allTags: Tag[] }) => {
  return (
    <Box marginY={2}>
      {props.tags &&
        props.tags.map((tag, i) => {
          const tagIndex = props.allTags.findIndex((_tag) => {
            return tag.sys.id === _tag.id
          })

          if (tagIndex < 0) return null

          const name = props.allTags[tagIndex].name

          return <TagButton key={i} name={name} />
        })}

      {!props.tags && props.allTags.map((tag) => <TagButton key={`${tag.id + '_' + tag.name}`} name={tag.name} />)}
    </Box>
  )
}

/**
 * Renders a button component with a tag name and navigates to the search page with the tag name as a query parameter.
 *
 * @param {Object} props - The props object containing the tag name.
 * @param {string} props.name - The name of the tag.
 * @return {JSX.Element} The rendered button component.
 */
const TagButton = (props: { name: string }) => {
  const navigate = useNavigate()

  return (
    <Box marginY={1}>
      <Button
        variant="contained"
        color="default"
        style={{
          color: '#647386',
          backgroundColor: 'white',
          fontSize: '14px',
          padding: '8px 20px',
          borderRadius: '20px',
          whiteSpace: 'nowrap'
        }}
        startIcon={<TagIcon viewBox={'0,0,16,16'} style={{ width: '16px', height: '16px' }} />}
        onClick={() => navigate(`/insights/search?term=${props.name}`)}
      >
        {props.name}
      </Button>
    </Box>
  )
}
