import { Box, Button, Divider, Paper } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import colors from '../../support/theme/colors'

const ViewButton = styled(Button)`
  && {
    &.selected {
      background-color: ${colors.main} !important;
      color: white !important;
    }
    &:hover {
      background-color: white;
    }
    text-transform: capitalize;
    color: ${colors.main} !important;
    box-shadow: none !important;
    border-radius: 7px !important;
    background-color: white;
  }
`

const ButtonContainer = styled(Box)`
  && {
    background-color: white;
    justify-content: space-between;
    padding: 4px;
    border-radius: 7px;
    display: flex;
    &.dark {
      background-color: white;
    }
  }
`

export type ViewSwitchOption = {
  selected: boolean
  label: string
  onClick: Function
  key: string
}

export interface ViewSwitchProps {
  options: ViewSwitchOption[]
}

const ViewSwitch: React.FC<ViewSwitchProps> = ({ options }) => {
  return (
    <Box display="flex" justifyContent="center" padding={2}>
      <Paper>
        <ButtonContainer>
          {options.map(({ selected, label, onClick, key }) => (
            <Box key={key}>
              <ViewButton className={selected ? 'selected' : ''} onClick={() => onClick()} variant="contained">
                {label}
              </ViewButton>
              {options[options.length - 1].key !== key && (
                <Divider orientation="vertical" flexItem style={{ marginLeft: '4px', marginRight: '4px' }} />
              )}
            </Box>
          ))}
        </ButtonContainer>
      </Paper>
    </Box>
  )
}

export default ViewSwitch
