/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
import { Hub } from 'aws-amplify'
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { AmplifyUser, useAmplify } from '../hooks/amplify'

type IdentityContextType = {
  session: AmplifyUser | null
  token: string | null
  group: string | null
  brand: string | null
  revalidateEmail: string | null
  approvalStatus: string | undefined
  isLoggedIn: boolean
  loading: boolean
  setIsLoggedIn: Function
  setUser: (user: AmplifyUser) => void
}

const IdentityContext = createContext<IdentityContextType>({
  session: null,
  token: null,
  group: null,
  brand: null,
  revalidateEmail: null,
  approvalStatus: undefined,
  isLoggedIn: false,
  loading: true,
  setIsLoggedIn: () => {},
  setUser: (user: AmplifyUser) => {}
})

type IdentityProviderProps = {}

/**
 * Identity Provider for storing authenticated session data.
 * handles the managing of the user group, token, session, approval status.
 */
export const IdentityProvider = ({ children }: PropsWithChildren<IdentityProviderProps>) => {
  const { currentUser, jwtToken, userGroup, userBrand, revalidate_email } = useAmplify()

  const [loading, setLoading] = useState<boolean>(true)
  const [session, setSession] = useState<AmplifyUser | null>(null)
  const [token, setToken] = useState<string | null>(null)
  const [group, setGroup] = useState<string | null>(null)
  const [brand, setBrand] = useState<string | null>(null)
  const [revalidateEmail, setRevalidateEmail] = useState<string | null>(null)
  const [approvalStatus, setApprovalStatus] = useState<'pending' | 'approved' | undefined>(undefined)
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)

  const loadUserSession = useCallback(async () => {
    const user = await currentUser()
    const jwt = await jwtToken()
    const tokenGroup = await userGroup()
    const tokenBrand = await userBrand()
    const tokenRevalidateEmail = await revalidate_email()

    setSession(user)
    setToken(jwt)
    setGroup(tokenGroup)
    setBrand(tokenBrand)
    setRevalidateEmail(tokenRevalidateEmail)
    setApprovalStatus(user?.attributes?.['custom:ApprovalStatus'])
    setIsLoggedIn(user !== null)
    setLoading(false)
  }, [currentUser, jwtToken, revalidate_email, userBrand, userGroup])

  const setUser = async (user: AmplifyUser) => {
    const jwt = await jwtToken()
    const tokenGroup = await userGroup()
    const tokenBrand = await userBrand()
    const tokenRevalidateEmail = await revalidate_email()

    setToken(jwt)
    setGroup(tokenGroup)
    setBrand(tokenBrand)
    setRevalidateEmail(tokenRevalidateEmail)
    setApprovalStatus(user?.attributes?.['custom:ApprovalStatus'])
    setSession(user)
  }

  useEffect(() => {
    loadUserSession()

    /**
     * This is fired from Amplify, we listen for this so that we can update the group for the user,
     * allows for locking a user out quickly if needed too, or enabling a user after approval.
     */
    Hub.listen('auth', async (data) => {
      switch (data.payload.event) {
        case 'tokenRefresh':
          const tokenGroup = await userGroup()
          setGroup(tokenGroup)
          break
      }
    })
  }, [loadUserSession, userGroup])

  return (
    <IdentityContext.Provider
      value={{
        session,
        token,
        group,
        brand,
        revalidateEmail,
        isLoggedIn,
        loading,
        approvalStatus,
        setIsLoggedIn,
        setUser
      }}
    >
      {children}
    </IdentityContext.Provider>
  )
}

/**
 * A hook for using the identity provider inside components.
 */
export const useIdentityContext = () => {
  return useContext(IdentityContext)
}
