import React, { useState, useEffect } from 'react'
import { Box } from '@material-ui/core'

/**
 * The props for the CountryFlag component.
 *
 * @interface CountryFlagProps
 * @property {string} countryCode - The country code for which to fetch the flag image.
 * @property {React.CSSProperties} [style] - Optional style object to apply to the flag container.
 */
interface CountryFlagProps {
  countryCode: string
  style?: React.CSSProperties // Add this line to include style props
}

/**
 * Common mistakes to ISO country codes mapping.
 */
const COMMON_MISTAKES: Record<string, string> = {
  usa: 'US',
  'u.k': 'GB',
  'u s': 'US',
  'u k': 'GB',
  uk: 'GB'
}

/**
 * Function to correct common mistakes in two-letter country codes and output in lowercase.
 */
function correctCountryCode(input: string): string {
  const cleanedInput = input.trim().toLowerCase()

  // Check if the cleaned input is a known common mistake
  if (COMMON_MISTAKES[cleanedInput]) {
    return COMMON_MISTAKES[cleanedInput].toLowerCase()
  }

  // Return the input as-is if no corrections are found
  return input.toLowerCase()
}

/**
 * Renders a flag image for a given country code.
 *
 * @param {CountryFlagProps} props - The props object containing the country code and optional style.
 * @param {string} props.countryCode - The country code for which to fetch the flag image.
 * @param {React.CSSProperties} [props.style] - Optional style object to apply to the flag container.
 * @return {React.ReactElement} The flag image component.
 */
const CountryFlag: React.FC<CountryFlagProps> = ({ countryCode, style }) => {
  const [flagUrl, setFlagUrl] = useState<string | null>(null)

  useEffect(() => {
    if (!countryCode) {
      return
    }
    /**
     * Fetches the URL for the flag image corresponding to the given country code.
     *
     * @return {Promise<void>} A promise that resolves when the flag URL is fetched and the state is updated.
     */
    const fetchFlagUrl = async () => {
      const url = `https://flagcdn.com/h20/${correctCountryCode(countryCode)}.webp`
      setFlagUrl(url)
    }

    fetchFlagUrl()
  }, [countryCode])

  return (
    <Box display="flex" alignItems="center" justifyContent="center" color="black" p={2} borderRadius={4} style={style}>
      {countryCode ? <img src={flagUrl ?? ''} alt={`${countryCode} flag`} /> : <></>}
    </Box>
  )
}

export default CountryFlag
