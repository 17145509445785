import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, Observable, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { jwtToken, userBrand, userGroup } from '../../hooks/amplify'

/**
 * Create a backend http link, used for injecting middleware into the request.
 *
 * @param {Record<string, string>} headers
 *
 * @returns {HttpLink}
 */
const createBackendLink = (headers: Record<string, string>) => {
  return new HttpLink({
    uri: import.meta.env.REACT_APP_GRAPHQL_URI,
    useGETForQueries: import.meta.env.NODE_ENV !== 'development',
    headers
  })
}

/**
 * Creates a middleware to inject the JWT into the request and user group.
 */
const createAuthLink = setContext(async (_, { headers }) => {
  const token = await jwtToken()
  const group = await userGroup()
  const brand = await userBrand()

  return {
    group,
    brand,
    headers: {
      ...headers,
      Authorization: token ? token : ''
    }
  }
})

/**
 * Creates a middleware to check if the user has a group,
 * throws and error if not or forwards the request to the next middleware if passes.
 *
 * @returns {Observable}
 */
const createGroupCheckLink = () => {
  const thisBrand = import.meta.env.REACT_APP_BRANDING
  return new ApolloLink((operation, forward) => {
    if (operation.getContext().group !== null && operation.getContext().brand !== null) {
      if (operation.getContext().brand !== thisBrand) {
        return new Observable((subscriber) => {
          subscriber.error({
            name: 'UnauthorisedBrandMismatch',
            message: 'Wrong Brand Was Passed In Context'
          })
        })
      }

      return forward(operation)
    }

    if (operation.getContext().group === null && operation.getContext().brand !== null) {
      return new Observable((subscriber) => {
        subscriber.error({
          name: 'UnauthorisedNoGroup',
          message: 'No Group Was Passed In Context'
        })
      })
    } else if (operation.getContext().group !== null && operation.getContext().brand === null) {
      return new Observable((subscriber) => {
        subscriber.error({
          name: 'UnauthorisedNoBrand',
          message: 'No Brand Was Passed In Context'
        })
      })
    } else {
      return new Observable((subscriber) => {
        subscriber.error({
          name: 'UnauthorisedNoGroupAndBrand',
          message: 'No Group and Brand Were Passed In Context'
        })
      })
    }
  })
}

/**
 * Handles the errors of requests in a specific environment.
 */
const errorLink = onError(({ response, graphQLErrors, networkError }) => {
  if (import.meta.env.NODE_ENV === 'development') {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (message.startsWith('Domain') && message.endsWith('does not correspond to a customer')) {
          // window.location.href = '/denied'
        }
      })
    }

    if (networkError) {
    }
  }

  return
})

/**
 * Creates a apollo client and joins in the required middleware were required.
 *
 * @param {Record<string, string>} headers
 *
 * @returns {ApolloClient<object>}
 */
export const createApolloClient = (headers: Record<string, string> = {}) => {
  const groupLink = createAuthLink.concat(createGroupCheckLink())
  const httpLink = createBackendLink(headers)
  const cache = new InMemoryCache()

  return new ApolloClient({
    cache,
    link: from([errorLink, groupLink, httpLink]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'none'
      }
    }
  })
}
