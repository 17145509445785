import { Box, Button, Container, Paper, TextField, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { ChangeEvent, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ErrorBox } from '../../components/Auth/ErrorBox'
import { Page } from '../../components/Layout/Page'
import { forgotPasswordConfirm } from '../../support/amplify/forgot-password-confirm'
import { PASSWORD_POLICY, fn as passwordValidator } from '../../support/validation/password'
import { UNEXPECTED_ERROR_MESSAGE } from '../../helpers/messages'

export const ChangePassword = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [error, setError] = useState<string | undefined>(undefined)
  const [input, setInput] = useState({
    username: location.state.username || '',
    code: '',
    newPassword: '',
    confirmNewPassword: ''
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const form = {
      ...input,
      [event.target.name]: event.target.value
    }

    setInput(form)
  }

  const validatePassword = async (password: string, confirmed: string) => {
    if (password !== confirmed) {
      setError(`Passwords do not match!`)

      return false
    }

    if (!passwordValidator(password) || !passwordValidator(confirmed)) {
      setError(`${PASSWORD_POLICY}`)

      return false
    }

    return true
  }

  const handleSubmit = async () => {
    const isPasswordValid = await validatePassword(input.newPassword, input.confirmNewPassword)

    if (!isPasswordValid) return

    const forgotPasswordConfirmResult = await forgotPasswordConfirm(input.username, input.code, input.newPassword)

    switch (forgotPasswordConfirmResult.type) {
      case 'success':
        navigate('/login', { state: { wasPasswordRecentlyChanged: true } })
        break
      case 'limit-exceeded':
        setError(forgotPasswordConfirmResult.error)
        break
      case 'network-error':
        setError(forgotPasswordConfirmResult.error)
        break
      case 'unknown-error':
        setError(UNEXPECTED_ERROR_MESSAGE)
        break
      default:
        setError(UNEXPECTED_ERROR_MESSAGE)
    }
  }

  return (
    <Page>
      <Container>
        <Box marginTop={4}>
          <Button
            variant="contained"
            color="default"
            style={{ backgroundColor: 'white', fontSize: '14px', borderRadius: '20px', color: '#647386' }}
            startIcon={<ArrowBack />}
            onClick={() => navigate('/login')}
          >
            Back
          </Button>
        </Box>
        <Container maxWidth={'sm'}>
          <Box display={'flex'} flexDirection={'column'} maxWidth={'md'}>
            <Box marginBottom={4}>
              <Paper>
                <Box padding={4}>
                  <Typography variant={'h1'}>Change Password</Typography>
                  {error && <ErrorBox message={error} />}

                  <Box marginY={2}>
                    <Typography variant={'body1'}>
                      You should have received a new confirmation code in your email, if you did not receive please{' '}
                      <a href={'https://iskyresearch.com/#contactArea'} style={{ color: '#001433' }}>
                        contact us
                      </a>
                    </Typography>
                  </Box>

                  <Box marginY={2}>
                    <Typography
                      style={{
                        fontSize: '14px',
                        color: '#647386',
                        fontWeight: 600
                      }}
                      variant={'subtitle2'}
                    >
                      Email Address
                    </Typography>
                    <TextField
                      style={{
                        marginTop: '4px'
                      }}
                      onChange={handleChange}
                      fullWidth={true}
                      size={'small'}
                      variant={'outlined'}
                      required={true}
                      type={'email'}
                      autoComplete={'email'}
                      name={'username'}
                      id="username"
                      aria-describedby="my-helper-text"
                      value={input.username}
                    />
                  </Box>

                  <Box marginY={2}>
                    <Typography
                      style={{
                        fontSize: '14px',
                        color: '#647386',
                        fontWeight: 600
                      }}
                      variant={'subtitle2'}
                    >
                      Confirmation Code
                    </Typography>
                    <TextField
                      style={{
                        marginTop: '4px'
                      }}
                      onChange={handleChange}
                      fullWidth={true}
                      size={'small'}
                      variant={'outlined'}
                      required={true}
                      type={'text'}
                      name={'code'}
                      id="confirmation-code"
                      autoComplete={'off'}
                      aria-describedby="confirmation code"
                    />
                  </Box>
                  <Box marginY={2}>
                    <Typography
                      style={{
                        fontSize: '14px',
                        color: '#647386',
                        fontWeight: 600
                      }}
                      variant={'subtitle2'}
                    >
                      New Password
                    </Typography>
                    <TextField
                      style={{
                        marginTop: '4px'
                      }}
                      onChange={handleChange}
                      fullWidth={true}
                      size={'small'}
                      variant={'outlined'}
                      required={true}
                      type={'password'}
                      name={'newPassword'}
                      id="new-password"
                      aria-describedby="your password"
                    />
                  </Box>
                  <Box marginY={2}>
                    <Typography
                      style={{
                        fontSize: '14px',
                        color: '#647386',
                        fontWeight: 600
                      }}
                      variant={'subtitle2'}
                    >
                      Confirm New Password
                    </Typography>
                    <TextField
                      style={{
                        marginTop: '4px'
                      }}
                      onChange={handleChange}
                      fullWidth={true}
                      size={'small'}
                      variant={'outlined'}
                      required={true}
                      type={'password'}
                      name={'confirmNewPassword'}
                      id="confirm-password"
                      aria-describedby="your password"
                    />
                  </Box>
                  <Box marginY={2}>
                    <Typography
                      style={{
                        fontSize: '14px',
                        color: '#647386'
                      }}
                      variant={'subtitle2'}
                    >
                      {PASSWORD_POLICY}
                    </Typography>
                  </Box>
                  <Box marginY={2}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                      <Button color={'secondary'} variant={'contained'} onClick={handleSubmit}>
                        Change Password
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Container>
      </Container>
    </Page>
  )
}
export default ChangePassword
