import { EnvironmentConfiguration } from '../types/Environment'

/**
 * Handling caching of the ENV vars for ease of access.
 */
export const environment: EnvironmentConfiguration = {
  cognitoRegion: import.meta.env.REACT_APP_COGNITO_REGION as string,
  cognitoUserPoolId: import.meta.env.REACT_APP_COGNITO_USER_POOL_ID as string,
  cognitoUserPoolWebClientId: import.meta.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID as string,
  services: {
    auth: import.meta.env.REACT_APP_AUTH_SERVICE_URL as string
  },
  branding: {
    name: import.meta.env.REACT_APP_BRANDING as string
  }
}
