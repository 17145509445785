import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  withStyles
} from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useCompareChannel } from '../../contexts/CompareChannelContext'
import { ExpandableProvider } from '../../contexts/ExpandableContext'
import { useJourneyView } from '../../hooks/journeys'
import { AssetJourney } from '../../types/Journeys'
import { ChannelBadge } from '../Common/ChannelBadge'
import { SegmentBadge } from '../Common/SegmentBadge'
import { ChevronDown } from '../Common/icons'
import { Expandable } from '../Expandable/Expandable'
import Score from '../Score'
import { Assets } from './Assets'
import { Functions } from './Modal/Functions'
import { Notes } from './Modal/Notes'
import { ChannelScore } from '../../declarations/ChannelScore'
import { Asset } from '../../declarations/Asset'

type DropdownOption = AssetJourney & { label: string }

type JourneyCompareProps = {
  channelScores: ChannelScore[]
  usesVoc: boolean
}

export const JourneyCompare = (props: JourneyCompareProps) => {
  const { journeys } = useJourneyView()
  const compareChannel = useCompareChannel()

  const journeyOptions = useMemo(
    () =>
      journeys.map((j) => {
        return { ...j, label: j.name }
      }),
    [journeys]
  )

  const expanded = useMemo(() => {
    if (compareChannel.state.default.expanded === undefined) {
      return []
    }

    return compareChannel.state.default.expanded
  }, [compareChannel.state.default.expanded])

  return (
    <ExpandableProvider
      state={{
        expanded: expanded,
        checked: []
      }}
      names={[]}
      checkable={[]}
    >
      <Box paddingY={1} paddingX={2}>
        <Box display={'flex'} justifyContent={'flex-end'} marginTop={'10px'}>
          <Box display={'flex'} alignItems={'center'}>
            <Expandable.GroupTrigger>
              <Typography style={{ marginLeft: '6px' }} variant={'h4'}>
                Expand all
              </Typography>
            </Expandable.GroupTrigger>
          </Box>
          <Box display={'flex'} alignItems={'center'} marginLeft={'15px'}>
            <Expandable.GroupTrigger collapse>
              <Typography style={{ marginLeft: '6px' }} variant={'h4'}>
                Collapse all
              </Typography>
            </Expandable.GroupTrigger>
          </Box>
        </Box>
        <Box bgcolor={'transparent'} borderRadius={'9px'} marginTop={'20px'}>
          {props.channelScores.map((p, pIdx) => (
            <Box key={pIdx} marginY={'20px'}>
              <Paper style={{ borderRadius: '9px' }}>
                <Box
                  display={'flex'}
                  bgcolor={'#fff'}
                  borderRadius={'9px'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  paddingX={2}
                  paddingY={1}
                >
                  <Box display={'flex'} alignItems={'center'}>
                    <Expandable.Trigger name={p.key}>
                      <Typography
                        variant={'h3'}
                        style={{
                          marginLeft: '10px',
                          marginRight: '10px'
                        }}
                      >
                        {p.providerName}
                      </Typography>
                    </Expandable.Trigger>
                  </Box>
                  <Box display={'flex'} alignItems={'center'}>
                    <ChannelBadge
                      channel={p.channel}
                      channelName={p.channelName}
                      providerName={p.providerName}
                      auditDate={p.audit_date}
                    />
                    <SegmentBadge segment={p.segment} segmentName={p.segmentName} />
                    <Divider orientation="vertical" flexItem />
                    <Box marginX={2} display="flex" alignItems="center">
                      <Box marginRight={2}>
                        <ScoreLabel>Utility Score</ScoreLabel>
                      </Box>
                      <Score score={p.score} />
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    {props.usesVoc && (
                      <Box marginX={2} display="flex" alignItems="center">
                        <Box marginRight={2}>
                          <ScoreLabel>VoC Score</ScoreLabel>
                        </Box>
                        <Score score={p.cx_score} />
                      </Box>
                    )}
                    <Box marginLeft={'10px'}>
                      {p.providerLogo && <img width={'100px'} src={p.providerLogo} alt={p.providerName} />}
                    </Box>
                  </Box>
                </Box>
              </Paper>

              <Expandable.Group name={p.key}>
                <ChannelJourneys
                  channelScore={p}
                  selectedJourney={
                    compareChannel.state.default?.selected &&
                    (compareChannel.state.default?.selected[p.key] ?? undefined)
                  }
                  options={journeyOptions}
                />
              </Expandable.Group>
            </Box>
          ))}
        </Box>
      </Box>
    </ExpandableProvider>
  )
}

type ChannelJourneysProps = {
  channelScore: ChannelScore
  selectedJourney?: string
  options: DropdownOption[]
}

const ChannelJourneys = (props: ChannelJourneysProps) => {
  const { getJourneyAssets } = useJourneyView()
  const [selected, setSelected] = useState<DropdownOption | undefined>(undefined)
  const [selectedAsset, setSelectedAsset] = useState<{ asset: Asset; view: 'notes' | 'functions' } | undefined>(
    undefined
  )

  const [assets, setAssets] = useState<Record<string, Asset[]>>({})
  const [ready, setReady] = useState<boolean>(false)

  useEffect(() => {
    const loadJourneyAssets = async () => {
      const loadedAssets: Record<string, Asset[]> = {}
      for (const j of props.options) {
        loadedAssets[j.key] = await getJourneyAssets(props.channelScore.auditId, j.id)
      }

      setAssets(loadedAssets)
      setReady(true)
    }

    loadJourneyAssets()
  }, [props.options, props.channelScore, getJourneyAssets])

  useEffect(() => {
    if (props.options.length > 0) {
      if (props.selectedJourney !== undefined) {
        const option = props.options.find((o) => o.key === props.selectedJourney)

        if (option) {
          setSelected(option)
        } else {
          setSelected(props.options[0])
        }
      } else {
        setSelected(props.options[0])
      }
    }
  }, [props.options, props.selectedJourney])

  const handleDropdownOptionSelected = (option: DropdownOption) => {
    setSelected(option)
  }

  const handleAssetSelected = (asset: Asset, view: 'notes' | 'functions') => {
    setSelectedAsset({
      asset,
      view
    })
  }

  if (!ready) {
    return <>Loading ...</>
  }

  return (
    <>
      <Box marginY={4} paddingX={2}>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <JourneyDropdown
            onSelected={handleDropdownOptionSelected}
            options={props.options}
            assets={assets}
            selected={selected}
          />
        </Box>
        <Box>
          {selected && (
            <JourneyAssets onAssetSelected={handleAssetSelected} assets={assets[selected.key]} selected={selected} />
          )}
        </Box>
      </Box>

      {selectedAsset && selectedAsset.view === 'functions' && (
        <Functions
          channelScore={props.channelScore}
          asset={selectedAsset.asset}
          onClose={() => setSelectedAsset(undefined)}
        />
      )}

      {selectedAsset && selectedAsset.view === 'notes' && (
        <Notes
          channelScore={props.channelScore}
          asset={selectedAsset.asset}
          onClose={() => setSelectedAsset(undefined)}
        />
      )}
    </>
  )
}

type JourneyAssetsProps = {
  selected: DropdownOption
  assets: Asset[]
  onAssetSelected: (asset: Asset, view: 'notes' | 'functions') => void
}

const JourneyAssets = (props: JourneyAssetsProps) => {
  return (
    <>
      <Box display={'flex'} paddingY={1}>
        <Typography
          variant={'h2'}
          style={{
            marginLeft: '10px',
            marginRight: '10px'
          }}
        >
          {props.selected.name} ({props.assets.length})
        </Typography>
      </Box>

      <Assets onSelected={props.onAssetSelected} assets={props.assets} />
    </>
  )
}

type JourneyDropdownProps = {
  selected?: DropdownOption
  assets: Record<string, Asset[]>
  options: DropdownOption[]
  onSelected: (option: DropdownOption) => void
}

export const JourneyDropdown = (props: JourneyDropdownProps) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const handleMenuItemClick = async (event: React.MouseEvent<HTMLLIElement, MouseEvent>, option: DropdownOption) => {
    props.onSelected(option)

    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <ButtonGroup
        size={'small'}
        style={{
          borderRadius: '20px'
        }}
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          size={'small'}
          variant={'contained'}
          style={{
            backgroundColor: 'white',
            fontSize: '14px',
            padding: '10px 20px',
            borderRadius: '20px',
            color: '#647386',
            textTransform: 'none'
          }}
          onClick={handleToggle}
        >
          <Box display={'flex'} justifyItems={'center'} alignItems={'center'} whiteSpace={'nowrap'}>
            {props.selected && (
              <>
                <span>
                  {props.selected.label} ({props.assets[props.selected.key].length})
                </span>
                <ChevronDown viewBox={'0 0 8 8'} style={{ width: '8px', height: '8px', marginLeft: '6px' }} />
              </>
            )}
          </Box>
        </Button>
      </ButtonGroup>
      <PopperStyles
        style={{ zIndex: 10, marginTop: '10px' }}
        open={open}
        anchorEl={anchorRef.current}
        popperOptions={{ positionFixed: true }}
        role={undefined}
        disablePortal={true}
        transition
        placement={'bottom-end'}
        modifiers={{
          flip: {
            enabled: false
          },
          preventOverflow: {
            enabled: false,
            boundariesElement: 'scrollParent'
          }
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Box>
                  <MenuListStyles>
                    <Box
                      display={'flex'}
                      justifyItems={'center'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      paddingX={2}
                      paddingY={1}
                    ></Box>
                    {props.options.map((option, optionIndex) => {
                      return (
                        <SortableItem key={optionIndex} onClick={(event) => handleMenuItemClick(event, option)}>
                          <Box display={'flex'} justifyItems={'space-between'} alignItems={'center'}>
                            <SortableItemTypography variant={'body1'}>
                              {option.label} ({props.assets[option.key].length})
                            </SortableItemTypography>
                          </Box>
                        </SortableItem>
                      )
                    })}
                  </MenuListStyles>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </PopperStyles>
    </>
  )
}

const ScoreLabel = styled(Box)`
  && {
    color: #647386;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.2px;
    text-align: right;
    font-weight: 600;
  }
`

const PopperStyles = styled(Popper)`
  && {
    filter: drop-shadow(0px 3px 9px rgba(65, 69, 88, 0.25));
    &:before {
      content: '';
      position: absolute;
      display: block;
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      top: -10px;
      left: 12px;
      width: 22px;
      height: 10px;
      clip-path: polygon(50% 0, 0% 100%, 100% 100%);
      background-color: white;
      z-index: 9999;
    }
  }
`

const MenuListStyles = styled(MenuList)`
  && {
    .MuiListItem-button:hover {
      p.MuiTypography-body1 {
        color: white !important;
      }
    }
  }
`

const SortableItemTypography = styled(Typography)`
  && {
    font-size: 14px !important;
    // color: #647386;
    &:hover {
      color: white !important;
    }
  }
`

const SortableItem = withStyles({
  root: {
    '&:hover': {
      backgroundColor: '#E5126E !important',
      color: 'white !important'
    },
    paddingTop: '2px',
    paddingBottom: '2px'
  }
})(MenuItem)
