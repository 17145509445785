import { FunctionComponent } from 'react'
import { Route, Routes } from 'react-router-dom'
import { ChangePassword, Confirm, ForgotPassword, Login, Register } from '../pages/Auth'

export const AuthContainer: FunctionComponent = () => {
  return (
    <Routes>
      <Route path={'/login'} element={<Login />} />
      <Route path={'/register'} element={<Register />} />
      <Route path={'/confirm'} element={<Confirm />} />
      <Route path={'/forgot-password'} element={<ForgotPassword />} />
      <Route path={'/forgot-password/confirm'} element={<ChangePassword />} />
      <Route path="*" element={<></>} />
    </Routes>
  )
}
