import React, { useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Box, FormControlLabel, Checkbox } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning' // Using a MUI icon for the example

interface Resource {
    content_type: string
    create_date: string | number
    description?: string
    expiry_date: string
    file_size: number
    id: number
    name: string
}

interface LargeDownloadModalProps {
    resource: Resource
    open: boolean
    onClose: () => void
    onDownload: () => void
}

/**
 * Renders a modal dialog for warning the user about a large file download.
 *
 * @param {LargeDownloadModalProps} props - The props object containing the following properties:
 *   - resource: The resource object containing the name and file size of the file to be downloaded.
 *   - open: A boolean indicating whether the modal is open or not.
 *   - onClose: A function to be called when the modal is closed.
 *   - onDownload: A function to be called when the download button is clicked.
 * @return {ReactElement} The rendered modal dialog component.
 */
const LargeDownloadModal: React.FC<LargeDownloadModalProps> = ({ resource, open, onClose, onDownload }) => {
    const [alwaysDownload, setAlwaysDownload] = useState<boolean>(false)

    useEffect(() => {
        const savedPreference = localStorage.getItem('alwaysDownloadLargeFiles')
        if (savedPreference) {
            setAlwaysDownload(JSON.parse(savedPreference))
        }
    }, [])

    /**
     * Updates the state of alwaysDownload and saves the preference in localStorage when the checkbox is changed.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - The event triggered by the checkbox change.
     * @return {void} This function does not return anything.
     */
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target
        setAlwaysDownload(checked)
        localStorage.setItem('alwaysDownloadLargeFiles', JSON.stringify(checked))
    }

    /**
     * Formats a file size in bytes into a human-readable format with units (B, kB, MB, GB, TB).
     *
     * @param {number} size - The file size in bytes.
     * @return {string} The formatted file size with units.
     */
    const formatFileSize = (size: number) => {
        const i = Math.floor(Math.log(size) / Math.log(1024))
        return (size / Math.pow(1024, i)).toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
    }

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="large-download-dialog-title">
            <DialogTitle id="large-download-dialog-title">Large Download Warning</DialogTitle>
            <DialogContent>
                <Box display="flex" alignItems="center">
                    <Box mr={2}>
                        <WarningIcon style={{ fontSize: 60, color: '#f44336' }} />
                        {/* Replace WarningIcon with an actual image if you have one */}
                    </Box>
                    <Box>
                        <DialogContentText>
                            The file you are about to download is quite large:
                        </DialogContentText>
                        <Typography variant="body1" component="p">
                            <strong>{resource.name}</strong>
                        </Typography>
                        <Typography variant="body2" component="p">
                            Size: {formatFileSize(resource.file_size)}
                        </Typography>
                        <DialogContentText>
                            Do you want to proceed with the download?
                        </DialogContentText>
                        <FormControlLabel
                            control={<Checkbox checked={alwaysDownload} onChange={handleCheckboxChange} color="primary" />}
                            label="Always download large files"
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onDownload} color="secondary" variant="contained">
                    Download
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default LargeDownloadModal