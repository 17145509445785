import { Box, Button } from '@material-ui/core'
import styled from 'styled-components'
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded'

const ButtonStyles = styled(Button)`
  && {
    background-color: white !important;
    border-radius: 20px;
    min-width: 0px;
    padding: 6px;
    color: #8b96a4;
    box-shadow: 0 1px 3px 0 rgba(65, 69, 88, 0.2);
  }
`

type PageNavigatorProps = {
  direction: 'left' | 'right'
  onPageNavigate: () => void
}

export const PageNavigator = (props: PageNavigatorProps) => {
  // Page Navigate and Scroll to the top of the page when navigation change is fired
  const handleClick = () => {
    props.onPageNavigate()
    document.getElementById('radartop')?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <Box marginX={2}>
      <ButtonStyles
        onClick={handleClick}
        variant="contained"
        aria-label={props.direction === 'left' ? 'Previous page' : 'Next Page'}
      >
        {props.direction === 'left' ? <ChevronLeftRoundedIcon /> : <ChevronRightRoundedIcon />}
      </ButtonStyles>
    </Box>
  )
}
