import HighChartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts/highstock'
import { useQuestions } from '../../hooks/questions'
import colors from '../../support/theme/colors'
import HighchartsReact from 'highcharts-react-official'
HighChartsMore(Highcharts)

type RadarChartProps = {
  chartData?: any
  hideCategories?: boolean
  pan?: string
  height?: number
  dotsOn?: boolean
  advanced?: boolean
  showPercentages: boolean
}

const RadarChart = ({
  chartData,
  hideCategories,
  pan,
  height = 300,
  dotsOn = true,
  advanced = false,
  showPercentages = false
}: RadarChartProps) => {
  const { getJourneyWeight } = useQuestions()

  const options = {
    chart: {
      height: height,
      // styledMode: false,
      polar: true,
      type: 'line',
      overflow: 'allow',
      renderTo: 'chart',
      style: {
        fontFamily: 'Inter, sans-serif',
        textAlign: 'center',
        margin: 'auto',
        overflow: 'visible'
      },
      events: {
        load: function () {
          // @ts-ignore
          Highcharts.addEvent(this.tooltip, 'headerFormatter', function (e) {
            // @ts-ignore
            if (!e.isFooter) {
              // @ts-ignore
              const config = e.labelConfig

              const name = config.key
              const parts = name.split('#')

              const html = `<span style='display:block; min-width: 214px;font-size: 15px; font-weight: 600; line-height: 1.5;color: #001433;letter-spacing: -0.19px;'>
                                ${parts[1]}
                                </span>`
              // @ts-ignore
              e.text = html
              return false // prevent default
            }
            return true // run default
          })
        }
      }
    },
    accessibility: {
      enabled: false,
      description: 'iSky'
    },
    title: 'none',
    pane: {
      size: pan || '100%'
    },
    xAxis: {
      allowOverlap: true,
      lineWidth: 0,
      // type: "category",
      tickmarkPlacement: 'on',
      categories: chartData.categories,
      labels: {
        formatter: function (): string {
          // @ts-ignore
          let value = this.value

          if (typeof value === 'string') {
            // @ts-ignore
            let [id, name] = value.split('#')

            if (advanced) {
              const weight = getJourneyWeight(parseInt(id))
              const value =
                weight && showPercentages
                  ? `<span class="weight segment-${weight.segment}">${weight.weight.toFixed(2)}&nbsp;%</span>`
                  : ''

              return `<span class="cat-space">
                        <span class="cat-name">${name}</span>
                        ${value}
                    </span>`
            } else {
              return `<span class="cat-space"><span class="cat-name"> ${name}</span>`
            }
          }

          return value
        },
        useHTML: true,
        // align: "center",
        offset: 30,
        staggerLines: 2,
        title: {
          text: null
        },
        enabled: !hideCategories,
        style: {
          color: colors.main,
          fontSize: '12px',
          visibility: !hideCategories ? 'visible' : 'hidden',
          fontWeight: 'bold'
        }
      }
    },

    yAxis: {
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      min: 0,
      max: 100,
      tickInterval: 20,
      labels: {
        enabled: false
      }
    },
    tooltip: {
      style: {
        zIndex: 2000
      },
      outside: true,
      shared: true,
      borderRadius: 6,
      borderWidth: 0,
      useHTML: true,
      backgroundColor: '#ffffff',
      headerFormat:
        "<span style='display:block; min-width: 214px;font-size: 15px; font-weight: 600; line-height: 1.5;color: #001433;letter-spacing: -0.19px;'>{point.key.split('#').pop()}</span>",
      pointFormatter: function (): string {
        // @ts-ignore
        let pointY = this.y
        // @ts-ignore
        let name = this.series.name.split('#').pop()
        // @ts-ignore
        let start = this.y
        // @ts-ignore
        let end = 100 - this.y

        return `
                    <span style="display: inline-block;min-width: 170px;">${name}</span>
                    <span class="tooltip-score">
                      <span class="tooltip-score_number">${pointY}</span>
                      <svg ViewBox="0 0 42 42" width="42" height="42">
                        <circle cx="21" cy="21" r="15.91549430918954" fill="#fff"/>
                        <circle cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#ECEDEF" stroke-width="3"/>
                        <circle cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#E5126E" stroke-width="3" style="stroke-dasharray: ${start} ${end};
                    stroke-dashoffset: 25;"/>
                      </svg>
                  </span>
                <br>
                `
      }
    },
    plotOptions: {
      series: {
        lineWidth: 2,
        marker: {
          enabled: dotsOn
        }
      }
    },
    legend: 'none',
    series: chartData.series,
    credits: {
      enabled: false
    }
  }
  return (
    <HighchartsReact
      constainerProps={{ width: '100%', overflow: 'visible' }}
      highcharts={Highcharts}
      options={options}
    />
  )
}

export default RadarChart
