import { uniqBy } from 'lodash'
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes'
import secDomainToString from '../../helpers/secDomainToString'
import { FeatureJourney } from '../../types/Functions'
import getFormattedDate from '../date/getFormattedDate'
import { getSurveySegmentFromSegment } from '../../types/Segments'
import { Answer } from '../../declarations/ChannelScore'
import { FrictionData } from '../../declarations/FrictionScore'
import { TransformedChannel } from '../../hooks/functions'

export const exportFunctions = async (
  filename: string,
  transformedChannels: TransformedChannel[],
  journeys: FeatureJourney[],
  frictionScoreData: FrictionData[],
  selected: string[],
  usesVoc: boolean
) => {
  const headers = await exportFunctionsHeaders(transformedChannels, usesVoc)
  const data = await exportFunctionsData(transformedChannels, journeys, frictionScoreData, selected, usesVoc)
  return { headers, data, filename }
}

const exportFunctionsHeaders = async (transformedChannels: TransformedChannel[], usesVoc: boolean) => {
  let headers = [
    { label: 'Function', key: 'function' },
    { label: 'Feature', key: 'feature' },
    { label: 'Sec Domain', key: 'secure' }
  ]
  let segments: LabelKeyObject[] = []
  let channelNames: LabelKeyObject[] = []

  transformedChannels.forEach((transformedChannel) => {
    const segmentKey = 'segment-' + getSurveySegmentFromSegment(transformedChannel.segment)
    if (usesVoc) {
      segments.push({
        label: 'Weight',
        key: segmentKey
      })
    }

    channelNames.push({
      label: transformedChannel.providerName,
      key: transformedChannel.key
    })
  })

  return headers.concat(uniqBy(segments, 'key')).concat(channelNames)
}

const exportFunctionsData = async (
  transformedChannels: TransformedChannel[],
  journeys: FeatureJourney[],
  frictionScoreData: FrictionData[],
  selected: string[],
  usesVoc: boolean
) => {
  let data: Record<string, string>[] = []

  let channelRow = { function: 'Channel' }
  let segmentRow = { function: 'Segment' }
  let channelVersionRow = { function: 'Channel Version' }
  let auditDateRow = { function: 'Audit Date' }

  let vocScoreRow = { function: 'VoC Score (Total)' }
  let utilityScoreRow = { function: 'Utility Score (Total)' }

  transformedChannels.forEach((transformedChannel) => {
    channelRow = {
      ...channelRow,
      [transformedChannel.key]: transformedChannel.channelName
    }

    segmentRow = {
      ...segmentRow,
      [transformedChannel.key]: transformedChannel.segmentName
    }

    channelVersionRow = {
      ...channelVersionRow,
      [transformedChannel.key]: transformedChannel.channel_version
    }

    auditDateRow = {
      ...auditDateRow,
      [transformedChannel.key]: getFormattedDate(transformedChannel.audit_date)
    }

    vocScoreRow = {
      ...vocScoreRow,
      [transformedChannel.key]: transformedChannel.cx_score
    }

    utilityScoreRow = {
      ...utilityScoreRow,
      [transformedChannel.key]: transformedChannel.score
    }
  })

  data.push(channelRow)
  data.push(segmentRow)
  data.push(channelVersionRow)
  data.push(auditDateRow)

  if (usesVoc) {
    data.push(vocScoreRow)
  }

  data.push(utilityScoreRow)

  journeys.forEach((journey) => {
    let functionRow = { function: journey.name }

    transformedChannels.forEach((transformedChannel) => {
      const segment = getSurveySegmentFromSegment(transformedChannel.segment)
      functionRow = {
        ...functionRow,
        [`segment-${segment}`]: journey.weight?.weight
      }
    })

    data.push(functionRow)

    if (selected.includes(`journey#${journey.id}`)) {
      journey.questions.forEach((question) => {
        let featureRow = {
          function: '',
          feature: question.text,
          secure: secDomainToString(question.secure_channelId)
        }

        transformedChannels.forEach((transformedChannel) => {
          let frictionScore = undefined
          let value: Answer | undefined = undefined
          let weight = undefined

          const frictionData = frictionScoreData.find((fd) => fd.auditId === transformedChannel.auditId)

          if (frictionData) {
            frictionScore = frictionData.friction_scores.find((fs) => fs.question === question.id)
          }

          const channelJourney = transformedChannel.journeys.find((j) => {
            return j.journey === journey.id
          })

          if (channelJourney) {
            value = channelJourney.answers.find((a) => a.question.id === question.id)
            weight = value ? value.weight.toFixed(2) : ''
          }

          const segment = getSurveySegmentFromSegment(transformedChannel.segment)

          featureRow = {
            ...featureRow,
            [`segment-${segment}`]: weight,
            [transformedChannel.key]: frictionScore
              ? frictionScore.friction_score
              : value?.value === 1
              ? 'Y'
              : value?.value === 2
              ? 'N'
              : 'N/A'
          }
        })

        data.push(featureRow)
      })

      let functionVoCRow = { feature: 'VoC Score' }
      let functionUtilityRow = { feature: 'Utility Score' }

      transformedChannels.forEach((transformedChannel) => {
        const channelJourney = transformedChannel.journeys.find((j) => {
          return j.journey === journey.id
        })

        functionVoCRow = {
          ...functionVoCRow,
          [transformedChannel.key]: channelJourney ? channelJourney.cx_score : 0
        }

        functionUtilityRow = {
          ...functionUtilityRow,
          [transformedChannel.key]: channelJourney ? channelJourney.score : 0
        }
      })

      if (usesVoc) {
        data.push(functionVoCRow)
      }
      data.push(functionUtilityRow)
    }
  })

  return data
}
