import { useApolloClient } from '@apollo/client'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { EqualHeight } from 'react-equal-height'
import { useFunctions } from '../../hooks/functions'
import { getFrictionScores } from '../../support/graphql/queries'
import { TabRankView } from '../../types/Channel'
import { Loading } from '../Common/Loading'
import { Columns, Container, Group, Row } from '../FunctionView/FunctionView.styled'
import { ChannelScore } from '../../declarations/ChannelScore'
import { FrictionData } from '../../declarations/FrictionScore'
import { FunctionViewColumnHeader, FunctionViewQuestionCell } from '../FunctionView/FunctionViewElements'
import { reverse, sortBy } from 'lodash'
import styled from 'styled-components'
import { RankViewHeader, RankViewQuestionStub } from './RankViewElements'

type RankViewProps = {
  channelScores: ChannelScore[]
  comparable?: boolean
  sortedOnText?: string
}

export const RankView: FunctionComponent<RankViewProps> = (props) => {
  const client = useApolloClient()
  const { journeys, transformChannels } = useFunctions()

  const { sortedOnText, channelScores, comparable = false } = props

  const [frictionScores, setFrictionScores] = useState<FrictionData[]>([])
  const [frictionScoresLoaded, setFrictionScoresLoaded] = useState<boolean>(false)
  const [renderAll, setRenderAll] = useState(false)
  const [reverseOrder, setReverseOrder] = useState(false)

  const providers = useMemo(() => transformChannels(channelScores), [channelScores, transformChannels])

  const auditIds = useMemo(() => {
    return channelScores.map((c) => c.auditId)
  }, [channelScores])

  const questionsToRender = useMemo(() => {
    // make a list of {question, journey} pairs...
    const questionsWithJourneys = journeys.flatMap((journey) =>
      journey.questions.map((question) => ({ question, journey }))
    )
    // ...sort them by descending weight...
    const inWeightOrder = sortBy(questionsWithJourneys, (x) => -(x.question.weight?.weight || 0))
    // ...set rank property = 1 to n
    const withRanks = inWeightOrder.map((x, i) => ({ ...x, rank: i + 1 }))
    // ...reverse if necessary...
    const sorted = reverseOrder ? reverse(withRanks) : withRanks
    // ...then restrict if necessary
    return sorted.slice(0, renderAll ? sorted.length : 10)
  }, [journeys, renderAll, reverseOrder])

  // The commit phase (DOM update) is very slow so in order to mitigate that a bit,
  // the first render after loading friction scores will only render the top 10 items.
  // then after that's been committed (i.e. this useEffect runs), we immediately re-render
  // with all the items
  useEffect(() => {
    if (!renderAll && frictionScoresLoaded) {
      setRenderAll(true)
    }
  }, [renderAll, frictionScoresLoaded])

  const loadFrictionScores = useCallback(async () => {
    const response = await getFrictionScores(client, { auditIds })
    setFrictionScores(response)
    setFrictionScoresLoaded(true)
  }, [auditIds, client])

  useEffect(() => {
    loadFrictionScores()
    return () => {
      setFrictionScores([])
      setFrictionScoresLoaded(false)
    }
  }, [auditIds, loadFrictionScores])

  if (frictionScores.length <= 0 && !frictionScoresLoaded) {
    return <Loading.Panel width={'100%'} height={600} />
  }

  const frictionDataByAudit = Object.fromEntries(frictionScores.map((x) => [x.auditId, x.friction_scores]))

  return (
    <EqualHeight>
      <Container>
        <Group style={{ width: 'auto' }}>
          <RankViewHeader reverseOrder={reverseOrder} setReverseOrder={setReverseOrder} />
          <Striped>
            {questionsToRender.map(({ question, journey, rank }) => (
              <RankViewQuestionStub
                key={rank}
                rank={rank}
                question={question}
                journey={journey}
                highlight={sortedOnText === question.text && comparable}
              />
            ))}
          </Striped>
        </Group>
        <Columns>
          {providers.map((provider, providerIdx) => {
            const answersByQuestionId = Object.fromEntries(
              provider.answers.flatMap((j) => j.answers.map((a) => [a.questionId, a.value]))
            )
            const frictionDataByQuestionId = Object.fromEntries(
              (frictionDataByAudit[provider.auditId] || []).map((x) => [x.question, x])
            )
            return (
              <Row key={providerIdx}>
                <FunctionViewColumnHeader
                  provider={provider}
                  comparable={comparable}
                  channelScores={channelScores}
                  view={TabRankView}
                />
                <div className="oddly">
                  {questionsToRender.map(({ question, journey, rank }) => (
                    <div key={rank}>
                      <FunctionViewQuestionCell
                        provider={provider}
                        journey={journey}
                        frictionScore={frictionDataByQuestionId[question.id]}
                        value={answersByQuestionId[question.id]}
                        question={question}
                      />
                    </div>
                  ))}
                </div>
              </Row>
            )
          })}
        </Columns>
      </Container>
    </EqualHeight>
  )
}

export const Striped = styled.div`
  && {
    background-color: white;
    div.oddly:nth-of-type(odd) {
      background-color: #f6f8fa;
    }
  }
`
