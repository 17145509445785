/**
 * Format a date to a human-readable string.
 *
 * @param {string|number} dateString - The date to format.
 * @param {Intl.DateTimeFormatOptions} [formatOptions] - Optional formatting options.
 *
 * @returns {string} The formatted date string.
 */
const getFormattedDate = (dateString: string | number, formatOptions?: Intl.DateTimeFormatOptions): string => {
  const defaultOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }

  const options = formatOptions || defaultOptions
  const date = new Date(dateString)
  return date.toLocaleDateString('en-GB', options)
}

export default getFormattedDate
