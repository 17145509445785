import { Box, CircularProgress, Grid, Radio, Typography } from '@material-ui/core'
import { FunctionComponent, useMemo } from 'react'
import CategoryTab from '../../components/Common/CategoryTab'
import RadarChart from '../../components/RadarChart'
import Score from '../../components/Score'
import { BodyTextSmall2, TitleText } from '../../components/Typography/Typography'
import { useComparison } from '../../contexts/ComparisonContext'
import { useCriteria } from '../../contexts/Search/Criteria'
import { withSingleChartData } from '../../support/charts/get-single-chart-data'
import getFormattedDate from '../../support/date/getFormattedDate'
import { TabChannelView } from '../../types/Channel'
import { ChannelBadge } from '../Common/ChannelBadge'
import { SegmentBadge } from '../Common/SegmentBadge'
import { CheckedIcon, CustomFullScreen, UncheckedIcon } from '../Common/icons'
import { GridItemBox, GridItemPaper, GridItemTail, HoverBox, ScoreBox, useStyles } from './ChannelScoreItem.styled'
import { useSearchQuery } from '../../contexts/Search/Query'
import { ChannelScore } from '../../declarations/ChannelScore'

interface ChannelScoreItemProps {
  channelScore: ChannelScore
  handleClick: Function
}

export const ChannelScoreItem: FunctionComponent<ChannelScoreItemProps> = ({ channelScore, handleClick }) => {
  const comparison = useComparison()
  const searchCriteria = useCriteria()
  const searchQuery = useSearchQuery()
  const usesVoc = searchQuery.state.selectedIndustry.usesVoc

  const chartData = useMemo(() => {
    return withSingleChartData(channelScore, searchCriteria.state.journeys, usesVoc ? 'cx_score' : 'score')
  }, [channelScore, searchCriteria.state.journeys, usesVoc])

  const functionCount = useMemo(
    () =>
      channelScore.journeys
        .map((journey) => journey.answers.filter((answer) => answer.value === 1).length)
        .reduce((sum, v) => sum + v, 0),
    [channelScore]
  )

  const selected = comparison.state[TabChannelView].filter((c) => {
    return c.value.key === channelScore.key
  })

  const isChecked = selected.length > 0
  const disabled = !isChecked && comparison.state[TabChannelView].length === 10

  const toggleAddToCompare = (e: any) => {
    e.stopPropagation()

    comparison.dispatch({
      type: 'TOGGLE_COMPARISON_ITEM_IN_STATE',
      payload: { item: channelScore, view: TabChannelView }
    })
  }

  const classes = useStyles()

  return (
    <Grid xs={12} md={6} lg={4} xl={2} item key={channelScore.key}>
      <GridItemPaper
        style={{
          borderWidth: '3px',
          borderStyle: 'solid',
          borderColor: `${isChecked ? '#001433' : 'transparent'}`,
          borderRadius: '12px'
        }}
      >
        <GridItemBox style={{ padding: '12px' }}>
          <Box display="flex">
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box display="flex">
                <Box>
                  <Radio
                    style={{
                      paddingLeft: 0
                    }}
                    checked={isChecked}
                    onClick={toggleAddToCompare}
                    classes={{ root: classes.radio }}
                    disabled={disabled}
                    disableRipple={true}
                    icon={<UncheckedIcon />}
                    inputProps={{ 'aria-label': channelScore.providerName }}
                    checkedIcon={<CheckedIcon />}
                    data-cy={`channelScore-radio-${channelScore.key}`}
                  />
                </Box>
                <Box>
                  <TitleText style={{ color: '#001433' }}>{channelScore.providerName}</TitleText>
                  <Typography style={{ fontSize: '12px', lineHeight: '18px', color: '#001433', whiteSpace: 'nowrap' }}>
                    {getFormattedDate(channelScore.audit_date)}
                  </Typography>
                </Box>
              </Box>
              {channelScore.providerLogo && (
                <img
                  style={{ maxHeight: '40px', width: 'auto' }}
                  src={channelScore.providerLogo}
                  alt={channelScore.providerName}
                />
              )}
            </Box>
          </Box>
          <Box marginTop={2} display="flex" justifyContent="space-between" style={{ paddingLeft: '21px' }}>
            <Box>
              <ChannelBadge
                version={channelScore.channel_version}
                channel={channelScore.channel}
                channelName={channelScore.channelName}
                providerName={channelScore.providerName}
                auditDate={channelScore.audit_date}
              />

              <SegmentBadge segment={channelScore.segment} segmentName={channelScore.segmentName} />
            </Box>
            <Box>
              <CategoryTab border="solid 1px #D9DBE0" padding="6px 10px!important">
                {functionCount} Functions
              </CategoryTab>
            </Box>
          </Box>
          <Box marginY={'6px'}>
            {chartData ? (
              <Box position="relative">
                <HoverBox
                  onClick={() => handleClick(channelScore)}
                  width="30px"
                  height="30px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  right="0"
                  top="8px"
                  zIndex="1"
                >
                  <CustomFullScreen style={{ color: '#8B96A4' }} />
                </HoverBox>
                {/* <SpiderChart hideLabels hideLegend data={chartData} /> */}
                <HoverBox onClick={() => handleClick(channelScore)} height="300px" width="100%">
                  <RadarChart
                    showPercentages={false}
                    height={300}
                    chartData={chartData}
                    hideCategories={true}
                    dotsOn={false}
                  />
                </HoverBox>
              </Box>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" padding={4} width="300px" height="300px">
                <CircularProgress />
              </Box>
            )}
          </Box>
          <Box display="flex" justifyContent="space-around">
            {usesVoc && (
              <div style={{ width: '100%' }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  textAlign={'center'}
                >
                  <BodyTextSmall2 align="center" style={{ marginBottom: 9 }}>
                    VoC Score
                  </BodyTextSmall2>
                  <ScoreBox>
                    <Score score={channelScore.cx_score} />
                  </ScoreBox>
                </Box>
              </div>
            )}
            <div style={{ width: '100%', borderLeft: '1px solid #D9DBE0' }}>
              <Box
                width={'100%'}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                textAlign={'center'}
              >
                <BodyTextSmall2 align="center" style={{ marginBottom: 9 }}>
                  Utility Score
                </BodyTextSmall2>
                <ScoreBox>
                  <Score score={channelScore.score} />
                </ScoreBox>
              </Box>
            </div>
          </Box>
        </GridItemBox>
      </GridItemPaper>
      <GridItemTail />
    </Grid>
  )
}
