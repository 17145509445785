import { ApolloClient, ApolloError, gql } from '@apollo/client'
import { SegmentBadgeBusinessAndCorporateSegment } from '../../components/Common/SegmentBadge'
import { Segments } from '../../types/Segments'

export type FetchProvidersResponse = {
  getProviders: ProviderInfo[]
}

export interface ProviderInfo {
  id: number
  image: string
  industry: Segments | typeof SegmentBadgeBusinessAndCorporateSegment
  market: number
  name: string
}

export type GetProviderVariables = {
  providerIds: number[]
}

/**
 * GraphQL query to fetch providers based on provider IDs.
 */
const query = gql`
  query getProviders($providerIds: [Int!]!) {
    getProviders(providerIds: $providerIds) {
      id
      image
      industry
      market
      name
    }
  }
`

/**
 * Fetches providers from the API using Apollo Client.
 *
 * @param client - The Apollo Client instance.
 * @param variables - The variables for the query, including provider IDs.
 * @returns A promise that resolves to the response data containing provider information.
 * @throws Will throw an error if the request fails.
 */
export const fetchProviders = async (
  client: ApolloClient<object>,
  variables: GetProviderVariables
): Promise<FetchProvidersResponse> => {
  const MAX_RETRIES = 3
  for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
    try {
      const response = await client.query<FetchProvidersResponse, GetProviderVariables>({
        query,
        variables
      })
      return response.data
    } catch (error) {
      console.error(`fetchProviders: attempt ${attempt + 1} caught error:`, error)

      if (error instanceof ApolloError) {
        // Log detailed error information
        console.error('GraphQL errors:', error.graphQLErrors)
        console.error('Network error:', error.networkError)
      }

      if (attempt >= MAX_RETRIES - 1) {
        throw new Error(`Failed to fetch providers after ${MAX_RETRIES} attempts: ${error}`)
      }

      // Optionally, add a delay between retries
      await new Promise((resolve) => setTimeout(resolve, 1000 * (attempt + 1)))
    }
  }

  throw new Error('Failed to fetch providers due to an unknown error')
}
