import { FunctionComponent } from 'react'
import { Route, Routes } from 'react-router-dom'
import ProtectedRoute from '../components/Router/ProtectedRoute'
import Dashboard from '../pages/Dashboard'

export const DashboardContainer: FunctionComponent = () => {
  return (
    <Routes>
      <Route
        path={'/dashboard'}
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/'}
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<></>} />
    </Routes>
  )
}
