import styled from 'styled-components'
import { Box, Divider as MatDivider } from '@material-ui/core'

export const Divider = styled(MatDivider)`
  && {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
`

export const ScoreLabel = styled(Box)`
  && {
    color: #647386;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.2px;
    text-align: right;
    font-weight: 600;
  }
`

export const HeaderText = styled(Box)`
  && {
    color: #647386;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.2px;
    text-align: right;
  }
`

export const BodyText = styled(Box)`
  && {
    color: #647386;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.2px;
    text-align: right;
    font-weight: 600;
  }
`
