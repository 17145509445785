import { ApolloClient, gql } from '@apollo/client'

type SeaachInsightsParameters = {
  term: string
}

type SearchInsightsResponse = {
  searchInsights: string[]
}

const query = gql`
  query searchInsights($term: String) {
    searchInsights(term: $term)
  }
`

export const searchInsights = async (
  client: ApolloClient<object>,
  variables: SeaachInsightsParameters
): Promise<string[]> => {
  try {
    const results = await client.query<SearchInsightsResponse, SeaachInsightsParameters>({
      query,
      variables: variables
    })
    return results.data.searchInsights
  } catch (caught) {
    console.error(caught)
    throw Error()
  }
}
