import { ApolloClient, gql } from '@apollo/client'
import { StackRaw } from '../../declarations/StackRaw'

/**
 * The response type for the api request.
 */
type GetStacksResponse = {
  getStacks: StackRaw[]
}

/**
 * The variables used for the request.
 */
type GetStacksVariables = {
  auditIds: number[]
  audience: Record<string, number[]>
  orderBy: { field: string; direction: string; friction_question?: number }
  offset?: number
  limit?: number
}

/**
 * The query to use when running the request.
 */
const query = gql`
  query stacks($auditIds: [Int], $audience: AudienceSearch, $orderBy: OrderBy) {
    getStacks(auditIds: $auditIds, audience: $audience, orderBy: $orderBy) {
      auditId
      provider
      journey
      segment
      count
      channel
      cx_score
      score
      asset_locations
      audit_date
    }
  }
`

/**
 * Gets the stacks from the api.
 *
 * @param {ApolloClient<object>} client
 * @param {GetStacksVariables} variables
 *
 * @throws Error
 */
export const getStacks = async (client: ApolloClient<object>, variables: GetStacksVariables) => {
  try {
    const stacksResult = await client.query<GetStacksResponse, GetStacksVariables>({
      query,
      variables
    })

    return stacksResult.data
  } catch (caught) {
    throw Error()
  }
}
