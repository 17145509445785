/**
 * This file handles the storing of the query that is generated based on the available or selected criteria,
 * it stores the current query used for the last search.
 */

import { createContext, FunctionComponent, useContext, useReducer } from 'react'
import { Industry, Cycle } from '../../types/Industries'
import { Segments } from '../../types/Segments'

/**
 * A type helper for Actions.
 */
type SetQueryPayload<T> = {
  value: T
}

/**
 * Types for Actions that are used within the reducer functions.
 */
type SetInitialQuery = { type: 'SET_INITIAL_QUERY_STATE'; payload: SetQueryPayload<Query> }
type SetMarketQuery = { type: 'SET_MARKET_QUERY_STATE'; payload: SetQueryPayload<number[]> }
type SetProviderQuery = { type: 'SET_PROVIDER_QUERY_STATE'; payload: SetQueryPayload<number[]> }
type SetSegmentQuery = { type: 'SET_SEGMENT_QUERY_STATE'; payload: SetQueryPayload<Segments[]> }
type SetChannelQuery = { type: 'SET_CHANNEL_QUERY_STATE'; payload: SetQueryPayload<number[]> }
type SetAudienceQuery = { type: 'SET_AUDIENCE_QUERY_STATE'; payload: SetQueryPayload<Record<string, number[]>> }
type SetQuestionQuery = {
  type: 'SET_QUESTION_QUERY_STATE'
  payload: SetQueryPayload<{ type?: string; questionIds?: number[] }>
}
type SetSortQuery = {
  type: 'SET_SORT_QUERY_STATE'
  payload: SetQueryPayload<{ label: string; field: string; direction: string; friction_question?: number }>
}
type SetPaginationQuery = {
  type: 'SET_PAGINATION_QUERY_STATE'
  payload: SetQueryPayload<{ page: number; offset: number; limit: number }>
}
type SetSelectedIndustry = { type: 'SET_SELECTED_INDUSTRY'; payload: SetQueryPayload<Industry> }
type SetSelectedCycle = { type: 'SET_SELECTED_CYCLE'; payload: SetQueryPayload<Cycle> }

/**
 * A Union for the Actions.
 */
type Action =
  | SetInitialQuery
  | SetMarketQuery
  | SetProviderQuery
  | SetSegmentQuery
  | SetChannelQuery
  | SetAudienceQuery
  | SetQuestionQuery
  | SetSortQuery
  | SetPaginationQuery
  | SetSelectedIndustry
  | SetSelectedCycle

/**
 * Dispatch type helper.
 */
type Dispatch = (action: Action) => void

/**
 * The Query state type.
 */
export type Query = {
  markets: number[]
  providers: number[]
  segments: Segments[]
  channels: number[]
  audience: Record<string, number[]>
  questions: {
    type: string
    questionIds: number[]
  }
  sort: {
    label: string
    field: string
    direction: string
    friction_question?: number
    friction_question_text?: string
  }
  pagination: {
    page: number
    offset: number
    limit: number
  }
}

/**
 * The State type.
 */
export type State = {
  selectedIndustry: Industry
  selectedCycle: Cycle
} & Query

type QueryContextType = { state: State; dispatch: Dispatch } | undefined
const QueryContext = createContext<QueryContextType>(undefined)
QueryContext.displayName = 'QueryContext'

/**
 * Used for setting the initial query. note that currently in some cases,
 * selectedInsudtry and selectedCycle are set BEFORE setting the intial query
 * which is a bit dodgy
 */
const setInitialQuery = (state: State, action: SetInitialQuery): State => {
  return {
    ...state,
    ...action.payload.value
  }
}

/**
 * Used for setting the market query.
 */
const setMarketQuery = (state: State, action: SetMarketQuery): State => {
  return {
    ...state,
    markets: action.payload.value
  }
}

/**
 * Used for setting the provider query.
 */
const setProviderQuery = (state: State, action: SetProviderQuery): State => {
  return {
    ...state,
    providers: action.payload.value
  }
}

/**
 * Used for setting the segment query.
 */
const setSegmentQuery = (state: State, action: SetSegmentQuery): State => {
  return {
    ...state,
    segments: action.payload.value
  }
}

/**
 * Used for setting the channel query.
 */
const setChannelQuery = (state: State, action: SetChannelQuery): State => {
  return {
    ...state,
    channels: action.payload.value
  }
}

/**
 * Used for setting the audience query.
 */
const setAudienceQuery = (state: State, action: SetAudienceQuery): State => {
  return {
    ...state,
    audience: action.payload.value
  }
}

/**
 * Used for setting the question query.
 */
const setQuestionQuery = (state: State, action: SetQuestionQuery): State => {
  return {
    ...state,
    questions: {
      ...state.questions,
      ...action.payload.value
    }
  }
}

/**
 * Used for setting the sort query.
 */
const setSortQuery = (state: State, action: SetSortQuery): State => {
  console.log('setSortQuery', state)
  return {
    ...state,
    sort: action.payload.value
  }
}

/**
 * Used for setting the pagination query.
 */
const setPaginationQuery = (state: State, action: SetPaginationQuery): State => {
  return {
    ...state,
    pagination: action.payload.value
  }
}

const setSelectedIndustry = (state: State, action: SetSelectedIndustry): State => {
  return {
    ...state,
    selectedIndustry: action.payload.value
  }
}

const setSelectedCycle = (state: State, action: SetSelectedCycle): State => {
  return {
    ...state,
    selectedCycle: action.payload.value
  }
}

/**
 * A reducer for cycling through dispatched events and call their corresponding function.
 *
 * @param {State} state
 * @param {Action} action
 *
 * @throws Error
 */
const reducer = (state: State, action: Action) => {
  if (action.type === 'SET_INITIAL_QUERY_STATE') {
    return setInitialQuery(state, action)
  }

  if (action.type === 'SET_MARKET_QUERY_STATE') {
    return setMarketQuery(state, action)
  }

  if (action.type === 'SET_PROVIDER_QUERY_STATE') {
    return setProviderQuery(state, action)
  }

  if (action.type === 'SET_SEGMENT_QUERY_STATE') {
    return setSegmentQuery(state, action)
  }

  if (action.type === 'SET_CHANNEL_QUERY_STATE') {
    return setChannelQuery(state, action)
  }

  if (action.type === 'SET_AUDIENCE_QUERY_STATE') {
    return setAudienceQuery(state, action)
  }

  if (action.type === 'SET_QUESTION_QUERY_STATE') {
    return setQuestionQuery(state, action)
  }

  if (action.type === 'SET_SORT_QUERY_STATE') {
    return setSortQuery(state, action)
  }

  if (action.type === 'SET_PAGINATION_QUERY_STATE') {
    return setPaginationQuery(state, action)
  }

  if (action.type === 'SET_SELECTED_INDUSTRY') {
    return setSelectedIndustry(state, action)
  }

  if (action.type === 'SET_SELECTED_CYCLE') {
    return setSelectedCycle(state, action)
  }

  throw new Error(`Unhandled action type in 'QueryContext'`)
}

/**
 * The Query Provider component, including in the app bootstrap process.
 */
export const QueryProvider: FunctionComponent = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    markets: [],
    providers: [],
    segments: [],
    channels: [],
    audience: {},
    questions: {
      type: 'OR',
      questionIds: []
    },
    sort: { label: 'VoC Score (high-low)', field: 'CX_SCORE', direction: 'DESC' },
    pagination: {
      page: 1,
      offset: 0,
      limit: 12
    },
    selectedIndustry: {} as Industry,
    selectedCycle: {} as Cycle
  })
  QueryProvider.displayName = 'QueryProvider - reducer'

  return <QueryContext.Provider value={{ state, dispatch }}>{props.children}</QueryContext.Provider>
}

/**
 * A hook used for accessing the query in components.
 */
export const useSearchQuery = () => {
  const context = useContext(QueryContext)

  if (context === undefined) {
    throw new Error('useSearchQuery must be used within a QueryProvider')
  }

  return context
}
