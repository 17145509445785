import { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { EqualHeightElement } from 'react-equal-height'
import { Question, Sticker } from '../FunctionView/FunctionView.styled'
import { FunctionViewQuestionStubProps } from '../FunctionView/FunctionViewElements'
import { Checkbox, Typography } from '@material-ui/core'
import { WeightBadge } from '../Common/WeightBadge'
import { SecureChannelBadge } from '../Common/SecureChannelBadge'

type RankViewHeaderProps = {
  reverseOrder: boolean
  setReverseOrder: Dispatch<SetStateAction<boolean>>
}

export const RankViewHeader: FunctionComponent<RankViewHeaderProps> = ({ reverseOrder, setReverseOrder }) => (
  <Sticker
    style={{
      minWidth: 500,
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      backgroundColor: 'white',
      borderBottom: '1px solid #D9DBE0',
      zIndex: 3,
      boxShadow: '5px 0px 15px 0px rgba(0,0,0,0.06)'
    }}
  >
    <div style={{ padding: '30px' }}>
      <Typography>
        Functions are ranked according to their VoC weightings (n.b. to alter VoC weighting, please click on the
        &lsquo;VoC setup&rsquo; button)
      </Typography>
    </div>
    <div style={{ padding: '15px' }}>
      <Typography style={{ marginLeft: '6px' }} variant={'h4'}>
        <Checkbox checked={reverseOrder} onChange={() => setReverseOrder(!reverseOrder)}></Checkbox>
        Reverse Order
      </Typography>
    </div>
  </Sticker>
)

type CircledNumberProps = {
  n: number
}

const CircledNumber: FunctionComponent<CircledNumberProps> = ({ n }) => (
  <div
    style={{
      display: 'inline-block',
      width: 25,
      height: 25,
      lineHeight: '25px',
      borderRadius: '50%',
      fontSize: n < 100 ? 16 : 13,
      textAlign: 'center' as const,
      border: '2px solid #736486'
    }}
  >
    {n}
  </div>
)

type RankViewQuestionStubProps = FunctionViewQuestionStubProps & { rank: number }

export const RankViewQuestionStub: FunctionComponent<RankViewQuestionStubProps> = ({
  question,
  journey,
  highlight,
  rank
}) => (
  <Question
    style={{
      borderTopLeftRadius: '6px',
      borderBottomLeftRadius: '6px',
      marginLeft: '50px'
    }}
    className={'oddly'}
  >
    <EqualHeightElement name={`${journey.key}#question#${question.id}`}>
      <div style={{ padding: '16px', display: 'block' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            width: '100%'
          }}
        >
          <div style={{ flex: '1 1 auto', width: 'auto' }}>
            <Typography component={'span'} variant={'body1'}>
              {highlight ? (
                <>
                  <div style={{ position: 'relative', top: '-220px', left: 0 }}>what is here?</div>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        flex: '5',
                        paddingLeft: '5px',
                        fontWeight: 'normal',
                        color: '#24AB3B'
                      }}
                    >
                      {question.text}
                    </div>
                    <div
                      style={{
                        flex: '3',
                        paddingRight: '5px',
                        paddingLeft: '5px',
                        fontWeight: 'normal',
                        color: '#24AB3B'
                      }}
                    >
                      Sorted by Friction Score
                    </div>
                    <div style={{ flex: 1, textAlign: 'right', paddingTop: 10 }}>
                      <CircledNumber n={rank} />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ display: 'flex' }}>
                    <p style={{ flex: 8 }}>{question.text}</p>
                    <div style={{ flex: 1, textAlign: 'right', paddingTop: 10 }}>
                      <CircledNumber n={rank} />
                    </div>
                  </div>
                </>
              )}
            </Typography>
            <div style={{ display: 'flex', paddingTop: '15px' }}>
              {question.weight && (
                <WeightBadge
                  weight={question.weight.weight}
                  question={question.id}
                  text={question.text}
                  segment={question.weight.segment}
                />
              )}
              <div>
                <Typography component={'span'}>
                  {<SecureChannelBadge secureChannel={question.secure_channelId} />}
                </Typography>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <Typography component={'span'} style={{ fontWeight: 'bold' }}>
                  {journey.name}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </EqualHeightElement>
  </Question>
)
