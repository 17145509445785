import { Box, Grid, Paper } from '@material-ui/core'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { Tab } from '../../../../components/Channel/Tabs'
import { ChannelBadge } from '../../../../components/Common/ChannelBadge'
import { SegmentBadge } from '../../../../components/Common/SegmentBadge'
import { Modal } from '../../../../components/Modal/Modal'
import Score from '../../../../components/Score'
import { useChannel } from '../../../../contexts/ChannelContext'
import getFormattedDate from '../../../../support/date/getFormattedDate'
import { ChannelTabs, TabChannelView, TabFunctionView, TabJourneyView } from '../../../../types/Channel'
import { BodyText, Divider, HeaderText, ScoreLabel } from './SingleModal.styled'
import { ChannelScore as ChannelScore_Type } from '../../../../declarations/ChannelScore'
import { StackRaw } from '../../../../declarations/StackRaw'
import { ToolbarAction } from '../../../../declarations/ToolbarAction'

type SingleModalProps = {
  usesVoc: boolean
}

export const SingleModal: FunctionComponent<SingleModalProps> = (props) => {
  const channel = useChannel()

  const [selectedView, setSelectedView] = useState<ChannelTabs>(channel.state.default.view)

  const toolbarActions: ToolbarAction[] = [
    {
      key: TabChannelView,
      selected: selectedView === TabChannelView,
      title: 'Channel',
      onClick: () => setSelectedView(TabChannelView)
    },
    {
      key: TabFunctionView,
      selected: selectedView === TabFunctionView,
      title: 'Functions',
      onClick: () => setSelectedView(TabFunctionView)
    },
    {
      key: TabJourneyView,
      selected: selectedView === TabJourneyView,
      title: 'Journeys',
      onClick: () => setSelectedView(TabJourneyView)
    }
  ]

  const onClose = () => {
    channel.dispatch({
      type: 'TOGGLE_CHANNEL_MODAL_STATE',
      payload: { value: false }
    })
  }

  const title = useMemo(() => channel.state.channelScore?.providerName, [channel.state.channelScore])

  useEffect(() => {
    if (selectedView !== channel.state.default.view) {
      setSelectedView(channel.state.default.view)
    }
    // if you add the dependency "selectedView", the dialog always flips back
    // to default view whenever you change the view. Deleting the useEffect
    // seems to work OK so it's not really clear what the intention was
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel.state.default, channel.state.default.view])

  if (!channel.state.channelScore) return null

  return (
    <>
      <Modal
        open={channel.state.open}
        onClose={onClose}
        closeable={true}
        title={title}
        paddingX={0}
        paddingY={0}
        actions={toolbarActions}
      >
        <SingleModalHeader channelScore={channel.state.channelScore} usesVoc={props.usesVoc} />

        {selectedView === TabChannelView && (
          <Tab.Channel channelScore={channel.state.channelScore} usesVoc={props.usesVoc} />
        )}

        {selectedView === TabFunctionView && (
          <Tab.Functions channelScore={channel.state.channelScore} usesVoc={props.usesVoc} />
        )}

        {selectedView === TabJourneyView && <Tab.Journeys channelScore={channel.state.channelScore} />}
      </Modal>
    </>
  )
}

type SingleModalHeaderProps = {
  channelScore: ChannelScore_Type
  stack?: StackRaw
  usesVoc: boolean
}

const SingleModalHeader: FunctionComponent<SingleModalHeaderProps> = (props) => {
  const { segmentName, providerLogo, providerName, cx_score, score, audit_date, segment } = props.channelScore

  return (
    <Paper style={{ overflow: 'scroll' }}>
      <Box paddingX={4} paddingY={2}>
        <Grid container>
          <Grid container item xs={4} alignContent={'center'}>
            <ChannelBadge
              version={props.channelScore.channel_version}
              channel={props.channelScore.channel}
              channelName={props.channelScore.channelName}
              providerName={props.channelScore.providerName}
              auditDate={props.channelScore.audit_date}
            />
            <SegmentBadge segment={segment} segmentName={segmentName} />
          </Grid>
          <Grid item xs={8}>
            <Box display="flex" justifyContent="flex-end">
              <Box>
                <ChannelAuditDate headerText="Audit date:" value={`${getFormattedDate(audit_date)}, ${segmentName}`} />
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box>
                <ChannelScore score={score} scoreLabel="Utility Score" />
              </Box>
              {props.usesVoc && (
                <>
                  <Divider orientation="vertical" flexItem />
                  <Box>
                    <ChannelScore score={cx_score} scoreLabel="VoC Score" />
                  </Box>
                </>
              )}
              <Divider orientation="vertical" flexItem />
              <Box>{providerLogo && <img width={'100px'} src={providerLogo} alt={providerName} />}</Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

type ChannelScoreProps = {
  scoreLabel: string
  score: number
}

const ChannelScore: FunctionComponent<ChannelScoreProps> = (props) => {
  return (
    <Box display="flex" alignItems="center">
      <Box marginRight={2}>
        <ScoreLabel>{props.scoreLabel}</ScoreLabel>
      </Box>
      <Score score={props.score} />
    </Box>
  )
}

type ChannelAuditDateProps = {
  headerText: string
  value: string
}

const ChannelAuditDate: FunctionComponent<ChannelAuditDateProps> = (props) => {
  return (
    <Box>
      <HeaderText>{props.headerText}</HeaderText>
      <BodyText>{props.value}</BodyText>
    </Box>
  )
}
