import {
  Backdrop,
  Box,
  Fade,
  LinearProgress,
  Modal,
  Theme,
  Typography,
  createStyles,
  styled,
  withStyles
} from '@material-ui/core'
import React from 'react'

export type FullPageLoadingProps = {
  progress: number
  open: boolean
  text?: string
  icon?: React.ReactNode
  onCanceled?: () => void
}

const FullpageLoading = (props: FullPageLoadingProps) => {
  return (
    <Modal open={props.open} BackdropProps={{ timeout: 600 }} closeAfterTransition BackdropComponent={Backdrop}>
      <Fade in={props.open}>
        <StyledContainer>
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              border: '5px solid white',
              borderRadius: '10px',
              backgroundColor: 'rgba(0,15,38,0.8)',
              padding: '2em'
            }}
          >
            <img src="/assets/images/ani-network.gif" alt="Animated eye" width={'100px'} />
            <Typography variant="h6" style={{ color: 'whitesmoke' }}>
              Finding your content...
            </Typography>
            <StyledProgressBar
              style={{ width: '300px', marginTop: '0.8rem', marginBottom: '1.5rem' }}
              color="secondary"
              variant="determinate"
              value={props.progress || 0}
            />

            <StyledTipText variant="h1">{props.text || 'Loading...'}</StyledTipText>
          </div>
        </StyledContainer>
      </Fade>
    </Modal>
  )
}

const StyledProgressBar = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '1em',
      borderRadius: 5
    },
    bar: {
      borderRadius: 5
    }
  })
)(LinearProgress)

const StyledContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: '30vh',
  alignItems: 'center'
})

const StyledTipText = styled(Typography)({
  color: 'white',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  marginTop: '1rem'
})

export default FullpageLoading
