import { Dropdown } from './Filter/Dropdown'
import { Group } from './Filter/Option/Group'
import { useCriteria } from '../../contexts/Search/Criteria'
import { useSearchQuery } from '../../contexts/Search/Query'
import { SearchKind } from '../../declarations/SearchCriteria'

export const MarketFilter = () => {
  const searchCriteria = useCriteria()
  const searchQuery = useSearchQuery()

  const handleSelectedChange = <K extends keyof SearchKind>(key: K, value: number, state: boolean) => {
    let criteria = Object.assign({}, {}, searchQuery.state)
    let markets = criteria['markets']
    let providers = criteria['providers']

    if (Array.isArray(markets)) {
      if (!state) {
        if (markets.includes(value)) {
          markets.splice(
            markets.findIndex((item) => item === value),
            1
          )
        }
      } else {
        markets.push(value)
      }
    }

    searchCriteria.state.providers
      .filter((p) => p.market === value)
      .map((p) => p.id)
      .forEach((p) => {
        if (!state) {
          if (providers.includes(p)) {
            providers.splice(
              providers.findIndex((item) => item === p),
              1
            )
          }
        } else {
          providers.push(p)
        }
      })

    searchQuery.dispatch({ type: 'SET_MARKET_QUERY_STATE', payload: { value: markets } })
    searchQuery.dispatch({ type: 'SET_PROVIDER_QUERY_STATE', payload: { value: providers } })
  }

  const handleSelectAll = <K extends keyof SearchKind>(key: K, state: boolean) => {
    if (state) {
      let options = searchCriteria.state['markets']
      let ids = options.map((criterion) => criterion.id)
      let providersIds = searchCriteria.state.providers.map((p) => p.id)

      searchQuery.dispatch({ type: 'SET_MARKET_QUERY_STATE', payload: { value: ids } })
      searchQuery.dispatch({ type: 'SET_PROVIDER_QUERY_STATE', payload: { value: providersIds } })
    } else {
      searchQuery.dispatch({ type: 'SET_MARKET_QUERY_STATE', payload: { value: [] } })
      searchQuery.dispatch({ type: 'SET_PROVIDER_QUERY_STATE', payload: { value: [] } })
    }
  }

  return (
    <Dropdown
      label={'Markets'}
      count={searchQuery.state.markets.length}
      total={searchCriteria.state.markets.length}
      disabled={searchCriteria.state.loading}
    >
      <Group
        groupKey={'markets'}
        onSelected={handleSelectedChange}
        onSelectAll={handleSelectAll}
        criteria={searchCriteria.state.markets}
        selected={searchQuery.state.markets}
      />
    </Dropdown>
  )
}
