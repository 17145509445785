import React, { PropsWithChildren, useMemo } from 'react'
import { useExpandable } from '../../contexts/ExpandableContext'
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons'

type ExpandableTriggerProps = {
  name: string
}

const ExpandableTrigger = (props: PropsWithChildren<ExpandableTriggerProps>) => {
  const expandable = useExpandable()
  // console.log("ExpandableTrigger", expandable.state)

  const handleTriggerClick = (event: React.MouseEvent<HTMLElement>) => {
    expandable.dispatch({ type: 'TOGGLE_EXPANDED_STATE', payload: { name: props.name } })
  }

  const isExpanded = useMemo(
    () => expandable.state.expanded.includes(props.name),
    [expandable.state.expanded, props.name]
  )

  return (
    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleTriggerClick}>
      {isExpanded ? (
        <RemoveCircleOutline style={{ color: '#8B96A4' }} />
      ) : (
        <AddCircleOutline style={{ color: '#8B96A4' }} />
      )}
      {props.children}
    </div>
  )
}

type ExpandableGroupTriggerProps = {
  collapse?: boolean
}

const ExpandableGroupTrigger = (props: PropsWithChildren<ExpandableGroupTriggerProps>) => {
  const { collapse = false } = props
  const expandable = useExpandable()
  const type = collapse ? 'COLLAPSE_ALL_EXPANDED_STATE' : 'EXPAND_ALL_EXPANDED_STATE'

  const handleTriggerClick = (event: React.MouseEvent<HTMLElement>) => {
    expandable.dispatch({ type, payload: {} })
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleTriggerClick}>
      {collapse ? (
        <RemoveCircleOutline style={{ color: '#8B96A4' }} />
      ) : (
        <AddCircleOutline style={{ color: '#8B96A4' }} />
      )}
      {props.children}
    </div>
  )
}

type ExpandableGroupProps = {
  name: string
}

const ExpandableGroup = (props: PropsWithChildren<ExpandableGroupProps>) => {
  const expandable = useExpandable()

  const isExpanded = useMemo(
    () => expandable.state.expanded.includes(props.name) || expandable.state.checked.includes(props.name),
    [expandable.state.checked, expandable.state.expanded, props.name]
  )

  if (!isExpanded) {
    return null
  }

  return <>{props.children}</>
}

export const Expandable = {
  Trigger: ExpandableTrigger,
  GroupTrigger: ExpandableGroupTrigger,
  Group: ExpandableGroup
}
