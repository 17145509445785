import { Channels } from './Channels'
import { Functions } from './Functions'
import { Journeys } from './Journeys'
import { Rank } from './Rank'

export const Tab = {
  Channels,
  Functions,
  Journeys,
  Rank
}
