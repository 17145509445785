import { Box, Container, Grid } from '@material-ui/core'
import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BlogList } from '../../components/Blog/BlogList'
import { Header } from '../../components/Blog/Header'
import { Pagination } from '../../components/Pagination/Pagination'
import { useQueryParams } from '../../hooks/query'
import { Blog } from '../../support/contentful/content-types'
import { useApolloClient } from '@apollo/client'
import { getInsights } from '../../services/api/get-insights'

export const Index: FunctionComponent = () => {
  const navigate = useNavigate()
  const query = useQueryParams()
  const apolloClient = useApolloClient()
  const perPage = 24

  const [loading, setLoading] = useState<boolean>(true)
  const [posts, setPosts] = useState<Blog[]>([])
  const [pagination, setPagination] = useState<{ limit: number; totalHits: number; skip: number }>()

  const [currentPage, setCurrentPage] = useState<number>(() => {
    const queryPage = query.get('page')
    if (queryPage) return parseInt(queryPage)
    return 1
  })

  useEffect(() => {
    const loadPosts = async () => {
      setLoading(true)
      const results = await getInsights(apolloClient, {
        limit: perPage,
        offset: (currentPage - 1) * perPage,
        order: '-fields.originalPostDate'
      })
      setPosts(results.insights)
      setPagination({
        limit: results.limit,
        totalHits: results.count,
        skip: results.offset
      })
      setLoading(false)
    }
    loadPosts()
  }, [apolloClient, currentPage])

  const handlePageNavigate = (page: number) => {
    query.set('page', page.toString())
    navigate({ pathname: '/insights', search: query.toString() })
    setCurrentPage(page)
  }

  if (loading) return null

  return (
    <Container maxWidth="xl">
      <Header title="" />
      <Grid item xs={12} style={{ marginBottom: '3em' }}>
        <BlogList items={posts} />
      </Grid>
      <Box paddingBottom={4}>
        {pagination && <Pagination onPageNavigate={handlePageNavigate} page={currentPage} meta={pagination} />}
      </Box>
    </Container>
  )
}

export default Index
