import { Typography } from '@material-ui/core'
import { Modal } from '../../Modal/Modal'
import { ChannelScore } from '../../../declarations/ChannelScore'
import { Asset } from '../../../declarations/Asset'

type NotesProps = {
  channelScore: ChannelScore
  asset: Asset
  onClose: () => void
}

export const Notes = (props: NotesProps) => {
  return (
    <div style={{ position: 'relative' }}>
      <Modal
        bgColor={'rgba(255,255,255,0.85)'}
        backdrop={false}
        size={'md'}
        fullHeight={false}
        title={'Notes'}
        onClose={props.onClose}
        closeable
        open={props.asset !== undefined}
        rounded
        zIndex={1400} // bypass the backdrop zIndex when on Friction Scores Popover
      >
        {props.asset.narratives.map((narrative, index) => (
          <div key={index} style={{ paddingTop: '8px', paddingBottom: '8px' }}>
            <Typography variant={'body2'}>{narrative}</Typography>
          </div>
        ))}
      </Modal>
    </div>
  )
}
