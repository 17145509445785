import { FunctionComponent } from 'react'
import { Cycle } from '../../types/Industries'
import { useCriteria } from '../../contexts/Search/Criteria'
import { useSearchQuery } from '../../contexts/Search/Query'
import { Dropdown } from './Filter/Dropdown'
import { Group } from './Filter/Option/Group'
import { SearchKind } from '../../declarations/SearchCriteria'

const SUBTITLE_TEXT = 'Data as at end of:'

type CycleFilterProps = {
  onCycleChange?: (selectedCycle: Cycle) => void
}

export type CycleSelection = {
  id: number
  label: string
  date: string
}

export const CycleFilter: FunctionComponent<CycleFilterProps> = (props) => {
  const searchCriteria = useCriteria()
  const searchQuery = useSearchQuery()

  const authorizedIndustries = searchCriteria.state.industries

  /**
   *  Triggers when the user selects a cycle from the Widget dropdown menu
   * @param key The key of the selected cycle.
   * @param value The value of the selected cycle.
   * @param state The state of the selected cycle.
   * @returns void
   *
   */
  const handleSelectedChange = async <K extends keyof SearchKind>(key: K, value: number, state: boolean) => {
    // Find the selected cycle in the Industry selected earlier
    const selectedIndustry = authorizedIndustries.find(
      (v) => v.industryId === searchQuery.state.selectedIndustry.industryId
    )
    if (!selectedIndustry) return //Need to notifiy the user that something went wrong
    const newCycle = selectedIndustry.cycles.find((v) => v.cycleId === value)

    newCycle &&
      searchQuery.dispatch({
        type: 'SET_SELECTED_CYCLE',
        payload: {
          value: newCycle
        }
      })

    props.onCycleChange && props.onCycleChange(newCycle!)
  }

  if (Object.keys(searchQuery.state.selectedIndustry).length === 0) return <></>
  return (
    <Dropdown label={`${searchQuery.state.selectedCycle.cycleName}`} hideCounter count={1} total={1} disabled={false}>
      <Group
        subtitleText={`${SUBTITLE_TEXT}`}
        single
        hideSelectAll
        groupKey={'sectors'}
        onSelected={handleSelectedChange}
        criteria={searchQuery.state.selectedIndustry.cycles.map((value) => ({
          id: value.cycleId,
          name: value.cycleName
        }))}
        selected={[searchQuery.state.selectedCycle.cycleId]}
        data-cy={`sector-filter`}
      />
    </Dropdown>
  )
}
