import { FunctionComponent } from 'react'
import { FunctionView } from '../../FunctionView/FunctionView'
import { ChannelScore } from '../../../declarations/ChannelScore'

// Why do I have two of these? - to use the same thing again in comparison mode?
type FunctionsProps = {
  channelScores: ChannelScore[]
  sortedOnText?: string
  usesVoc: boolean
}

export const Functions: FunctionComponent<FunctionsProps> = (props) => {
  return <FunctionView channelScores={props.channelScores} sortedOnText={props.sortedOnText} usesVoc={props.usesVoc} />
}
