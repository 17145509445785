import { Box, Button, Container, Paper, Typography } from '@material-ui/core'
import { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'

export const Unauthorised: FunctionComponent = () => {
  const navigate = useNavigate()

  const handleRedirect = async () => {
    navigate('/resources')
  }

  return (
    <Container maxWidth={false} disableGutters={true} style={{ minHeight: 'calc(100vh - 150px)' }}>
      <Box maxWidth={'500px'} marginX={'auto'} marginTop={'50px'}>
        <Paper style={{ padding: '40px 30px' }}>
          <Box>
            <Typography variant={'h1'}>Unauthorised Access</Typography>
          </Box>

          <Box paddingTop={'10px'}>
            <Typography variant={'body1'}>
              Sorry, it would appear you do not have access to the requested report.
            </Typography>
            <Typography variant={'body1'} style={{ marginTop: '8px' }}>
              Please contact support at swiftassist@iskyresearch.com
            </Typography>
          </Box>

          <Box paddingTop={'20px'}>
            <Button
              disableElevation
              color={'default'}
              variant="outlined"
              style={{
                backgroundColor: '#E5126E',
                color: 'white',
                borderRadius: '6px',
                // textTransform: "capitalize",
                fontWeight: 'bold'
              }}
              onClick={handleRedirect}
            >
              Back to Reports
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  )
}
