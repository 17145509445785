import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@material-ui/core'
import { range } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { ChevronDown } from '../Common/icons'

/**
 * This is an arbetrary restriction of pages to be listed.
 * This was done because we had listing over 1k+ and the render
 * delay was looooooong. Two hundred seems ok.
 */
const MAX_LIST_SIZE = 200

const ButtonGroupStyles = styled(ButtonGroup)`
   && {
    background-color: white !important;
    border-radius: 20px;
    min-width: 0px;
    overflow: hidden;
    box-shadow: 0 1px 3px 0 rgba(65,69,88,0.2);
    padding-top: 6px;
    padding-bottom: 6px;
    transition: box-shadow .25s;
    color: #8B96A4 !important,
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1);
    &:hover {
      box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    }
  }
`

const ButtonStylesRight = styled(Button)`
    && {
      box-shadow: none;
      padding-top: 0;
      padding-bottom: 0;
      background-color: white !important;
      border-top-left-radius: 20px;
      color: #8B96A4,
      border-bottom-left-radius: 20px;
      &:hover {
          box-shadow: none;
      }
    }
`

const ButtonStylesLeft = styled(Button)`
    && {
      box-shadow: none;
      padding-top: 0;
      padding-bottom: 0;
      background-color: white !important;
      color: #8B96A4,
      &:hover {
          box-shadow: none;
      }
    }
`

type PageSelectorProps = {
  totalPages: number
  page: number
  onPageNavigate: (page: number) => void
}

export const PageSelector = (props: PageSelectorProps) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState(props.page)

  const handleClick = () => {
    //props.onPageNavigate(selectedIndex);
    document.getElementById('radartop')?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index)
    setOpen(false)
    props.onPageNavigate(index)
    document.getElementById('radartop')?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <ButtonGroupStyles variant="contained" ref={anchorRef} aria-label="split button">
          <ButtonStylesLeft onClick={handleClick}>{props.page}</ButtonStylesLeft>
          <ButtonStylesRight
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ChevronDown fill={'currentColor'} viewBox={'0 0 8 8'} style={{ width: '8px', height: '8px' }} />
          </ButtonStylesRight>
        </ButtonGroupStyles>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ left: '0', zIndex: 999 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper style={{ left: '0', maxHeight: '200px', overflowY: 'scroll' }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {range(1, props.totalPages < MAX_LIST_SIZE ? props.totalPages + 1 : MAX_LIST_SIZE).map(
                      (option, index) => (
                        <MenuItem
                          key={option}
                          disabled={option === selectedIndex}
                          selected={option === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, option)}
                        >
                          Page {option}
                        </MenuItem>
                      )
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  )
}
