import { Box, Container, Typography } from '@material-ui/core'
import { Page } from '../../components/Layout/Page'

export const Index = () => {
  return (
    <Page>
      <Container>
        <Box paddingY={4}>
          <Box paddingY={2}>
            <Typography variant={'h1'}>Radar Scoring</Typography>

            <Typography variant={'body1'} style={{ marginTop: '10px', fontSize: '18px' }}>
              There are two scoring systems deployed in the Radar tool that help users to compare digital channels, the
              functionality they offer and the value to the customer.
            </Typography>
          </Box>

          <Box paddingY={2}>
            <Typography variant={'h2'}>Utility Score:</Typography>

            <Typography variant={'body1'} style={{ marginTop: '10px', fontSize: '18px' }}>
              Weights all functionality equally, so no function is more or less valuable than its peers. Consequently,
              Utility Scores reflect the depth of functionality available in the channel.
            </Typography>
          </Box>

          <Box paddingY={2}>
            <Typography variant={'h2'}>VoC Score™:</Typography>

            <Typography variant={'body1'} style={{ marginTop: '10px', fontSize: '18px' }}>
              Applies a weight to each function to highlight those that customers deem more or less important to their
              experience. Consequently, the scoring of the VoC Score reflects channel performance against the customer
              expectation of functionality availability.
            </Typography>
          </Box>

          <Box paddingY={2}>
            <Typography variant={'body1'} style={{ marginTop: '10px', fontSize: '18px' }}>
              N.B. VoC Score weighting can be altered by use of the ‘VoC Setup’ tab, to represent the preferred
              demographic.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Page>
  )
}
