import { Auth } from 'aws-amplify'
import { AmplifyUser } from '../../hooks/amplify'
import {
  AmplifyNetworkError,
  AmplifyNotAuthorised,
  AmplifyPasswordResetRequired,
  AmplifySuccess,
  AmplifyUnknownError,
  AmplifyUserNotConfirmed,
  AmplifyUserNotFound
} from '../../types/Amplify'
import { CognitoError } from '../../types/Cognito'

export type SignInResult =
  | AmplifySuccess<{ user: AmplifyUser }>
  | AmplifyNotAuthorised
  | AmplifyUserNotFound
  | AmplifyUserNotConfirmed
  | AmplifyUnknownError
  | AmplifyNetworkError
  | AmplifyPasswordResetRequired

/**
 * Handles the sign in for the user via the amplify sdk.
 *
 * @param {string} username
 * @param {string} password
 *
 * @returns {Promise<SignInResult>}
 */
export const signIn = async (username: string, password: string): Promise<SignInResult> => {
  try {
    return {
      type: 'success',
      user: await Auth.signIn(username, password)
    }
  } catch (caught: any) {
    console.log('caught', caught)
    if ((caught as CognitoError).code === 'NetworkError') {
      return { type: 'network-error', error: 'Please check your internet connection' }
    }
    if ((caught as CognitoError).code === undefined) {
      return { type: 'unknown-error', exception: caught }
    }

    switch ((caught as CognitoError).code) {
      case 'NotAuthorizedException':
        return {
          type: 'not-authorized',
          error: caught.message
        }
      case 'UserNotConfirmedException':
        return {
          type: 'user-not-confirmed',
          error:
            'The account is not confirmed, please resend your verification code and follow the steps to verify your account.'
        }
      case 'PasswordResetRequiredException':
        return {
          type: 'password-reset-required',
          error: 'This account requires a password reset.'
        }
    }

    return { type: 'unknown-error', exception: caught }
  }
}
