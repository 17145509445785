import { ChannelScore } from '../../declarations/ChannelScore'
import { JourneyCriterion } from '../../declarations/Criterion'
import { getChannelJourneys } from '../../hooks/journeys'
import { uniqBy } from 'lodash'

/**
 * Handles transforming of data into a chart data series for Highcharts.
 *
 * @param {ChannelScore[]} channelScores
 * @param {JourneyCriterion[]} journeys
 * @param {'score' | 'cx_score'} type
 * @param {Record<string, string>} channelColours
 *
 * @returns {{ categories: string[]; series: Array<{ name: string; data: { y: number }[] }> }}
 */
export const withMultipleChartData = (
  channelScores: ChannelScore[],
  journeys: JourneyCriterion[],
  type: 'score' | 'cx_score',
  channelColours: Record<string, string>
): { categories: string[]; series: Array<{ name: string; data: { y: number }[] }> } => {
  const channelJourneys = channelScores.map((channelScore, index) => {
    return getChannelJourneys(channelScore, journeys)
  })

  const allJourneys: { id: number; key: string; name: string }[] = uniqBy(
    channelJourneys
      .reduce((a, b) => a.concat(b))
      .map((j) => {
        return {
          id: j.id,
          key: j.key,
          name: j.name ?? ''
        }
      }),
    'key'
  )

  const series = channelScores.map((channelScore, index) => {
    const data = allJourneys.map((j) => {
      const csj = channelJourneys[index].filter((i) => i.key === j.key)[0]

      if (csj) {
        return csj[type]
      } else {
        return 0
      }
    })

    return {
      key: channelScore.key,
      name: channelScore.providerName,
      data: data.map((d) => ({ y: d }))
    }
  })

  return {
    categories: allJourneys.map((j) => `${j.id}#${j.name}`),
    series: series.map((set, index: number) => {
      return {
        name: set.name,
        data: set.data,
        pointPlacement: 'on',
        color: channelColours[set.key],
        // key: dataset.meta.channelScore.key,
        marker: {
          symbol: 'circle'
        }
      }
    })
  }
}
