import { ApolloClient, gql } from '@apollo/client'
import { Blog } from '../../support/contentful/content-types'

type GetInsightsParameters = {
  order?: string
  limit?: number
  offset?: number
  ids?: string[]
  path?: string
}

/*
 * the structure of an insight is too complicated to pass across the GraphQl interface so each one is
 * returned from the server a JSON string which we parse after retrieval
 */
type GetInsightsServerResponse = {
  getInsights: {
    insights: string[]
    count: number
    offset: number
    limit: number
  }
}

export type GetInsightsResponse = {
  insights: Blog[]
  count: number
  offset: number
  limit: number
}

const query = gql`
  query insights($order: String, $offset: Int, $limit: Int, $ids: [String], $path: String) {
    getInsights(criteria: { order: $order, offset: $offset, limit: $limit, ids: $ids, path: $path }) {
      count
      limit
      offset
      insights
    }
  }
`

export const getInsights = async (
  client: ApolloClient<object>,
  variables: GetInsightsParameters
): Promise<GetInsightsResponse> => {
  try {
    const insightsResult = await client.query<GetInsightsServerResponse, GetInsightsParameters>({
      query,
      variables: variables
    })
    return {
      ...insightsResult.data.getInsights,
      insights: insightsResult.data.getInsights.insights.map((x) => JSON.parse(x))
    }
  } catch (caught) {
    console.error(caught)
    throw Error()
  }
}
