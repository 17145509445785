import { Auth } from '@aws-amplify/auth'
import {
  AmplifyLimitExceeded,
  AmplifyNetworkError,
  AmplifySuccess,
  AmplifyUnknownError,
  AmplifyUserNotConfirmed
} from '../../types/Amplify'
import { CognitoError } from '../../types/Cognito'

type ForgotPasswordResult =
  | AmplifySuccess
  | AmplifyLimitExceeded
  | AmplifyUserNotConfirmed
  | AmplifyNetworkError
  | AmplifyUnknownError

/**
 * Handle requesting a forgot password change via the amplify sdk.
 *
 * @param {string} username
 *
 * @returns {Promise<ForgotPasswordResult>}
 */
export const forgotPassword = async (username: string): Promise<ForgotPasswordResult> => {
  try {
    await Auth.forgotPassword(username)

    return { type: 'success' }
  } catch (caught) {
    if ((caught as CognitoError).code === 'NetworkError') {
      return { type: 'network-error', error: 'Please check your internet connection' }
    }
    if ((caught as CognitoError).code === undefined) {
      return { type: 'unknown-error', exception: caught }
    }

    switch ((caught as CognitoError).code) {
      case 'InvalidParameterException':
        // if (caught.message.startsWith('Cannot reset password for the user')) {
        //   return {
        //     type: 'user-not-confirmed',
        //     error:
        //       'The account is not confirmed, please resend your verification code and follow the steps to verify your account.'
        //   };
        // }
        return { type: 'unknown-error', exception: caught }
      case 'LimitExceededException':
        return {
          type: 'limit-exceeded',
          error: 'You have exceeded the number of attempts, please try again shortly.'
        }
    }

    return { type: 'unknown-error', exception: caught }
  }
}
