import { Box } from '@material-ui/core'
import React from 'react'

export interface NoResultsFoundProps {}

const NoResultsFound: React.FC<NoResultsFoundProps> = () => {
  return (
    <Box padding={4} textAlign="center" fontSize="20px" fontWeight="600">
      No Results Found.
    </Box>
  )
}

export default NoResultsFound
