import { Box, Divider, Typography } from '@material-ui/core'
import { AntSwitch } from '../../../Common/AntSwitch'
import styled from 'styled-components'

type ToggleProps = {
  checked: boolean
  handleChange: (state: boolean) => void
}

export const Toggle = (props: ToggleProps) => {
  return (
    <Box marginBottom={1}>
      <Box display={'flex'} justifyItems={'center'} alignItems={'center'} paddingY={1} style={{ paddingBottom: '0' }}>
        <AntSwitch checked={props.checked} onChange={(e) => props.handleChange(e.target.checked)} />
        <Typography
          variant={'body1'}
          style={{ marginLeft: '8px', fontSize: '12px', fontWeight: 'bold', color: '#647386' }}
        >
          Select All
        </Typography>
      </Box>

      <DividerStyles />
    </Box>
  )
}

const DividerStyles = styled(Divider)`
  && {
    margin-top: 16px !important;
    background-color: #d9dbe0 !important;
  }
`
