import { Box, Typography } from '@material-ui/core'
import { useEffect, useMemo, useState } from 'react'
import { useChannel } from '../../contexts/ChannelContext'
import { ExpandableProvider } from '../../contexts/ExpandableContext'
import { useJourneyView } from '../../hooks/journeys'
import { Loading } from '../Common/Loading'
import { Expandable } from '../Expandable/Expandable'
import { Assets } from './Assets'
import { Functions } from './Modal/Functions'
import { Notes } from './Modal/Notes'
import { ChannelScore } from '../../declarations/ChannelScore'
import { Asset } from '../../declarations/Asset'

type JourneyListProps = {
  channelScore: ChannelScore
}

export const JourneyList = (props: JourneyListProps) => {
  const { names, journeys, getJourneyAssets } = useJourneyView()
  const channel = useChannel()

  const [assets, setAssets] = useState<Record<string, Asset[]>>({})
  const [ready, setReady] = useState<boolean>(false)

  const [selected, setSelected] = useState<{ asset: Asset; view: 'notes' | 'functions' } | undefined>(undefined)

  const expanded = useMemo(() => {
    if (channel.state.default.expanded === undefined) {
      return names
    }

    return channel.state.default.expanded
  }, [names, channel.state.default.expanded])

  const onSelected = (asset: Asset, view: 'notes' | 'functions') => {
    setSelected({
      asset,
      view
    })
  }

  useEffect(() => {
    const loadJourneyAssets = async () => {
      const loadedAssets: Record<string, Asset[]> = {}
      for (const j of journeys) {
        loadedAssets[j.key] = await getJourneyAssets(props.channelScore.auditId, j.id)
      }
      setAssets(loadedAssets)
      setReady(true)
    }

    loadJourneyAssets()
  }, [getJourneyAssets, journeys, props.channelScore])

  if (!ready) {
    return <Loading.Box height={700} />
  }

  return (
    <>
      <ExpandableProvider
        state={{
          expanded,
          checked: []
        }}
        names={[]}
        checkable={[]}
      >
        <Box paddingY={1} paddingX={4}>
          <Box display={'flex'} justifyContent={'flex-end'} marginTop={'10px'}>
            <Box display={'flex'} alignItems={'center'}>
              <Expandable.GroupTrigger>
                <Typography style={{ marginLeft: '6px' }} variant={'h4'}>
                  Expand all
                </Typography>
              </Expandable.GroupTrigger>
            </Box>
            <Box display={'flex'} alignItems={'center'} marginLeft={'15px'}>
              <Expandable.GroupTrigger collapse>
                <Typography style={{ marginLeft: '6px' }} variant={'h4'}>
                  Collapse all
                </Typography>
              </Expandable.GroupTrigger>
            </Box>
          </Box>
          <Box bgcolor={'transparent'} borderRadius={'9px'} marginTop={'20px'}>
            {journeys.map((j, jIdx) => {
              const journeyAssets = assets[j.key]

              return (
                <Box key={jIdx} borderTop={'1px solid #D9DBE0'}>
                  <Box display={'flex'} padding={2}>
                    <Expandable.Trigger name={j.key}>
                      <Typography
                        variant={'h2'}
                        style={{
                          marginLeft: '10px',
                          marginRight: '10px'
                        }}
                      >
                        {j.name} ({journeyAssets.length})
                      </Typography>
                    </Expandable.Trigger>
                  </Box>

                  <Expandable.Group name={j.key}>
                    <Box paddingY={1}>
                      <Assets onSelected={onSelected} assets={journeyAssets} />
                    </Box>
                  </Expandable.Group>
                </Box>
              )
            })}
          </Box>
        </Box>
      </ExpandableProvider>

      {selected && selected.view === 'functions' && (
        <Functions channelScore={props.channelScore} asset={selected.asset} onClose={() => setSelected(undefined)} />
      )}

      {selected && selected.view === 'notes' && (
        <Notes channelScore={props.channelScore} asset={selected.asset} onClose={() => setSelected(undefined)} />
      )}
    </>
  )
}
