/**
 * This file should be considered legacy, it was the original structure for queries that we have
 * slowly migrated away from for better typed responses and inputs.
 *
 * This has most of the queries that can be used against the apis, most of these are poorly typed.
 *
 * There are a few queries left behind that have not been migrated to the new structure yet,
 * this could be because they have not been touched in a while or not required to move yet.
 */

import { ApolloClient, gql } from '@apollo/client'
import { Asset } from '../../declarations/Asset'

const ASSET_SEARCH = gql`
  query journeyAssets($auditId: Int!, $journey: Int!) {
    getAssets(auditId: $auditId, journey: $journey) {
      count
      assets {
        provider
        journey
        segment
        market
        questions
        narratives
        location
        type
        channel
        sector
        created
      }
    }
  }
`

export const ALL_SEARCH_CRITERIA = gql`
  query allSearchCriteria {
    getSearchCriteria {
      channels {
        id
        name
      }
      markets {
        id
        name
      }
      providers {
        id
        name
        image
      }
      segments {
        id
        name
      }
    }
  }
`

export const GET_AUDIT_HISTORY = gql`
  query getAuditHistory {
    getAuditHistory(limit: 30) {
      audits {
        channel
        market
        provider
        sector
        segment
        audit_date
      }
    }
  }
`

export const GET_FRICTION_SCORES = gql`
  query getFrictionScores($auditIds: [Int]) {
    getFrictionData(auditIds: $auditIds) {
      friction_scores {
        question
        clicks
        swipes
        wipes
        data_input
        screens
        security_biometric
        security_remember
        security_get
        security_score
        friction_score
      }
      auditId
    }
  }
`

const GET_RESOURCES = gql`
  query allResources($limit: Int, $offset: Int) {
    getResources(limit: $limit, offset: $offset) {
      count
      limit
      offset
      resources {
        content_type
        create_date
        description
        expiry_date
        file_size
        id
        name
      }
    }
  }
`

export const GET_RESOURCE_DOWNLOAD_LINK = gql`
  query getResourceDownload($resourceId: Int) {
    getResourceDownloadLink(resourceId: $resourceId)
  }
`

/**
 *
 * @param {ApolloClient<any>} client
 * @param variables
 */
export const getFrictionScores = async (client: ApolloClient<any>, variables: { auditIds: number[] }) => {
  const res = await client.query({ query: GET_FRICTION_SCORES, variables })
  return res.data.getFrictionData
}

/**
 * Handles fetching the audit history from the backend api.
 *
 * @param {ApolloClient<any>} client
 */
export const getAuditHistory = async (client: ApolloClient<any>) => {
  const res = await client.query({ query: GET_AUDIT_HISTORY })
  return res.data.getAuditHistory
}

/**
 * Handles fetching the assets from the backend api.
 *
 * @param {ApolloClient<any>} client
 * @param {any} variables
 */
export const getAssets = async (client: any, variables: any): Promise<Asset[]> => {
  const res = await client.query({
    query: ASSET_SEARCH,
    variables
  })

  return res.data.getAssets.assets
}

/**
 * Handles fetching the resources from the backend api.
 *
 * @param {ApolloClient<any>} client
 * @param {{ limit: number; offset: number }} variables
 */
export const getResources = async (client: any, variables?: { limit: number; offset: number }) => {
  const res = await client.query({ query: GET_RESOURCES, variables })

  return res.data.getResources.resources
}

/**
 * Handles fetching the resource download link from the backend api.
 *
 * @param {ApolloClient<any>} client
 * @param {{ resourceId: number }} variables
 */
export const getResourceDownloadLink = async (client: ApolloClient<any>, variables: { resourceId: number }) => {
  try {
    const res = await client.query({
      query: GET_RESOURCE_DOWNLOAD_LINK,
      variables
    })

    return res.data.getResourceDownloadLink
  } catch (caught) {}

  return null
}
