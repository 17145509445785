import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  header: {
    color: '#001433',
    fontWeight: 'bold',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      height: '20px',
      right: '0',
      width: '1px',
      backgroundColor: 'rgba(224, 224, 224, 1)',
      top: '50%',
      transform: 'translateY(-50%)'
    },
    '&:last-child': {
      '&:after': {
        display: 'none'
      }
    }
  }
})
