import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { LockedIcon, UnlockedIcon } from './icons'

type SecureChannelBadgeProps = {
  secureChannel: number
  withText?: boolean
}

type SecureChannelBadgeType = {
  [key: number]: { title: string; icon: React.ReactNode }
}

const SecureChannelBadges: SecureChannelBadgeType = {
  1: { title: 'Public', icon: <UnlockedIcon /> },
  2: { title: 'Authenticated', icon: <LockedIcon /> },
  3: {
    title: 'Either',
    icon: (
      <>
        <LockedIcon />
        <UnlockedIcon />
      </>
    )
  }
}

export const SecureChannelBadge = (props: SecureChannelBadgeProps) => {
  const { secureChannel, withText = true } = props

  if (!secureChannel) return null

  return (
    <Box
      paddingX={1}
      paddingY={0.5}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      style={{
        backgroundColor: '#F6F8FA',
        borderRadius: withText ? '20px' : '100%'
      }}
    >
      {SecureChannelBadges[secureChannel].icon}

      {
        <Typography style={{ marginLeft: '4px', fontSize: '10px' }} variant={'body1'}>
          {SecureChannelBadges[secureChannel].title}
        </Typography>
      }
    </Box>
  )
}
