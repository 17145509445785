import { Container, Divider } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { Header } from '../../components/Blog/Header'
import SkeletonView from '../../components/Common/SkeletonView'
import { Page } from '../../components/Layout/Page'
import { Pagination } from '../../components/Pagination/Pagination'
import { useQueryParams } from '../../hooks/query'
import { Blog } from '../../support/contentful/content-types'
import { BlogList } from '../../components/Blog/BlogList'
import { useApolloClient } from '@apollo/client'
import { getInsights } from '../../services/api/get-insights'
import { searchInsights } from '../../services/api/search-insights'

const MAX_PAGE_ITEMS = 12

export const Search = () => {
  const queryParams = useQueryParams()
  const apolloClient = useApolloClient()

  const [term, setTerm] = useState<string | null>(() => {
    return queryParams.get('term')
  })

  const [currentPage, setCurrentPage] = useState<number>(() => {
    const queryPage = queryParams.get('page')

    if (queryPage) return parseInt(queryPage)

    return 1
  })

  const [totalResultPages, setTotalResultPages] = useState<number>(0)

  const [loading, setLoading] = useState<boolean>(true)
  const [posts, setPosts] = useState<Blog[]>([])

  function getPageIndices(page: number): { startIndex: number; endIndex: number } {
    if (isNaN(Number(page)) || page < 1) return { startIndex: 0, endIndex: 0 }

    const startIndex = (page - 1) * MAX_PAGE_ITEMS
    const endIndex = startIndex + MAX_PAGE_ITEMS
    return { startIndex, endIndex }
  }

  /**
   * These are the posts we want to show on the UI. Usually a subsection of the state variable posts
   */
  const [currentPosts, setCurrentPosts] = useState<Blog[]>([])

  /**
   * Updates the `currentPosts` state with posts for the current page.
   *
   * This effect recalculates the slice of posts to be displayed whenever
   * the `currentPage` changes or the `posts` array is updated. It ensures
   * that the component only renders posts relevant to the current page,
   * aiding in pagination functionality.
   *
   * The `getPageIndices` function is used to compute the start and end indices
   * for slicing the `posts` array based on the current page number.
   *
   * @effect Recalculates visible posts slice on `currentPage` or `posts` changes.
   */
  useEffect(() => {
    const { startIndex, endIndex } = getPageIndices(currentPage)
    setCurrentPosts(posts.slice(startIndex, endIndex))
  }, [currentPage, posts])

  /**
   * Performs a search operation based on the provided search term and updates the blog posts state.
   * If the search term is empty, the operation is skipped.
   *
   * Steps:
   * 1. Sets loading state to true at the start of the search operation.
   * 2. Uses the searchInsights to search for the term and return a list of IDs.
   * 3. Converts the search results into a comma-separated string of IDs.
   * 4. Calls `loadBlogPosts` with the resulting IDs to fetch the corresponding blog posts.
   * 5. On successful fetch, logs the fetched posts, calculates the total number of result pages, updates the posts state, and sets loading to false.
   *
   * Dependencies:
   * - `term`: The search term used for querying. The effect runs whenever `term` changes.
   */
  useEffect(() => {
    const loadResults = async () => {
      if (!term) return
      setLoading(true)
      const insightIds = await searchInsights(apolloClient, { term })
      const { insights } = await getInsights(apolloClient, {
        limit: 150,
        offset: 0,
        order: '-sys.createdAt,-fields.originalPostDate',
        ids: insightIds
      })
      setTotalResultPages(Math.ceil(insights.length / MAX_PAGE_ITEMS))
      setPosts(insights)
      setLoading(false)
    }
    loadResults()
  }, [apolloClient, term])

  /**
   * Synchronizes the search term in the app's state with the query parameter in the URL.
   * If the term in the URL query parameter is the same as the current state, no action is taken.
   * Otherwise, it updates the term in the state and resets the current page to 1.
   *
   * Dependencies:
   * - `queryParams`: A URLSearchParams object representing the current URL query parameters.
   *   The effect runs whenever `queryParams` changes.
   */
  useEffect(() => {
    if (queryParams.get('term') === term) return

    setTerm(queryParams.get('term'))
    setCurrentPage(1)
  }, [queryParams, term])

  if (loading)
    return (
      <>
        <SkeletonView />
      </>
    )

  return (
    <Page>
      <Container maxWidth="xl">
        {term && <Header title={`Search results for '${term}'`} term={term} />}

        <Divider
          style={{
            backgroundColor: '#D9DBE0'
          }}
        />

        <Pagination
          onPageNavigate={(page) => setCurrentPage(page)}
          page={currentPage}
          meta={{ limit: 20, skip: 20 * currentPage, totalPages: totalResultPages }}
        />

        <BlogList items={currentPosts} />

        <Pagination
          onPageNavigate={(page) => setCurrentPage(page)}
          page={currentPage}
          meta={{ limit: 20, skip: 20 * currentPage, totalPages: totalResultPages }}
        />
      </Container>
    </Page>
  )
}
