import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Switch } from '@material-ui/core'

export const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex'
    },
    switchBase: {
      padding: 1,
      color: 'white',
      '&$checked': {
        transform: 'translateX(14px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#24AB3B'
        }
      }
    },
    thumb: {
      width: 13,
      height: 13,
      boxShadow: 'none'
    },
    track: {
      borderRadius: 8,
      opacity: 1,
      backgroundColor: '#ECEDEF'
    },
    checked: {}
  })
)(Switch)
