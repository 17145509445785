import { useState } from 'react'
import {
  IconButton,
  Tooltip,
  Typography,
  Grow,
  Paper,
  makeStyles,
  Badge,
  createTheme,
  ThemeProvider
} from '@material-ui/core'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'

interface TagItemProps {
  text: string
  onClick?: () => void // Optional onClick handler
}

/**
 * Renders a tag item with a clickable paper component.
 *
 * @param props - The props object containing the text and onClick handler.
 * @param props.text - The text to display in the tag item.
 * @param props.onClick - The optional onClick handler.
 * @return {JSX.Element} The rendered tag item component.
 */
function TagItem({ text, onClick }: TagItemProps): JSX.Element {
  return (
    <Paper
      variant="outlined"
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '4px 8px',
        margin: 4,
        borderRadius: 20,
        cursor: 'pointer'
      }}
    >
      <LocalOfferIcon style={{ marginRight: 5 }} />
      <Typography variant="body2">{text}</Typography>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: 'transparent' // Remove default background
  },
  arrow: {
    backgroundColor: 'linear-gradient(to bottom, #007BFF, #FFFFFF)' // Gradient for the arrow
  },
  tooltipPlacementBottom: {
    marginTop: theme.spacing(1), // Adjust spacing if needed
    '& .MuiTooltip-tooltip': {
      background: 'linear-gradient(to bottom, #007BFF, #FFFFFF)', // Blue to white gradient
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius
    }
  },
  badge: {
    right: 12, // Adjust right positioning
    top: 12, // Adjust top positioning
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      transform: 'scale(0.2)', // Adjust scaling factor for size
      transformOrigin: '100% 0%' // Set the origin for scaling
    }
  }
}))

const theme = createTheme({
  overrides: {
    MuiBadge: {
      badge: {
        fontSize: 10, // Adjust font size for smaller badge
        height: 16, // Adjust height for smaller badge
        minWidth: 16 // Adjust minWidth for smaller badge
      },
      colorSecondary: {
        // Target the "secondary" color variant
        backgroundColor: '#616161', // Change the secondary color to your gray
        color: 'white' // Adjust text color for contrast
      }
    }
  }
})

/**
 * Renders a stack of tags with a tooltip that displays all tags when clicked.
 *
 * @param props - The props object containing the tags array.
 * @param props.tags - The array of tags to display. Each tag is an object with a text property
 *   and an onClick function.
 * @returns The rendered TagStack component.
 */
function TagStack(props: { tags: Array<{ text: string; onClick: () => void }> }): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles() // Get styles

  return (
    <ThemeProvider theme={theme}>
      <Tooltip
        title={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {props.tags.map((tag, index) => (
              <Grow in={isOpen} timeout={100 + index * 50} key={index}>
                <TagItem text={tag.text} onClick={tag.onClick} />
              </Grow>
            ))}
          </div>
        }
        interactive
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        classes={{
          tooltip: classes.tooltip,
          arrow: classes.arrow,
          tooltipPlacementBottom: classes.tooltipPlacementBottom
        }}
        PopperProps={{
          disablePortal: true // Prevent portal creation
        }}
      >
        <Badge badgeContent={props.tags.length} color="secondary" classes={{ badge: classes.badge }}>
          <IconButton aria-label="tags">
            <LocalOfferIcon />
          </IconButton>
        </Badge>
      </Tooltip>
    </ThemeProvider>
  )
}

export default TagStack
