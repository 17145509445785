import { JourneyCompare } from '../../JourneyView/JourneyCompare'
import { ChannelScore } from '../../../declarations/ChannelScore'

type JourneysProps = {
  channelScores: ChannelScore[]
  usesVoc: boolean
}

export const Journeys = (props: JourneysProps) => {
  return <JourneyCompare channelScores={props.channelScores} usesVoc={props.usesVoc} />
}
