import { Box, Button, Container, Paper, TextField, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { ChangeEvent, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ErrorBox } from '../../components/Auth/ErrorBox'
import { Page } from '../../components/Layout/Page'
import { confirmSignUp } from '../../support/amplify/confirm-sign-up'
import { UNEXPECTED_ERROR_MESSAGE } from '../../helpers/messages'

export const Confirm = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [error, setError] = useState<string | undefined>(undefined)
  const [input, setInput] = useState({
    username: location.state?.username || '',
    code: ''
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const form = {
      ...input,
      [event.target.name]: event.target.value
    }

    setInput(form)
  }

  const handleSubmit = async () => {
    const confirmSignUpResult = await confirmSignUp(input.username, input.code)

    switch (confirmSignUpResult.type) {
      case 'success':
        await navigate('/login')
        break
      case 'code-expired':
      case 'code-mismatch':
      case 'user-not-found':
        setError(confirmSignUpResult.error)
        break
      case 'unknown-error':
        setError(UNEXPECTED_ERROR_MESSAGE)
        break
      default:
        setError(UNEXPECTED_ERROR_MESSAGE)
    }
  }

  return (
    <Page>
      <Container>
        <Box marginTop={4}>
          <Button
            variant="contained"
            color="default"
            style={{
              backgroundColor: 'white',
              fontSize: '14px',
              borderRadius: '20px',
              color: '#647386'
            }}
            startIcon={<ArrowBack />}
            onClick={() => navigate('/register')}
          >
            Back
          </Button>
        </Box>
        <Container maxWidth={'sm'}>
          <Box display={'flex'} flexDirection={'column'} maxWidth={'md'}>
            <Box marginBottom={4}>
              <Paper>
                <Box padding={4}>
                  <Typography variant={'h1'}>Verify Account</Typography>
                  {error && <ErrorBox message={error} />}

                  <Box marginY={2}>
                    <Typography variant={'body1'}>
                      You should have received a new confirmation code in your email, if you did not receive please{' '}
                      <a href={'https://iskyresearch.com/#contactArea'} style={{ color: '#001433' }}>
                        contact us
                      </a>
                    </Typography>
                  </Box>

                  <Box marginY={2}>
                    <Typography
                      style={{
                        fontSize: '14px',
                        color: '#647386',
                        fontWeight: 600
                      }}
                      variant={'subtitle2'}
                    >
                      Email Address
                    </Typography>
                    <TextField
                      style={{
                        marginTop: '4px'
                      }}
                      onChange={handleChange}
                      fullWidth={true}
                      size={'small'}
                      variant={'outlined'}
                      required={true}
                      type={'email'}
                      name={'username'}
                      id="my-input"
                      aria-describedby="my-helper-text"
                      value={input.username}
                    />
                  </Box>
                  <Box marginY={2}>
                    <Typography
                      style={{
                        fontSize: '14px',
                        color: '#647386',
                        fontWeight: 600
                      }}
                      variant={'subtitle2'}
                    >
                      Verification Code
                    </Typography>
                    <TextField
                      style={{
                        marginTop: '4px'
                      }}
                      onChange={handleChange}
                      fullWidth={true}
                      size={'small'}
                      variant={'outlined'}
                      required={true}
                      type={'text'}
                      name={'code'}
                      id="code"
                      aria-describedby="your verification code"
                      autoComplete={'off'}
                    />
                  </Box>
                  <Box marginY={2}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                      <Button color={'secondary'} variant={'contained'} onClick={handleSubmit}>
                        Confirm Account
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Container>
      </Container>
    </Page>
  )
}
export default Confirm
