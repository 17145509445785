import { Box, Grid, Paper } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import { useCallback, useMemo, useState } from 'react'
import { useCompareChannel } from '../../../contexts/CompareChannelContext'
import { useComparison } from '../../../contexts/ComparisonContext'
import { useSearchQuery } from '../../../contexts/Search/Query'
import { useCharts } from '../../../hooks/charts'
import chartColours from '../../../support/charts/colors'
import colors from '../../../support/theme/colors'
import { TabChannelView } from '../../../types/Channel'
import {
  BusinessBanking2021Sector,
  BusinessBanking2023Sector,
  LifeInsurance2021Sector,
  LifeInsurance2023Sector,
  PersonalBanking2021Sector,
  PersonalBanking2023Sector
} from '../../../types/Sectors'
import { ChannelBadge } from '../../Common/ChannelBadge'
import { GreenCheckbox } from '../../Common/GreenCheckbox'
import { SegmentBadge, SegmentBadgeBusinessAndCorporateSegment } from '../../Common/SegmentBadge'
import { ExportTrigger } from '../../Export/ExportTrigger'
import RadarChart from '../../RadarChart'
import ViewSwitch from '../../ViewSwitch'
import { ViewSwitchOption } from '../../ViewSwitch/ViewSwitch'
import { LifeInsurance2021Segment, Personal2021Segment } from '../../../types/Segments'
import { ChannelScore } from '../../../declarations/ChannelScore'

type ChannelsProps = {
  channelScores: ChannelScore[]
  usesVoc: boolean
}

export const Channels = (props: ChannelsProps) => {
  const comparison = useComparison()
  const compareChannel = useCompareChannel()

  const [hidden, setHidden] = useState<string[]>([])
  const [viewState, setViewState] = useState<'cx_score' | 'score'>(props.usesVoc ? 'cx_score' : 'score')

  const vocSwitchOption = props.usesVoc
    ? [
        {
          label: 'VoC Score',
          onClick: () => setViewState('cx_score'),
          key: 'cx_score',
          selected: viewState === 'cx_score'
        }
      ]
    : []
  const viewSwitchOptions: ViewSwitchOption[] = [
    ...vocSwitchOption,
    {
      label: 'Utility Score',
      onClick: () => setViewState('score'),
      key: 'score',
      selected: viewState === 'score'
    }
  ]

  const filterChart = (e: any, key: string) => {
    const existing = Array.from(hidden)
    const value = e.target.checked

    if (value) {
      const index = existing.findIndex((v) => v === key)

      if (index >= 0) {
        existing.splice(index, 1)
      }
    } else {
      existing.push(key)
    }

    setHidden(existing)
  }

  const removeFromComparison = (channelScore: ChannelScore) => {
    comparison.dispatch({
      type: 'TOGGLE_COMPARISON_ITEM_IN_STATE',
      payload: { item: channelScore, view: TabChannelView }
    })

    compareChannel.dispatch({
      type: 'SET_COMPARE_CHANNEL_SCORES_MODAL_STATE',
      payload: { channelScores: props.channelScores.filter((channel) => channel.key !== channelScore.key) }
    })
  }

  const chartChannels = useMemo(
    () => props.channelScores.filter((channel) => !hidden.includes(channel.key)),
    [props.channelScores, hidden]
  )

  const chartChannelColours = useCallback(() => {
    const colours: Record<string, string> = {}

    props.channelScores.forEach((channel, i) => (colours[channel.key] = chartColours[i]))

    return colours
  }, [props.channelScores])

  if (chartChannels.length < 1) return null

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <ViewSwitch options={viewSwitchOptions} />
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="end" alignItems="center" padding={3}>
            <ExportTrigger tab={TabChannelView} selected={[viewState]} />
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Legend
            channelColours={chartChannelColours()}
            channelScores={props.channelScores}
            hidden={hidden}
            filterChart={filterChart}
            removeFromComparison={removeFromComparison}
          />
        </Grid>
        <Grid item xs={9}>
          <Chart channelColours={chartChannelColours()} channelScores={chartChannels} viewState={viewState} />
        </Grid>
      </Grid>
    </Box>
  )
}

type LegendProps = {
  channelColours: Record<string, string>
  channelScores: ChannelScore[]
  hidden: string[]
  filterChart: Function
  removeFromComparison: Function
}

const Legend = (props: LegendProps) => {
  return (
    <>
      {props.channelScores.map((item: ChannelScore, index: number) => {
        return (
          <Box marginBottom={2} key={item.key}>
            <Paper>
              <Box padding="17px 15px 15px">
                <Box display="flex">
                  <Box>
                    <GreenCheckbox
                      style={{ paddingTop: '0px', marginTop: '-1px', paddingLeft: '0px', marginLeft: '-2px' }}
                      checked={!props.hidden.includes(item.key)}
                      onClick={(e) => props.filterChart(e, item.key)}
                    />
                  </Box>
                  <Box display="flex" justifyContent="space-between" width="100%" flexWrap="wrap">
                    <Box>
                      <Box fontSize="14px" lineHeight="18px" fontWeight="bold" color={colors.main}>
                        {item.providerName}
                      </Box>
                    </Box>
                    <Box display="flex">
                      <FiberManualRecord
                        style={{
                          color: props.channelColours[item.key]
                        }}
                      />
                    </Box>
                    <Box marginTop="10px" width="100%">
                      <SegmentBadge segment={item.segment} segmentName={item.segmentName} />
                      <ChannelBadge
                        version={item.channel_version}
                        channel={item.channel}
                        channelName={item.channelName}
                        providerName={item.providerName}
                        auditDate={item.audit_date}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        )
      })}
    </>
  )
}

type ChartProps = {
  channelColours: Record<string, string>
  channelScores: ChannelScore[]
  viewState: 'cx_score' | 'score'
}

const Chart = (props: ChartProps) => {
  const { multiple } = useCharts()
  const searchQuery = useSearchQuery()
  const currentSector = searchQuery.state.selectedCycle.linkedSector

  const chartDataV2 = useMemo(
    () => multiple(props.channelScores, props.viewState, props.channelColours),
    [props.channelScores, props.viewState, props.channelColours, multiple]
  )

  return (
    <Paper>
      <Box padding={4}>
        {chartDataV2 && (
          <Box height="600px">
            <RadarChart
              showPercentages={true}
              advanced={props.viewState === 'cx_score'}
              height={500}
              chartData={chartDataV2}
            />
          </Box>
        )}

        <Box>
          {props.viewState === 'cx_score' && (
            <>
              {(currentSector === BusinessBanking2021Sector || currentSector === BusinessBanking2023Sector) && (
                <SegmentBadge
                  segment={SegmentBadgeBusinessAndCorporateSegment}
                  segmentName={'Business & Corporate weightings'}
                />
              )}
              {(currentSector === PersonalBanking2021Sector || currentSector === PersonalBanking2023Sector) && (
                <SegmentBadge segment={Personal2021Segment} segmentName={'Personal weightings'} />
              )}
              {(currentSector === LifeInsurance2021Sector || currentSector === LifeInsurance2023Sector) && (
                <SegmentBadge segment={LifeInsurance2021Segment} segmentName={'Life Insurance weightings'} />
              )}
            </>
          )}
        </Box>
      </Box>
    </Paper>
  )
}
