import { createContext, PropsWithChildren, useContext, useReducer } from 'react'

type ToggleDropdownOpenPayload = {
  value: boolean
}

type ToggleDropdown = { type: 'TOGGLE_DROPDOWN_OPEN_STATE'; payload: ToggleDropdownOpenPayload }

type Action = ToggleDropdown

type Dispatch = (action: Action) => void

type State = {
  open: boolean
}

type DropdownContextType = { state: State; dispatch: Dispatch } | undefined
const DropdownContext = createContext<DropdownContextType>(undefined)

const toggleDropdown = (state: State, action: ToggleDropdown): State => {
  return {
    ...state,
    open: action.payload.value
  }
}

const reducer = (state: State, action: Action) => {
  if (action.type === 'TOGGLE_DROPDOWN_OPEN_STATE') {
    return toggleDropdown(state, action)
  }

  throw new Error(`Unhandled action type in 'DropdownContext'`)
}

const initialState = {
  open: false
}

type DropdownProviderProps = {}

export const DropdownProvider = (props: PropsWithChildren<DropdownProviderProps>) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <DropdownContext.Provider value={{ state, dispatch }}>{props.children}</DropdownContext.Provider>
}

export const useDropdown = () => {
  const context = useContext(DropdownContext)

  if (context === undefined) {
    throw new Error('useDropdown must be used within a DropdownProvider')
  }

  return context
}
