import { Box, makeStyles, Paper, Radio, Typography } from '@material-ui/core'
import PlayArrow from '@material-ui/icons/PlayArrow'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import CategoryTab from '../../components/Common/CategoryTab'
import Score from '../../components/Score'
import { BodyTextSmall2, TitleText } from '../../components/Typography/Typography'
import { useComparison } from '../../contexts/ComparisonContext'
import { useSearchResults } from '../../contexts/Search/Results'
import isVideo from '../../helpers/isVideo'
import getFormattedDate from '../../support/date/getFormattedDate'
import { TabJourneyView } from '../../types/Channel'
import { ChannelBadge } from '../Common/ChannelBadge'
import { CheckedIcon, UncheckedIcon } from '../Common/icons'
import { SegmentBadge } from '../Common/SegmentBadge'
import { StackRaw } from '../../declarations/StackRaw'

export interface StackItemProps {
  stack: StackRaw
  usesVoc: boolean
}

const StackItemPaper = styled(Paper)`
  && {
    height: 100%;
    position: relative;
    &:hover {
      cursor: pointer;
    }
  }
`

const ScoreBox = styled(Box)`
  && {
    width: 60px;
    height: 60px;
  }
`

const useStyles = makeStyles(() => ({
  radio: {
    paddingTop: '2px',
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  }
}))

export const StackItem: FunctionComponent<StackItemProps> = ({ stack, usesVoc }) => {
  const comparison = useComparison()
  const searchResults = useSearchResults()

  const selected = comparison.state[TabJourneyView].filter((c) => {
    if (c.stack !== undefined) {
      return c.stack.key === stack.key
    }

    return false
  })

  const isChecked = selected.length > 0
  const disabled = !isChecked && comparison.state[TabJourneyView].length === 10

  const toggleComparison = (e: any) => {
    e.stopPropagation()

    const score = searchResults.state.channels.find((score) => {
      return score.segment === stack.segment && score.channel === stack.channel && score.provider === stack.provider
    })

    if (score) {
      comparison.dispatch({
        type: 'TOGGLE_COMPARISON_ITEM_IN_STATE',
        payload: { item: score, stack, view: TabJourneyView }
      })
    }
  }

  const classes = useStyles()

  return (
    <StackItemPaper
      style={{
        borderWidth: '3px',
        borderStyle: 'solid',
        borderColor: `${isChecked ? '#001433' : 'transparent'}`,
        borderRadius: '12px'
      }}
    >
      <Box padding={2}>
        <Box display="flex">
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box display="flex">
              <Box>
                <Radio
                  style={{
                    paddingLeft: 0
                  }}
                  checked={isChecked}
                  onClick={toggleComparison}
                  classes={{ root: classes.radio }}
                  disabled={disabled}
                  disableRipple={true}
                  inputProps={{ 'aria-label': stack.providerName }}
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                />
              </Box>
              <Box>
                <TitleText style={{ color: '#001433' }}>{stack.providerName}</TitleText>
                <Typography style={{ fontSize: '12px', lineHeight: '18px', color: '#001433', whiteSpace: 'nowrap' }}>
                  {getFormattedDate(stack.audit_date)}
                </Typography>
              </Box>
            </Box>
            {stack.providerLogo && <img style={{ width: '100px' }} src={stack.providerLogo} alt="Provider" />}
          </Box>
        </Box>
        <Box marginTop={2} display="flex" justifyContent="space-between">
          <Box>
            <ChannelBadge
              channel={stack.channel}
              channelName={stack.channelName}
              providerName={stack.providerName}
              auditDate={stack.audit_date}
            />
            <SegmentBadge segment={stack.segment} segmentName={stack.segmentName} />
          </Box>
          <Box display={'flex'} alignItems={'center'}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              style={{ backgroundColor: '#F6F8FA', borderRadius: '100%', width: '18px', height: '18px' }}
            >
              {/* {stack.secure ? <LockedIcon /> : <UnlockedIcon />} */}
            </Box>
            <CategoryTab
              marginLeft={1}
              style={{ whiteSpace: 'nowrap' }}
              border="solid 1px #D9DBE0"
              padding="6px 10px!important"
            >
              {stack.journeyName}
            </CategoryTab>
          </Box>
        </Box>
        <Box bgcolor="#001433" padding={4} marginTop={2} marginLeft="-19px" marginRight="-19px">
          <Box height="180px" overflow="hidden">
            {stack.asset_locations && (
              <>
                {isVideo(stack.asset_locations[0]) ? (
                  <Box position="relative">
                    <Box position="absolute" top="30px" bottom="0" width="100%" textAlign="center">
                      <PlayArrow color="secondary" style={{ fontSize: '100px' }} />
                    </Box>
                    <video
                      src={stack.asset_locations[0]}
                      style={{
                        width: '100%',
                        height: 'auto'
                      }}
                    ></video>
                  </Box>
                ) : (
                  <img
                    style={{
                      width: '100%',
                      height: 'auto'
                    }}
                    alt="stack"
                    src={stack.asset_locations[0]}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-around" marginTop={2} marginBottom="-9px" width="100%">
          {usesVoc && (
            <div style={{ width: '100%' }}>
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <BodyTextSmall2 align="center" style={{ marginBottom: 9 }}>
                  VoC Score
                </BodyTextSmall2>
                <ScoreBox>
                  <Score score={stack.cx_score} />
                </ScoreBox>
              </Box>
            </div>
          )}
          <div style={{ width: '100%', borderLeft: '1px solid #D9DBE0' }}>
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
              <BodyTextSmall2 align="center" style={{ marginBottom: 9 }}>
                Utility Score
              </BodyTextSmall2>
              <ScoreBox>
                <Score score={stack.score} />
              </ScoreBox>
            </Box>
          </div>
        </Box>
      </Box>
    </StackItemPaper>
  )
}

export default StackItem
