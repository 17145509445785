import { FunctionComponent } from 'react'
import { ChannelScore } from '../../../declarations/ChannelScore'
import { RankView } from '../../../components/RankView/RankView'

type RankProps = {
  channelScores: ChannelScore[]
  sortedOnText?: string
  usesVoc: boolean
}

export const Rank: FunctionComponent<RankProps> = (props) => {
  return <RankView comparable={true} channelScores={props.channelScores} sortedOnText={props.sortedOnText} />
}
