import { FunctionComponent, useMemo, useRef, useState } from 'react'
import { useCompareChannel } from '../../contexts/CompareChannelContext'
import { useFunctions } from '../../hooks/functions'
import { useApolloClient } from '@apollo/client'
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes'
import { getFrictionScores } from '../../support/graphql/queries'
import { exportFunctions } from '../../support/export/functions'
import { CSVLink } from 'react-csv'
import { Button } from '@material-ui/core'
import { ChannelTabs } from '../../types/Channel'
import { exportChannels } from '../../support/export/channels'
import { FrictionData } from '../../declarations/FrictionScore'

type ExportButtonProps = {
  onExported: () => void
  tab: ChannelTabs
  selected: string[]
  filename: string
  usesVoc: boolean
}

export const ExportButton: FunctionComponent<ExportButtonProps> = ({
  onExported,
  tab,
  selected,
  filename,
  usesVoc
}) => {
  const [isExporting, setIsExporting] = useState<boolean>(false)

  const compareChannel = useCompareChannel()
  const downloadRef = useRef(null)
  const { journeys, transformChannels } = useFunctions()
  const client = useApolloClient()
  const providers = useMemo(
    () => transformChannels(compareChannel.state.channelScores ?? []),
    [compareChannel.state.channelScores, transformChannels]
  )

  const [csvData, setCsvData] = useState<{
    headers: LabelKeyObject[]
    data: Record<string, string>[]
    filename: string
  }>({
    headers: [],
    data: [],
    filename: ''
  })

  const handleExport = async () => {
    setIsExporting(true)

    new Promise<FrictionData[]>((resolve) => {
      return tab === 'function'
        ? resolve(getFrictionScores(client, { auditIds: compareChannel.state!.channelScores!.map((cs) => cs.auditId) }))
        : resolve([])
    })
      .then((result) => {
        return tab === 'function'
          ? exportFunctions(filename, providers, journeys, result, selected, usesVoc)
          : exportChannels(filename, providers, journeys, result, selected[0] as 'cx_score' | 'score')
      })
      .then((exportData) => setCsvData(exportData))
      .then(() =>
        setTimeout(() => {
          // @ts-ignore
          downloadRef.current.link.click()
          setIsExporting(false)
          onExported()
        })
      )
  }

  return (
    <>
      <CSVLink
        style={{ display: 'hidden', textDecoration: 'none', cursor: 'pointer' }}
        filename={csvData.filename}
        headers={csvData.headers}
        data={csvData.data}
        asyncOnClick={true}
        ref={downloadRef}
        uFEFF={false}
      />
      <Button
        color={'secondary'}
        variant={'contained'}
        disabled={isExporting}
        onClick={handleExport}
        style={{ boxShadow: 'none' }}
      >
        {isExporting ? 'Exporting' : 'Export'}
      </Button>
    </>
  )
}
