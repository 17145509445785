import { useEffect, useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import DashboardPanel from '../DashboardPanel'
import getFormattedDate from '../../support/date/getFormattedDate'
import { useApolloClient } from '@apollo/client'
import { getAuditHistory } from '../../support/graphql/queries'
import { SegmentBadge } from '../Common/SegmentBadge'
import { Criterion, ProviderCriterion } from '../../declarations/Criterion'
import { Audit } from '../../declarations/Audit'
import { Segments } from '../../types/Segments'
import * as _ from 'lodash'
import { Link } from 'react-router-dom'
import { GetSearchCriteriaData } from '../../services/api/get-search-criteria'

type LatestUpdatesProps = {
  userId: string
  searchCriteria: GetSearchCriteriaData
}

type LatestUpdateRecord = {
  sector: number
  segment: Criterion
  provider: ProviderCriterion
  date: string
}

export const LatestUpdates = (props: LatestUpdatesProps) => {
  const [audits, setAudits] = useState<LatestUpdateRecord[]>([])
  const client = useApolloClient()

  useEffect(() => {
    const init = async () => {
      const audits = (await getAuditHistory(client)).audits as Audit[]
      const data = audits.map((audit) => ({
        ...audit,
        segment: props.searchCriteria.getSearchCriteria.segments.find((x) => x.id === audit.segment)!,
        provider: props.searchCriteria.getSearchCriteria.providers.find((x) => x.id === audit.provider)!,
        date: audit.audit_date
      }))
      /**
       * Filtering out duplicated mentions of providers and only keep the first 7
       */
      const filtered = _.uniqBy(data, 'provider.name').slice(0, 6)
      setAudits(filtered)
    }

    init()
  }, [client, props.searchCriteria])

  return (
    <DashboardPanel title="Latest Radar Updates">
      <Box display={'flex'} flexDirection={'column'}>
        {audits.map((record: LatestUpdateRecord, index: number) => (
          <Box
            key={index}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            paddingY={2}
            paddingX={1}
            style={{ borderBottom: '1px solid #ECEDEF' }}
          >
            <Box flexGrow={1} display={'flex'} alignItems={'center'}>
              <Link
                title={record.provider.name}
                to={`/search?sector=${record.sector}&segment=${record.segment.id}&provider=${record.provider.id}`}
              >
                <img width={'60px'} src={record.provider.image} alt={record.provider.name} />
              </Link>
              <Typography style={{ marginLeft: '130px' }} variant={'body1'}>
                {getFormattedDate(record.date)}
              </Typography>
            </Box>
            <Box style={{ width: '25%' }} alignItems={'center'}>
              <SegmentBadge segment={record.segment.id as Segments} segmentName={record.segment.name} />
            </Box>
          </Box>
        ))}
      </Box>
    </DashboardPanel>
  )
}
