import { useApolloClient } from '@apollo/client'
import { find, sortBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useCriteria } from '../contexts/Search/Criteria'
import { getAssets } from '../support/graphql/queries'
import { AssetJourney } from '../types/Journeys'
import { ChannelJourney, ChannelScore, WeightedJourneyScore } from '../declarations/ChannelScore'
import { JourneyCriterion } from '../declarations/Criterion'
import { Segments } from '../types/Segments'

const transformToChannelJourney = (
  weightedJourneyScore: WeightedJourneyScore,
  journeys: JourneyCriterion[],
  secure: boolean,
  segment: Segments,
  auditId: number
): ChannelJourney => {
  return {
    id: weightedJourneyScore.journey,
    name: find(journeys, { id: weightedJourneyScore.journey })?.name,
    score: weightedJourneyScore.score,
    cx_score: weightedJourneyScore.cx_score,
    key: `${weightedJourneyScore.journey}#${secure ? '1' : '0'}`,
    weight: weightedJourneyScore.weight,
    segment: segment,
    answers: weightedJourneyScore.answers,
    auditId: auditId,
    sequence: weightedJourneyScore.journey_seq
  }
}

export const getChannelJourneys = (channelScore: ChannelScore, journeys: JourneyCriterion[]): ChannelJourney[] => {
  const channelJourneys = channelScore.journeys.map((weightedJourneyScore) =>
    transformToChannelJourney(weightedJourneyScore, journeys, true, channelScore.segment, channelScore.auditId)
  )

  return sortBy<ChannelJourney>(channelJourneys, ['sequence'])
}

export const useJourneyView = () => {
  const searchCriteria = useCriteria()
  const client = useApolloClient()

  const journeys: AssetJourney[] = useMemo(
    () =>
      searchCriteria.state.journeys.map((j) => ({
        key: `${j.id}`,
        id: j.id,
        name: j.name,
        assets: []
      })),
    [searchCriteria.state.journeys]
  )

  const names = useMemo(() => journeys.map((j) => j.key), [journeys])

  const getJourneyAssets = useCallback(
    async (auditId: number, journey: number) => {
      console.log(auditId, journey)
      try {
        const assets = await getAssets(client, { auditId, journey })
        if (!assets) {
          return []
        }
        return assets
      } catch (e) {
        console.log('Failed to load assets!')
      }
      return []
    },
    [client]
  )

  return {
    getJourneyAssets,
    journeys,
    names
  }
}
