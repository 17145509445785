import { useApolloClient } from '@apollo/client'
import { Box } from '@material-ui/core'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { EqualHeight } from 'react-equal-height'
import { ExpandableProvider, useExpandable } from '../../contexts/ExpandableContext'
import { useFunctions } from '../../hooks/functions'
import { getFrictionScores } from '../../support/graphql/queries'
import { TabFunctionView } from '../../types/Channel'
import { Loading } from '../Common/Loading'
import { ExportTrigger } from '../Export/ExportTrigger'
import { Columns, Container, Group, Row } from './FunctionView.styled'
import { ChannelScore } from '../../declarations/ChannelScore'
import { FrictionData } from '../../declarations/FrictionScore'
import {
  FunctionViewColumnHeader,
  FunctionViewHeader,
  FunctionViewJourneyCell,
  FunctionViewJourneyStub
} from './FunctionViewElements'

type WithExpandableProviderProps = {
  expanded: string[]
  names: string[]
  checkable: string[]
  checked: string[]
}

const WithExpandableProvider: FunctionComponent<WithExpandableProviderProps> = (props) => {
  return (
    <ExpandableProvider
      state={{
        expanded: props.expanded,
        checked: props.checked
      }}
      names={props.names}
      checkable={props.checkable}
    >
      {props.children}
    </ExpandableProvider>
  )
}

type FunctionViewComparisonProps = {
  channelScores: ChannelScore[]
  comparable?: boolean
  sortedOnText?: string
  sortedQuestion?: number
  sortedJourneyKey?: string
  usesVoc: boolean
}

const FunctionViewComparison: FunctionComponent<FunctionViewComparisonProps> = (props) => {
  const client = useApolloClient()
  const { journeys, transformChannels } = useFunctions()
  const expandable = useExpandable()

  const { sortedOnText, channelScores, comparable = false, sortedQuestion, sortedJourneyKey } = props

  const [frictionScores, setFrictionScores] = useState<FrictionData[]>([])
  const [frictionScoresLoaded, setFrictionScoresLoaded] = useState<boolean>(false)
  const [prevSortedJourneyKey, setPrevSortedJourneyKey] = useState(sortedJourneyKey)

  useEffect(() => {
    /*
     * This code needs to be inside an effect otherwise you get the console warning:
     *    Warning: Cannot update a component (`ExpandableProvider`) while rendering
     *    a different component (`FunctionViewComparison`).
     *
     * Strangely, without the useEffect, the warning only appears very intermittently
     * and even then the result is still as intended ¯\_(ツ)_/¯
     */
    if (sortedJourneyKey !== prevSortedJourneyKey) {
      expandable.dispatch({ type: 'CHECK_ITEM', payload: { name: sortedJourneyKey } })
      setPrevSortedJourneyKey(sortedJourneyKey)
    }
  }, [expandable, sortedJourneyKey, prevSortedJourneyKey])

  // console.log("sortedQuestion", sortedQuestion)

  /**
   *
   * this code is commented out because as of March 2024 I beleive the scrolling
   * does more harm than good. it can be deleted if we're happy with that
   * Only scroll if we sort by Friction
   */
  // if (!scrolled && comparable && searchResults.state.orderby.field === 'FRICTION') {
  //   if (frictionSORef.current) {
  //     frictionSORef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //     setScrolled(true);
  //   }
  // }

  // useEffect(() => {
  //   setScrolled(false);
  // }, [sortedOnText, comparable]);

  const providers = useMemo(() => transformChannels(channelScores), [channelScores, transformChannels])

  const auditIds = useMemo(() => {
    return channelScores.map((c) => c.auditId)
  }, [channelScores])

  const loadFrictionScores = useCallback(async () => {
    const response = await getFrictionScores(client, { auditIds })

    setFrictionScores(response)
    setFrictionScoresLoaded(true)
  }, [auditIds, client])

  useEffect(() => {
    loadFrictionScores()
    return () => {
      setFrictionScores([])
      setFrictionScoresLoaded(false)
    }
  }, [auditIds, loadFrictionScores])

  if (frictionScores.length <= 0 && !frictionScoresLoaded) {
    return <Loading.Panel width={'100%'} height={600} />
  }

  // console.log("expandable", expandable.state)
  return (
    <EqualHeight>
      {!comparable && (
        <Box marginY={1} display={'flex'} justifyContent={'end'}>
          <Box marginTop={'10px'}>
            <ExportTrigger tab={TabFunctionView} selected={expandable.state.expanded} />
          </Box>
        </Box>
      )}
      <Container>
        <Group style={{ width: 'auto' }}>
          <FunctionViewHeader />
          {journeys.map((journey, journeyIdx) => (
            <FunctionViewJourneyStub
              journey={journey}
              key={journeyIdx}
              sortedQuestion={sortedQuestion}
              usesVoc={props.usesVoc}
              comparable={comparable}
              sortedOnText={sortedOnText}
            />
          ))}
        </Group>
        <Columns>
          {providers.map((provider, providerIdx) => (
            <Row key={providerIdx}>
              <FunctionViewColumnHeader
                provider={provider}
                comparable={comparable}
                channelScores={channelScores}
                view={TabFunctionView}
              />
              {
                // the lint warning on the next line seems to be a bug in react/prop-types
                // eslint-disable-next-line react/prop-types
                provider.channelJourneys.map((journey, jIdx) => (
                  <FunctionViewJourneyCell
                    key={jIdx}
                    provider={provider}
                    journey={journey}
                    frictionScores={frictionScores}
                    usesVoc={props.usesVoc}
                    sortedQuestion={sortedQuestion}
                  />
                ))
              }
            </Row>
          ))}
        </Columns>
      </Container>
    </EqualHeight>
  )
}

// TODO This view renders 8 times on state change which seems more than necessary
export const FunctionView: FunctionComponent<FunctionViewComparisonProps> = (props) => {
  const { names, journeys } = useFunctions()

  let sortedJourneyKey
  let sortedQuestion
  if (props.sortedOnText) {
    const findSortedOnJourney = (sortedOnText: string) =>
      journeys.filter((journey) => {
        let journeyHasFrictionScoreQuestion = false
        journey.questions.forEach((question) => {
          if (question.text === sortedOnText) {
            sortedQuestion = question.id
            journeyHasFrictionScoreQuestion = true
          }
        })

        return journeyHasFrictionScoreQuestion
      })

    const sortedJourney = findSortedOnJourney(props.sortedOnText as string)
    // Ideally sortedJourney would always be found if the state of the app is consistent.
    // However if you're currently in function view with sort-by-friction and then
    // you change sector, this component renders initially with props.sortedOnText
    // set to the previous friction question before it resets to the default sort-by-voc.
    // hence if sortedJourney is not found we just ignore it
    if (props.comparable && sortedJourney.length) {
      sortedJourneyKey = sortedJourney[0].key
    }
  }

  const checkable: string[] = sortedJourneyKey ? [sortedJourneyKey] : []
  const checked: string[] = sortedJourneyKey ? [sortedJourneyKey] : []

  return (
    <WithExpandableProvider expanded={[]} names={names} checkable={checkable} checked={checked}>
      <FunctionViewComparison {...props} sortedQuestion={sortedQuestion} sortedJourneyKey={sortedJourneyKey} />
    </WithExpandableProvider>
  )
}
