import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { FunctionComponent } from 'react'
import { Pagination as BasePagination } from '../../components/Pagination/Pagination'
import { useSearchQuery } from '../../contexts/Search/Query'
import { useSearchResults } from '../../contexts/Search/Results'

type PaginationProps = {
  onTriggerSearch: () => void
  pagination: {
    page: number
    offset: number
    limit: number
  }
}

export const Pagination: FunctionComponent<PaginationProps> = (props) => {
  const searchResults = useSearchResults()
  const searchQuery = useSearchQuery()

  const onPageNavigateHandler = (page: number) => {
    // console.log('onPageNavigateHandler', searchQuery.state);
    searchQuery.dispatch({
      type: 'SET_PAGINATION_QUERY_STATE',
      payload: { value: { ...searchQuery.state.pagination, page, offset: (page - 1) * 12 } }
    })
    props.onTriggerSearch()
  }

  return (
    <>
      {searchResults.state.loading ? (
        <Box marginY={2}>
          <Box display="flex" justifyContent="space-between" justifyItems={'center'} alignItems="center">
            <Skeleton variant={'text'} height={48} width={200} />
            <Skeleton variant={'text'} height={48} width={200} />
          </Box>
        </Box>
      ) : (
        <BasePagination
          meta={{ limit: props.pagination.limit, skip: props.pagination.offset, totalHits: searchResults.state.count }}
          page={props.pagination.page}
          onPageNavigate={onPageNavigateHandler}
        />
      )}
    </>
  )
}
