/**
 * This file handles the storing of the criteria that is returned from the GraphQL endpoint,
 * as we do not want to be calling that many times we transform and store its output here for
 * ease of access.
 *
 * The Criteria refer to the defined options a consumer can search for.
 */

import { createContext, FunctionComponent, useContext, useReducer } from 'react'
import { Industry, Cycle } from '../../types/Industries'
import { Questions } from '../../declarations/Question'
import { Segments } from '../../types/Segments'
import { Criterion, JourneyCriterion, ProviderCriterion } from '../../declarations/Criterion'

/**
 * A type helper for Actions.
 */
type SetCriterionPayload<T> = {
  value: T
}

/**
 * Types for Actions that are used within the reducer functions.
 */
type SetInitialCriteria = { type: 'SET_INITIAL_CRITERIA_STATE'; payload: SetCriterionPayload<Criteria> }
type SetIndustryCriteria = { type: 'SET_INDUSTRY_CRITERIA_STATE'; payload: SetCriterionPayload<Industry[]> }
type SetLoadingCriteria = { type: 'SET_LOADING_CRITERIA_STATE'; payload: SetCriterionPayload<boolean> }
type SetQuestionsCriteria = { type: 'SET_QUESTIONS_CRITERIA_STATE'; payload: SetCriterionPayload<Questions> }

/**
 * A Union for the Actions.
 */
type Action = SetInitialCriteria | SetIndustryCriteria | SetLoadingCriteria | SetQuestionsCriteria

/**
 * Dispatch type helper.
 */
type Dispatch = (action: Action) => void

/**
 * The Criteria state type.
 */
export type Criteria = {
  cycles: Cycle[]
  markets: Criterion[]
  providers: ProviderCriterion[]
  segments: Criterion[]
  journeys: JourneyCriterion[]
  channels: Criterion[]
  audience: Record<
    string,
    {
      id: number
      name: string
      segment: Segments
      __typename: string
    }[]
  >
  questions: Questions
}

/**
 * The State type.
 */
type State = {
  industries: Industry[]
  loading: boolean
} & Criteria

type CriteriaContextType = { state: State; dispatch: Dispatch } | undefined
const CriteriaContext = createContext<CriteriaContextType>(undefined)
CriteriaContext.displayName = 'CriteriaContext'

/*
 * Because of a limitation in the server data model, getSearchCriteria returns spurious
 * journeys which are not valid for the selected sector.
 * However, getQuestions only returns the valid journeys so we use that to filter the
 * journeys prop and remove the spurious ones
 */
const filterJourneys = (state: State): State => {
  const validJourneys = new Set(state.questions.journeys.map((x) => x.journeyId))
  const isValidJourney = (j: { id: number }) => validJourneys.has(j.id)
  return {
    ...state,
    journeys: state.journeys.filter(isValidJourney)
  }
}

/**
 * Used for setting the initial criteria to be used.
 */
const setInitialCriteria = (state: State, action: SetInitialCriteria): State => {
  // console.log('Setting initial criteria', action.payload.value);
  return filterJourneys({
    ...state,
    ...action.payload.value
  })
}

const setIndustryCriteria = (state: State, action: SetIndustryCriteria): State => {
  // console.log('Setting Industry Criteria', action.payload.value);
  return {
    ...state,
    industries: action.payload.value
  }
}
/**
 * Used for setting the loading state of the criteria to block or delay other components.
 */
const setLoadingCriteria = (state: State, action: SetLoadingCriteria): State => {
  return {
    ...state,
    loading: action.payload.value
  }
}

/**
 * Used for setting the questions criteria.
 */
const setQuestionCriteria = (state: State, action: SetQuestionsCriteria): State => {
  return filterJourneys({
    ...state,
    questions: action.payload.value
  })
}

/**
 * A reducer for cycling through dispatched events and call their corresponding function.
 *
 * @param {State} state
 * @param {Action} action
 *
 * @throws Error
 */
const reducer = (state: State, action: Action) => {
  if (action.type === 'SET_INITIAL_CRITERIA_STATE') {
    return setInitialCriteria(state, action)
  }

  if (action.type === 'SET_INDUSTRY_CRITERIA_STATE') {
    return setIndustryCriteria(state, action)
  }

  if (action.type === 'SET_LOADING_CRITERIA_STATE') {
    return setLoadingCriteria(state, action)
  }

  if (action.type === 'SET_QUESTIONS_CRITERIA_STATE') {
    return setQuestionCriteria(state, action)
  }

  throw new Error(`Unhandled action type in 'CriteriaContext'`)
}

/**
 * The Criteria Provider component, including in the app bootstrap process.
 */
export const CriteriaProvider: FunctionComponent = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    loading: true,
    industries: [],
    cycles: [],
    markets: [],
    providers: [],
    segments: [],
    journeys: [],
    channels: [],
    audience: {},
    questions: {
      questions: [],
      journeys: []
    }
  })

  return <CriteriaContext.Provider value={{ state, dispatch }}>{props.children}</CriteriaContext.Provider>
}

/**
 * A hook used for accessing the criteria in components.
 */
export const useCriteria = () => {
  const context = useContext(CriteriaContext)

  if (context === undefined) {
    throw new Error('useCriteria must be used within a CriteriaProvider')
  }

  return context
}
