import { Box } from '@material-ui/core'
import styled from 'styled-components'

const CategoryTab = styled(Box)`
  && {
    font-size: 10px;
    line-height: 12px;
    padding: 6px 8px;
    color: #647386;
    display: inline-block;
    border-radius: 20px;
    &:not(:only-child):not(:last-child) {
      margin-right: 8px;
    }
    &.channel--1 {
      color: #2c6be8;
      border: 1px solid #f3f9ff;
      background-color: #f3f9ff;
    }
    &.channel--2 {
      color: #428100;
      border: 1px solid #f6faf2;
      background-color: #f6faf2;
    }
    &.channel--4 {
      color: #1d7b8c;
      border: 1px solid #e9f8fa;
      background-color: #e9f8fa;
    }
    &.channel--5 {
      color: #b620e0;
      border: 1px solid #fbf4fd;
      background-color: #fbf4fd;
    }
    &.greyed {
      background-color: #f6f8fa;
      border: 1ps solid #f6f8fa;
    }
    &.red {
      color: #b8102d;
      background-color: #feefe5;
      border: none;
    }
    &.yellow {
      color: #bb4c00;
      background-color: #f7e7ea;
      border: none;
    }
  }
`

export default CategoryTab
