import { Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { PropsWithChildren } from 'react'
import { BusinessIcon, CorporateIcon, LifeInsuranceIcon, MerchantServicesIcon, HealthIcon, PersonalIcon } from './icons'
import {
  Business2021Segment,
  Business2023Segment,
  Corporate2021Segment,
  Corporate2023Segment,
  LifeInsurance2021Segment,
  LifeInsurance2023Segment,
  MerchantServices2023Segment,
  Health2024Segment,
  Personal2021Segment,
  Personal2023Segment,
  Segments,
  TelecomsSegment,
  getSegmentName
} from '../../types/Segments'

const Badge = withStyles({
  root: {
    fontSize: '10px',
    lineHeight: '12px',
    padding: '6px 8px',
    color: '#647386',
    display: 'inline-block',
    borderRadius: '20px',
    '&:not(:only-child):not(:last-child)': {
      marginRight: '8px'
    }
  }
})(Box)

export const SegmentBadgeBusinessAndCorporateSegment = 0

const styles = (segment: Segments | typeof SegmentBadgeBusinessAndCorporateSegment | number): React.CSSProperties => {
  switch (segment) {
    case SegmentBadgeBusinessAndCorporateSegment:
    case Corporate2021Segment:
    case Business2021Segment:
    case Corporate2023Segment:
    case Business2023Segment:
      return {
        color: '#b8102d',
        border: '1px solid #f7e7ea',
        backgroundColor: '#f7e7ea'
      }
    case Personal2021Segment:
    case Personal2023Segment:
      return {
        color: '#bb4c00',
        border: '1px solid #feefe5',
        backgroundColor: '#feefe5'
      }
    case LifeInsurance2021Segment:
    case LifeInsurance2023Segment:
      return {
        color: '#018067',
        border: '1px solid #E5FBF7',
        backgroundColor: '#E5FBF7'
      }
    case TelecomsSegment:
      return {
        color: '#747600',
        border: '1px solid #F9FAD8',
        backgroundColor: '#F9FAD8'
      }
    case MerchantServices2023Segment:
      return {
        color: '#747600',
        border: '1px solid #F9FAD8',
        backgroundColor: '#F9FAD8'
      }
    case Health2024Segment:
      return {
        color: '#4040ff',
        border: '1px solid #e0e0ff',
        backgroundColor: '#e0e0ff'
      }
  }

  return {}
}

const icons = (segment: Segments | typeof SegmentBadgeBusinessAndCorporateSegment | number): JSX.Element | null => {
  switch (segment) {
    case SegmentBadgeBusinessAndCorporateSegment:
      return (
        <>
          <BusinessIcon styles={{ marginRight: '2px' }} />
          <CorporateIcon styles={{ marginRight: '5px' }} />
        </>
      )
    case Corporate2021Segment:
    case Corporate2023Segment:
      return <CorporateIcon styles={{ marginRight: '5px' }} />
    case Business2021Segment:
    case Business2023Segment:
      return <BusinessIcon styles={{ marginRight: '5px' }} />
    case LifeInsurance2021Segment:
    case LifeInsurance2023Segment:
      return <LifeInsuranceIcon styles={{ marginRight: '5px' }} />
    case Personal2021Segment:
    case Personal2023Segment:
      return <PersonalIcon styles={{ marginRight: '5px' }} />
    case TelecomsSegment:
      return null
    case MerchantServices2023Segment:
      return <MerchantServicesIcon styles={{ marginRight: '5px' }} />
    case Health2024Segment:
      return <HealthIcon styles={{ marginRight: '5px' }} />
  }

  return null
}

type SegmentBadgeProps = {
  segment: Segments | typeof SegmentBadgeBusinessAndCorporateSegment | number
  segmentName?: string
}

export const SegmentBadge = (props: PropsWithChildren<SegmentBadgeProps>) => {
  const { segment, segmentName = undefined } = props

  const icon = icons(segment)
  const style = styles(segment)

  return (
    <Badge style={{ ...style, position: 'relative' }} display={'flex'}>
      <Box display={'flex'} justifyContent={'center'}>
        {icon && icon}
        {segmentName ? segmentName : getSegmentName(segment)}
      </Box>
    </Badge>
  )
}
