import { Box, Button, FormControlLabel, Paper, Radio, TextField, Typography } from '@material-ui/core'
import { CheckboxProps } from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useExport } from '../../contexts/ExportContext'
import { useCriteria } from '../../contexts/Search/Criteria'
import { GreenCheckbox } from '../Common/GreenCheckbox'
import { Modal } from '../Modal/Modal'
import { ExportButton } from './ExportButton'

const Input = withStyles({
  root: {
    '& .MuiInputBase-root': {
      color: '#647386'
    }
  }
})(TextField)

const RadioInput = withStyles({
  root: {
    paddingTop: '3px',
    paddingBottom: '4px',
    color: '#8B96A4',
    '&$checked': {
      color: '#24AB3B'
    }
  },
  checked: {}
})((props: CheckboxProps) => {
  return <Radio {...props} />
})

const FormControlStyles = styled(FormControlLabel)`
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #24ab3b;
  }
  .MuiFormControlLabel-label {
    white-space: nowrap;
  }
  && {
    color: #68788b;
    white-space: nowrap;
  }
`

type ExportModalProps = {
  usesVoc: boolean
}

export const ExportModal: FunctionComponent<ExportModalProps> = (props) => {
  const exportContext = useExport()
  const searchCriteria = useCriteria()

  // WARNING: selected is overloaded - for exporting functions it contains the list of journeys;
  // for exporting channels it contains a single item - either 'score' or 'cx score'
  const [selected, setSelected] = useState<string[]>([])
  const [exportName, setExportName] = useState<string>('')

  useEffect(() => setSelected(exportContext.state.expanded), [exportContext.state.expanded])
  useEffect(() => setExportName(`export-${exportContext.state.tab}-${new Date().getTime()}`), [exportContext.state.tab])

  const onCloseHandler = () => {
    exportContext.dispatch({ type: 'TOGGLE_EXPORT_MODAL_OPEN_STATE', payload: { value: false } })
  }

  const onJourneySelectedHandler = (name: string) => {
    if (selected.includes(name)) {
      setSelected(() => selected.filter((item) => item !== name))
    } else {
      setSelected((prev) => prev.concat([name]))
    }
  }

  const onScoreSelectHandler = (name: string) => {
    setSelected(() => [name])
  }

  return (
    <Modal
      open={exportContext.state.open}
      onClose={onCloseHandler}
      closeable={true}
      paddingX={4}
      paddingY={4}
      fullHeight={false}
      rounded={true}
      size={'md'}
      title={'Export'}
    >
      <Box
        style={{
          fontSize: '14px',
          color: '#647386',
          letterSpacing: '-0.2px',
          marginBottom: '32px',
          lineHeight: '1.5'
        }}
      >
        <p>Continue to export to csv file from the selection below and rename as required.</p>
      </Box>
      <Paper>
        <Box
          display="flex"
          flexDirection={'column'}
          alignContent={'center'}
          alignItems={'center'}
          width={'100%'}
          paddingX={1}
          paddingY={4}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyItems={'center'}
            paddingX={2}
            paddingY={2}
            width={'500px'}
          >
            <Typography
              style={{
                fontSize: '14px',
                color: '#001433',
                fontWeight: 600,
                marginRight: '20px'
              }}
              variant={'subtitle2'}
            >
              Export Name
            </Typography>
            <Box width={'350px'} marginTop={0.5}>
              <Input
                style={{
                  color: '#647386',
                  backgroundColor: 'white',
                  marginTop: '4px'
                }}
                onChange={(event) => setExportName(event.target.value)}
                name={'name'}
                placeholder={'Export Name'}
                fullWidth={true}
                size={'small'}
                variant={'outlined'}
                required={true}
                type={'text'}
                value={exportName}
              />
            </Box>
          </Box>

          {exportContext.state.tab === 'channel' && (
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyItems={'center'}
              paddingX={2}
              paddingY={2}
              width={'500px'}
            >
              <Typography
                style={{
                  fontSize: '14px',
                  color: '#001433',
                  fontWeight: 600
                }}
                variant={'subtitle2'}
              >
                Export Score
              </Typography>
              <Box display={'flex'}>
                {props.usesVoc && (
                  <FormControlStyles
                    label={'VoC Score'}
                    control={
                      <RadioInput
                        checked={selected.includes('cx_score')}
                        size="small"
                        onChange={(e) => onScoreSelectHandler('cx_score')}
                      />
                    }
                  />
                )}
                <FormControlStyles
                  label={'Utility Score'}
                  control={
                    <RadioInput
                      checked={selected.includes('score')}
                      size="small"
                      onChange={(e) => onScoreSelectHandler('score')}
                    />
                  }
                />
              </Box>
            </Box>
          )}

          {exportContext.state.tab === 'function' && (
            <Box display={'flex'} justifyContent={'center'} paddingX={2}>
              <Box display={'flex'} flexDirection={'column'} width={'250px'}>
                <Typography
                  style={{
                    fontSize: '14px',
                    color: '#001433',
                    fontWeight: 600,
                    textTransform: 'capitalize',
                    paddingTop: '9px'
                  }}
                  variant={'subtitle2'}
                >
                  Channel
                </Typography>

                {searchCriteria.state.journeys.map((journey) => {
                  const name = `journey#${journey.id}`
                  const isChecked = selected.includes(name)
                  return (
                    <FormControlLabel
                      key={name}
                      style={{ whiteSpace: 'nowrap' }}
                      control={
                        <GreenCheckbox
                          checked={isChecked}
                          size="small"
                          onChange={() => onJourneySelectedHandler(name)}
                          name={name}
                        />
                      }
                      label={journey.name}
                    />
                  )
                })}
              </Box>
              <Box display={'flex'} flexDirection={'column'} width={'250px'}></Box>
            </Box>
          )}

          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} marginTop={'20px'}>
            <ExportButton
              onExported={onCloseHandler}
              tab={exportContext.state.tab}
              selected={selected}
              filename={exportName}
              usesVoc={props.usesVoc}
            />
            <Button
              color={'secondary'}
              variant={'text'}
              onClick={onCloseHandler}
              style={{ marginLeft: '20px', boxShadow: 'none' }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  )
}
