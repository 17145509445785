import { useApolloClient } from '@apollo/client'
import { Box, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import DashboardPanel from '../../components/DashboardPanel'
import { useResources } from '../../hooks/resources'
import getFormattedDate from '../../support/date/getFormattedDate'
import { ResourceIcon } from '../Common/ResourceIcon'
import { megabytesToBytes, bytesToHumanReadable } from '../../helpers/numberFormatting'
import LargeDownloadModal from '../Common/LargeDownloadModal'

interface ItemData {
  content_type: string
  create_date: string | number
  description?: string
  expiry_date: string
  file_size: number
  id: number
  name: string
}

/**
 * The Resources component fetches and displays a list of resources.
 * It also handles the downloading of resources with a modal for large file downloads.
 *
 * @return {React.FC} The Resources component.
 */
export const Resources: React.FC = () => {
  const [largeDownload, setLargeDownload] = useState<ItemData | null>(null)
  const [resources, setResources] = useState<ItemData[]>([])

  const { getResources, downloadResource } = useResources()
  const client = useApolloClient()

  useEffect(() => {
    /**
     * Fetches resources from the server and sets them to the state.
     */
    const fetchResources = async () => {
      try {
        // Fetch resources with a limit of 4 and an offset of 0.
        const response = await getResources(client, { limit: 4, offset: 0 })
        // Set the fetched resources to the state.
        setResources(response)
      } catch (error) {
        console.error("Failed to fetch resources:", error)
      }
    }

    fetchResources()
  }, [client, getResources])

  /**
   * Handles the download of a resource. If the resource is large, it shows a confirmation modal.
   *
   * @param {React.MouseEvent} event - The click event.
   * @param {ItemData} resource - The resource to be downloaded.
   */
  const onDownloadHandler = async (event: React.MouseEvent, resource: ItemData) => {
    event.preventDefault()

    const alwaysDownloadLargeFiles = localStorage.getItem('alwaysDownloadLargeFiles') === 'true'

    if (resource.file_size > megabytesToBytes(500) && !alwaysDownloadLargeFiles) {
      setLargeDownload(resource)
    } else {
      await downloadResource(client, resource.id)
    }
  }

  /**
   * Renders a row for a resource item.
   *
   * @param {Object} props - The properties object.
   * @param {ItemData} props.itemdata - The resource item data.
   * @return {JSX.Element} The resource row.
   */
  function ResourceRow({ itemdata }: { itemdata: ItemData }) {
    const fileSize = bytesToHumanReadable(itemdata.file_size)
    const isLargeFile = itemdata.file_size > megabytesToBytes(500)

    return (
      <Grid container spacing={4} alignItems="center" style={{ flexGrow: 1 }}>
        <Grid item xs={1}>
          <ResourceIcon type={itemdata.content_type} />
        </Grid>
        <Grid
          item
          xs={7}
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => onDownloadHandler(e, itemdata)}
          style={{ cursor: 'pointer' }}
        >
          <Box marginLeft={2}>
            <Typography variant="h4" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {itemdata.name}
            </Typography>
            {itemdata.description && (
              <Typography
                variant="body2"
                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {itemdata.description}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Typography>{getFormattedDate(itemdata.create_date, { year: '2-digit', month: 'numeric', day: 'numeric' })}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Box
            padding="2px 6px"
            borderRadius={4}
            bgcolor={isLargeFile ? '#ffd9d9' : '#e0e0e0'}
            color={isLargeFile ? 'black' : 'black'}
            textAlign="center"
            fontSize="0.75rem"
          >
            {fileSize}
          </Box>
        </Grid>
      </Grid>
    )
  }

  return (
    <DashboardPanel title="Reports" link="/resources">
      {resources.length === 0 ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography style={{ textAlign: 'center', color: '#647386' }}>No Reports Available</Typography>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" style={{ height: '100%' }} flexGrow={1}>
          {resources.map((resource) => (
            <Box key={resource.id} display="flex" flex={1} style={{ minHeight: 0, borderBottom: '1px solid #ECEDEF' }}>
              <ResourceRow itemdata={resource} />
            </Box>
          ))}
        </Box>
      )}

      {largeDownload && (
        <LargeDownloadModal
          resource={largeDownload}
          open={!!largeDownload}
          onClose={() => setLargeDownload(null)}
          onDownload={() => {
            if (largeDownload) {
              downloadResource(client, largeDownload.id)
              setLargeDownload(null)
            }
          }}
        />
      )}
    </DashboardPanel>
  )
}
