import { FunctionComponent } from 'react'
import { Route, Routes } from 'react-router-dom'
import { PrivateRoute } from '../components/Router/PrivateRoute'
/**
 * Lazy load the containers for the app.
 */
import { MyAccount } from '../pages/Account'
import { Index as Help } from '../pages/Help/Index'

export const AccountContainer: FunctionComponent = () => {
  return (
    <Routes>
      <Route
        path={'/my-account'}
        element={
          <PrivateRoute>
            <MyAccount />
          </PrivateRoute>
        }
      />
      <Route
        path={'/help'}
        element={
          <PrivateRoute>
            <Help />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<></>} />
    </Routes>
  )
}
