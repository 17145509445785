/**
 * Converts megabytes to bytes.
 *
 * @param {number} megabytes - The number of megabytes to convert.
 * @return {number} The equivalent number of bytes.
 */
export function megabytesToBytes(megabytes: number): number {
  // Multiply the number of megabytes by 1024 to get the number of kilobytes.
  // Then multiply the number of kilobytes by 1024 again to get the number of bytes.
  return megabytes * 1024 * 1024
}

/**
 * Converts a size in bytes to a human-readable string.
 *
 * @param {number} bytes - The size in bytes.
 * @return {string} The human-readable string.
 */
export function bytesToHumanReadable(bytes: number): string {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Bytes'
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return parseFloat((bytes / Math.pow(1024, i)).toFixed(0)) + ' ' + sizes[i]
}
