import { Box, FormControlLabel, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SearchIcon } from '../Common/icons'

type HeaderProps = {
  title: string
  term?: string
}

export const Header = (props: HeaderProps) => {
  const navigate = useNavigate()

  const [value, setValue] = useState<string>('')

  useEffect(() => {
    if (props.term) {
      setValue(props.term)
    }
  }, [props.term])

  const handleSearch = () => {
    navigate(`/insights/search?term=${value}`)
  }

  return (
    <Box marginTop={4} marginBottom={2}>
      <Grid container spacing={1} justifyContent="space-between" alignItems="center">
        <Grid item xs={12} md={9}>
          <Typography variant={'h2'}>{props.title}</Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControlLabel
            value={''}
            label={''}
            aria-label={'Search articles'}
            style={{ display: 'block', width: '100%' }}
            control={
              <TextField
                fullWidth
                style={{
                  borderRadius: '6px',
                  backgroundColor: 'white',
                  borderColor: '#D9DBE0'
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch()
                  }
                }}
                onChange={(e) => {
                  setValue(e.target.value)
                }}
                value={value}
                variant={'outlined'}
                size={'small'}
                placeholder={'Search articles'}
                InputProps={{
                  'aria-label': 'Search articles',
                  'aria-placeholder': 'Search articles',
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            }
          />
        </Grid>
      </Grid>
    </Box>
  )
}
