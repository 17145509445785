import { useApolloClient } from '@apollo/client'
import { Box, Container, LinearProgress } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import { find } from 'lodash'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { DemographicsModal } from '../../components/AdjustDemographics/DemographicsModal'
import { AdvancedJourneyModal } from '../../components/AdvancedJourney/AdvancedJourneyModal'
import { SortByModal } from '../../components/FrictionScore/SortByModal'
import { Page } from '../../components/Layout/Page'
import { PrioritisationOverlayModal } from '../../components/PrioritisationOverlay/PrioritisationOverlayModal'
import Widget from '../../components/Search/Widget'
import { Criteria, useCriteria } from '../../contexts/Search/Criteria'
import { State, useSearchQuery } from '../../contexts/Search/Query'
import { useSearchResults } from '../../contexts/Search/Results'
import { GetChannelScoresVariables, getChannelScores } from '../../services/api/get-channel-scores'
import { getStacks } from '../../services/api/get-stacks'
import { TabChannelView, TabFunctionView, TabJourneyView, TabRankView } from '../../types/Channel'
import { Loading } from './Loading'
import { MultipleModal } from './Modal/Multiple/MultipleModal'
import { SingleModal } from './Modal/Single/SingleModal'
import { SearchResults } from './Results'
import { ComparisonToolbar } from './Toolbar/ComparisonToolbar/ComparisonToolbar'
import { ChannelScore } from '../../declarations/ChannelScore'
import { PrevalenceButton } from '../../components/Prevalence/PrevalenceButton'
import { StackRaw } from '../../declarations/StackRaw'

const Search: FunctionComponent = () => {
  const apolloClient = useApolloClient()
  const { dispatch: searchResultsDispatch, state: searchResultsState } = useSearchResults()
  const searchCriteria = useCriteria()
  const searchQuery = useSearchQuery()
  const [triggerSearch, setTriggerSearch] = useState(false)

  const [isWidgetLoading, setWidgetLoading] = useState(true)
  const [view, setView] = useState(TabChannelView)

  const [showingDemographicsModal, setShowingDemographicsModal] = useState<boolean>(false)
  const [showingFrictionScoreSortModal, setShowingFrictionScoreSortModal] = useState<boolean>(false)

  const loadSearchResults = useCallback(async () => {
    // console.log('loadSearchResults', query, criteria);
    Sentry.addBreadcrumb({
      category: 'Search',
      message: 'Loading search results',
      data: { query: searchQuery.state, criteria: searchCriteria.state }
    })
    searchResultsDispatch({ type: 'SET_LOADING_SCORES', payload: { value: true } })

    const channelscoreQuery = buildChannelScoreQuery(searchQuery.state)
    Sentry.addBreadcrumb({
      category: 'Search',
      message: 'Constructed channel score query',
      data: { channescoreQuery: channelscoreQuery }
    })
    const channelScoresResult = await getChannelScores(apolloClient, channelscoreQuery)
    Sentry.addBreadcrumb({
      category: 'Search',
      message: 'getChannelScore results are in',
      data: { channelScoreReults: channelScoresResult }
    })

    const channelScores: ChannelScore[] = channelScoresResult!.getChannelScores.channels.map((x) =>
      postProcessChannelScore(x, searchCriteria.state)
    )

    const stacksResult = channelScores.length
      ? await getStacks(apolloClient, {
          auditIds: channelScores.map((x) => x.auditId),
          audience: searchQuery.state.audience,
          orderBy: { field: searchQuery.state.sort.field, direction: searchQuery.state.sort.direction }
        })
      : { getStacks: [] }

    const stacks = stacksResult!.getStacks.map((x) => postProcessStack(x, searchCriteria.state))

    searchResultsDispatch({
      type: 'SET_RESULTS',
      payload: {
        value: {
          channels: channelScores,
          stacks,
          count: channelScoresResult!.getChannelScores.count
        }
      }
    })
    searchResultsDispatch({ type: 'SET_LOADING_SCORES', payload: { value: false } })
  }, [apolloClient, searchCriteria.state, searchQuery.state, searchResultsDispatch])

  useEffect(() => {
    if (view === TabRankView && !searchQuery.state.selectedIndustry.usesVoc) {
      setView(TabFunctionView)
    }
  }, [view, searchQuery.state.selectedIndustry.usesVoc])

  useEffect(() => {
    if (triggerSearch) {
      setWidgetLoading(false)
      setTriggerSearch(false)
      loadSearchResults()
    }
  }, [loadSearchResults, triggerSearch])

  /*
   * before calling onTriggerSearch, the subcomponent will have dispatched updates to the query and/or
   * search context but those will not have taken effect by the time we get here. Therefore we
   * simply set a flag here and actually fire off the search in a useEffect above
   */
  const onTriggerSearch = useCallback(() => {
    setTriggerSearch(true)
  }, [])

  const onViewChangeHandler = async (view: string) => {
    setView(view)
  }

  const isChannelScoresLoading = searchResultsState.loading
  const isLoading = isWidgetLoading || isChannelScoresLoading

  return (
    <Page>
      {isLoading && <LinearProgress color="secondary" />}

      <Widget onTriggerSearch={onTriggerSearch} />

      <Container maxWidth={false} style={{ minHeight: 'calc(100vh - 20px)' }}>
        <Box marginTop={4}>
          {isWidgetLoading ? (
            <Loading />
          ) : (
            <>
              <AdvancedJourneyModal />

              <PrioritisationOverlayModal />

              {showingFrictionScoreSortModal && (
                <SortByModal
                  onTriggerSearch={onTriggerSearch}
                  onClose={() => setShowingFrictionScoreSortModal(false)}
                />
              )}

              {showingDemographicsModal && (
                <DemographicsModal
                  onClose={() => setShowingDemographicsModal(false)}
                  onTriggerSearch={onTriggerSearch}
                />
              )}

              <SingleModal usesVoc={searchQuery.state.selectedIndustry.usesVoc} />
              <MultipleModal />

              <SearchResults
                onFrictionScoreSort={() => setShowingFrictionScoreSortModal(true)}
                onOpenDemographics={() => setShowingDemographicsModal(true)}
                onTriggerSearch={onTriggerSearch}
                onViewChange={onViewChangeHandler}
                view={view}
              />
            </>
          )}
        </Box>
      </Container>

      {(view === TabChannelView || view === TabJourneyView || view === TabFunctionView || view === TabRankView) && (
        <>
          <div style={{ float: 'right', position: 'relative', zIndex: 6 }}>
            <PrevalenceButton />
          </div>
          <ComparisonToolbar view={view} />
        </>
      )}
    </Page>
  )
}

const postProcessChannelScore = (channelScore: ChannelScore, criteria: Criteria) => {
  const channel = find(criteria.channels, { id: channelScore.channel })
  const provider = find(criteria.providers, { id: channelScore.provider })
  const segment = find(criteria.segments, { id: channelScore.segment })
  const market = find(criteria.markets, { id: channelScore.market })

  return {
    ...channelScore,
    channelName: channel ? channel.name : 'Unknown Channel',
    providerName: provider ? provider.name : 'Unknown Provider',
    providerLogo: provider ? provider.image : 'Unknown Provider',
    segmentName: segment ? segment.name : 'Unknown Segment',
    marketName: market ? market.name : 'Unknown Market',
    key: `provider#${channelScore.provider}#channel#${channelScore.channel}#segment#${channelScore.segment}`
  }
}

const postProcessStack = (stack: StackRaw, criteria: Criteria) => {
  const channel = find(criteria.channels, { id: stack.channel })
  const provider = find(criteria.providers, { id: stack.provider })
  const segment = find(criteria.segments, { id: stack.segment })

  const journey = criteria.journeys.find((j) => j.id === stack.journey)

  return {
    ...stack,
    channelName: channel ? channel.name : 'Unknown Channel',
    providerName: provider ? provider.name : 'Unknown Provider',
    providerLogo: provider ? provider.image : 'Unknown Provider',
    segmentName: segment ? segment.name : 'Unknown Segment',
    journeyName: journey!.name,
    key: `provider#${stack.provider}#channel#${stack.channel}#segment#${stack.segment}#journey#${
      stack.journey
    }#secure#${stack.secure ? '1' : '0'}`
  }
}

// note for legacy reasons, the API accepts a list of sectors but we only ever use a single one
const buildChannelScoreQuery = (queryState: State): GetChannelScoresVariables => ({
  markets: queryState.markets,
  providers: queryState.providers,
  segments: queryState.segments,
  channels: queryState.channels,
  sectors: [queryState.selectedCycle.linkedSector],
  questions: queryState.questions,
  audience: queryState.audience,
  orderBy: {
    field: queryState.sort.field,
    direction: queryState.sort.direction,
    friction_question: queryState.sort.friction_question
  },
  offset: queryState.pagination.offset,
  limit: queryState.pagination.limit,
  as_at_date: queryState.selectedCycle.cycleEndDate ?? undefined
})

export default Sentry.withProfiler(Search)
