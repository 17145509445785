import { Checkbox, withStyles } from '@material-ui/core'
import { CheckboxProps } from '@material-ui/core/Checkbox'

export const GreenCheckbox = withStyles({
  root: {
    borderRadius: '3px',
    paddingTop: '3px',
    paddingBottom: '4px',
    color: '#8B96A4',
    '&$checked': {
      color: '#24AB3B'
    },
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  checked: {}
})((props: CheckboxProps) => {
  return <Checkbox size={'small'} color="default" {...props} />
})
