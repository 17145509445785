import React, { PropsWithChildren } from 'react'
import { Box, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { ArrowRight } from '../Common/icons'
import { Asset as Asset_Type } from '../../declarations/Asset'

type AssetsProps = {
  assets: Asset_Type[]
  onSelected: (asset: Asset_Type, view: 'notes' | 'functions') => void
}

export const Assets = (props: AssetsProps) => {
  if (props.assets.length === 0) {
    return <Box padding={2}>No Assets Available</Box>
  }

  return (
    <>
      <Box maxHeight="800px">
        <Box display="flex" height="650px" style={{ overflowX: 'scroll' }}>
          {props.assets.map((a, aIdx) => (
            <Asset key={aIdx} asset={a} onSelected={props.onSelected} />
          ))}
        </Box>
      </Box>
    </>
  )
}

type AssetProps = {
  asset: Asset_Type
  onSelected: (asset: Asset_Type, view: 'notes' | 'functions') => void
}

const Asset = (props: AssetProps) => {
  return (
    <Box maxHeight={'530px'} padding={2} display={'flex'} flexDirection={'column'}>
      <Box>
        {props.asset.type === 2 ? (
          <video src={props.asset.location} height={'530px'} width="auto" controls></video>
        ) : (
          <ImgStyles height={'530px'} width={'auto'} alt="asset" src={props.asset.location} />
        )}
      </Box>
      <Box marginY={2} display={'flex'} flexDirection={'column'}>
        <ViewLink text={'View functions'} onClick={() => props.onSelected(props.asset, 'functions')} />
        <ViewLink text={'View notes'} onClick={() => props.onSelected(props.asset, 'notes')} />
      </Box>
    </Box>
  )
}

type ViewLinkProps = PropsWithChildren<{
  text: string
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}>

const ViewLink = (props: ViewLinkProps) => {
  return (
    <Box style={{ cursor: 'pointer' }} display={'flex'} justifyItems={'center'} onClick={props.onClick}>
      <Typography style={{ whiteSpace: 'nowrap', color: '#001433', fontSize: '14px' }} variant={'body1'}>
        {props.text}
      </Typography>
      <span>
        <ArrowRight style={{ margin: '2px 6px' }} />
      </span>
    </Box>
  )
}

const ImgStyles = styled.img`
  && {
    -webkit-box-shadow: 0px 1px 3px 1px rgba(65, 69, 88, 0.2);
    box-shadow: 0px 1px 3px 1px rgba(65, 69, 88, 0.2);
  }
`
